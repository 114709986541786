import tw from 'twin.macro'

export const CircleBtn = tw.button`
        bg-theme-bg-secondary
        hover:bg-theme-btn-primary-hover
        p-3 
        rounded-full
        shadow-theme
    `
export const PrimaryBtn = tw.button`
        text-theme-btn-primary-active-txt
        bg-theme-btn-primary
        hover:bg-theme-btn-primary-hover
        rounded-lg
        py-2 px-4
    `
export const SecondaryBtn = tw.button`
    text-theme-btn-primary
    ring-2
    ring-theme-btn-primary
    hover:text-theme-btn-primary-hover
    hover:ring-theme-btn-primary-hover
    rounded-lg
    py-2 px-4
`
export const DangerBtn = tw.button`
    text-theme-btn-primary-active-txt
    bg-red-500
    hover:bg-red-400
    rounded-lg
    py-2 px-4
`

export  const PaginationBtn = tw.button`
    shadow-md
    px-4 py-2 
    hover:bg-theme-btn-primary-hover
    hover:text-theme-bg-primary
    first:rounded-l-md  
    last:rounded-r-md
    bg-theme-primary
    disabled:bg-gray-600
    disabled:cursor-not-allowed
`