import tw from "twin.macro";

export const TableBg = tw.div`
    overflow-x-auto rounded-lg w-full shadow-theme my-2
`

export const Table = tw.table `
    min-w-full
    text-sm
    text-gray-400
    relative
    `

export const Thread = tw.thead`
        bg-theme-input text-xs uppercase font-medium text-white sticky top-0
    `
export const Th = tw.th`
    px-6 py-3 text-left tracking-wider hover:bg-theme-btn-primary-hover hover:text-theme-bg-primary cursor-pointer
    `

export const Tbody = tw.tbody`
    bg-gray-900
`

export const Tr = tw.tr`
    hover:bg-gray-800
`

export const Td = tw.td`
    px-6 py-4 whitespace-nowrap truncate max-w-[20rem] overflow-hidden truncate
`