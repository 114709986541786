import React, { useEffect, useMemo, useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTable, useSortBy } from "react-table";
import { Tab } from '@headlessui/react'

// Components
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";

// Style Components 
import { BadgeRed, BadgeGreen, BadgeGray, } from "../../styled_components/badges";
import { PrimaryBtn, PaginationBtn, SecondaryBtn } from "../../styled_components/buttons";
import { TableBg, Table, Thread, Th, Td, Tr, Tbody } from "../../styled_components/tables";
import { CustomListBox } from "../../styled_components/listbox";
import { MediumModal, SmallModal } from "../../styled_components/modals";
import {
    HiOutlineUserAdd,
    HiCheck,
    HiBan,
    HiChevronDown,
    HiChevronUp,
    HiChevronLeft,
    HiChevronRight
} from "react-icons/hi";

// Context
import { AuthTokenContext } from "../../contexts/AuthTokenContext";

// Custom hooks
import { useListUsersFetch } from "../../hooks/api/UserFetch";
import { useAsyncFetch } from "../../hooks/AsyncFetch";

// Ultils
import { isoToDateNow } from "../../ultils/datetime_format";

// API
import { fetchCreateUser, fetchResetPasswordUser, fetchUpdateUser } from "../../apis/users";

const pageSize = [
    {
        value: 25,
        title: '25'
    },
    {
        value: 50,
        title: '50'
    },
    {
        value: 100,
        title: '100'
    },
    {
        value: 200,
        title: '200'
    },
]

const UserManagementPage = () => {
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    // CONTEXTS
    const { authToken, setAuthToken } = useContext(AuthTokenContext)
    const navigator = useNavigate()

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    // MODALS
    const addUserModal = useRef()
    const editUserModal = useRef()

    // State of edit user
    // const [selectUserEdit, setSelectUserEdit] = useState()
    const [editUser, setEditUser] = useState({})
    const infoModal = useRef()
    const [infoModalContent, setInfoModalContent] = useState({
        title: '',
        content: ''
    })


    ////////////////////////////////////////////////////////////////////////////////////////////////////
    //  ON CREATE USER
    // Async state for submit add hashes
    const [
        isLoadingAddUser,
        setIsLoadingAddUser,
        isErrorAddUser,
        setIsErrorAddUser
    ] = useAsyncFetch()

    // on submit add hash handler
    const onSubmitAddUser = async (e) => {
        e.preventDefault()

        // extract checkStatic and checkCloud
        let submitData = {}
        const data = new FormData(e.target);
        data.forEach((value, key) => value !== '' ? submitData[key] = value: '');

        setIsLoadingAddUser(true)
        setIsErrorAddUser({
            error: false,
            statusCode: 0,
            message: ''
        })
        try {
            const resp = await fetchCreateUser(authToken.access, submitData)
            const respJson = await resp.json()
            //  SUCCESS
            if (resp.status === 200) {
                setInfoModalContent({
                    title: "Information",
                    content: "User added successfully"
                })
                addUserModal.current.close()
                //  Try to refresh table
                setRefreshCall(String(Date.now()))
            }
            else {
                setIsErrorAddUser({
                    error: true,
                    statusCode: resp.status,
                    message: respJson.detail
                })
                setInfoModalContent({
                    title: "Error",
                    content: typeof respJson.detail === 'object' ? respJson.detail[Object.keys(respJson.detail)[0]][0] : respJson.detail
                })
            }
        }
        catch (err) {
            setIsErrorAddUser({
                error: true,
                statusCode: 0,
                message: err.message
            })
            setInfoModalContent({
                title: "Error",
                content: "User created unsuccessfully"
            })
        }
        setIsLoadingAddUser(false)
        infoModal.current.open()

    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    //  ON RESET USER PASSWORD
    // Async state for submit add hashes
    const [
        isLoadingResetPasswd,
        setIsLoadingResetPasswd,
        isErrorResetPasswd,
        setIsErrorResetPasswd
    ] = useAsyncFetch()

    const onSubmitResetPasswd = async (e) => {
        e.preventDefault()

        let submitData = {}
        const data = new FormData(e.target);
        data.forEach((value, key) => value !== '' ? submitData[key] = value: '');
        setIsLoadingResetPasswd(true)
        setIsErrorResetPasswd({
            error: false,
            statusCode: 0,
            message: ''
        })
        try {
            const resp = await fetchResetPasswordUser(authToken.access, editUser.id, submitData)
            const respJson = await resp.json()
            //  SUCCESS
            if (resp.status === 200) {
                setInfoModalContent({
                    title: "Information",
                    content: "Password reset successfully"
                })
                editUserModal.current.close()
                //  Try to refresh table
                setRefreshCall(String(Date.now()))
            }
            else {
                setIsErrorResetPasswd({
                    error: true,
                    statusCode: resp.status,
                    message: respJson.detail
                })
                setInfoModalContent({
                    title: "Error",
                    content: typeof respJson.detail === 'object' ? respJson.detail[Object.keys(respJson.detail)[0]][0] : respJson.detail
                })
            }
        }
        catch (err) {
            setIsErrorResetPasswd({
                error: true,
                statusCode: 0,
                message: err.message
            })
            setInfoModalContent({
                title: "Error",
                content: "Password reset unsuccessfully"
            })
        }
        setIsLoadingResetPasswd(false)
        infoModal.current.open()

    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    //  ON UPDATE USER PROFILE
    // Async state for submit add hashes
    const [
        isLoadingUpdateProfile,
        setIsLoadingUpdateProfile,
        isErrorUpdateProfile,
        setIsErrorUpdateProfile,
    ] = useAsyncFetch()

    const onSubmitUpdateProfile = async (e) => {
        e.preventDefault()

        let submitData = {}
        const data = new FormData(e.target);
        data.forEach((value, key) => value !== '' ? submitData[key] = value : '');
        setIsLoadingUpdateProfile(true)
        setIsErrorUpdateProfile({
            error: false,
            statusCode: 0,
            message: ''
        })
        try {
            const resp = await fetchUpdateUser(authToken.access, editUser.id, submitData)
            const respJson = await resp.json()
            //  SUCCESS
            if (resp.status === 200) {
                setInfoModalContent({
                    title: "Information",
                    content: "Update user profile successfully"
                })
                editUserModal.current.close()
                //  Try to refresh table
                setRefreshCall(String(Date.now()))
            }
            else {
                setIsErrorUpdateProfile({
                    error: true,
                    statusCode: resp.status,
                    message: respJson.detail
                })
                setInfoModalContent({
                    title: "Error",
                    content: typeof respJson.detail === 'object' ? respJson.detail[Object.keys(respJson.detail)[0]][0] : respJson.detail
                })
            }
        }
        catch (err) {
            setIsErrorUpdateProfile({
                error: true,
                statusCode: 0,
                message: err.message
            })
            setInfoModalContent({
                title: "Error",
                content: "Update user profile unsuccessfully"
            })
        }
        setIsLoadingUpdateProfile(false)
        infoModal.current.open()
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////// 
    // 
    // DATA TABLE
    const [searchInput, setSearchInput] = useState();
    const [
        pagination,
        setPagination,
        sortField,
        setSortField,
        filter,
        setFilter,
        data,
        nextPage,
        previous,
        totalItem,
        isLoading,
        isError,
        setRefreshCall
    ] = useListUsersFetch(0, 25)

    // Table columns
    const columns = useMemo(() => [
        {
            Header: "username",
            accessor: "username"
        },
        {
            Header: "email",
            accessor: "email"
        },
        {
            Header: "Date Joined",
            accessor: "date_joined",
            Cell: ({ value }) => {
                return isoToDateNow(value)
            }
        },
        {
            Header: "Name",
            accessor: (row) => `${row.first_name} ${row.last_name}`,
            disableSortBy: true
        },
        {
            Header: "Enabled",
            accessor: "is_active",
            Cell: ({ value }) => (value ? <HiCheck className="w-5 h-5 text-green-400" /> : <HiBan className="w-5 h-5 text-red-500" />),
        },
        {
            Header: "Role",
            accessor: "is_staff",
            Cell: ({ value }) => (value ? <BadgeGreen>Admin</BadgeGreen> : <BadgeGray>User</BadgeGray>),
        }
    ],
        [])

    // Table hook
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        // We need to get 'sortBy' from the table state, then use it as an argument to fetch new data
        state: { sortBy }
    } = useTable(
        {
            columns,
            data,
            // we need to tell react-table that we will handle sorting manually
            manualSortBy: true
        },
        useSortBy
    );

    // Handle sort by server site
    useEffect(() => {
        if (sortBy.length > 0) {
            setSortField(`${sortBy[0].desc ? '-' : ''}${sortBy[0].id}`)
        }
    }, [sortBy])

    // Handle search
    useEffect(() => {
        const timeOutId = setTimeout(() => setFilter(searchInput), 500);
        return () => clearTimeout(timeOutId);
    }, [searchInput])


    //////////////////////////////////////////////// JSX ////////////////////////////////////////////////////
    return (
        <div className="max-w-screen max-h-screen bg-theme-bg-primary text-white flex">
            <Sidebar></Sidebar>
            {/* main */}
            <div className="grow flex flex-col align-top max-w-main gap-4">
                <Navbar>
                    <div className="flex items-end">
                        <a className="text-4xl text-theme-primary">User Management</a>
                    </div>
                </Navbar>
                <div className="px-4 pb-4 grow overflow-x-auto">
                    <div className="h-full flex flex-col">
                        <div className="flex items-center justify-between">
                            <h2 className="uppercase text-lg text-theme-primary">Users Information</h2>
                            <div className="flex items-center gap-2">
                                <input className="input input-txt min-w-[18rem]" placeholder="Search" value={searchInput} onChange={event => setSearchInput(event.target.value)}></input>
                                <PrimaryBtn onClick={() => { addUserModal.current.open() }} className="flex items-center py gap-1 text-sm">
                                    <HiOutlineUserAdd className="w-5 h-5" />
                                    <span>Add User</span>
                                </PrimaryBtn>
                            </div>
                        </div>
                        <TableBg>
                            <Table {...getTableProps()}>
                                <Thread>
                                    {
                                        headerGroups.map((headerGroup) => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {
                                                    headerGroup.headers.map((column) => (
                                                        <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                            <div className="flex items-center  gap-2 ">
                                                                <span>{column.render("Header")}</span>
                                                                {
                                                                    column.isSorted ?
                                                                        column.isSortedDesc ?
                                                                            <HiChevronDown className="w-4 h-4" /> :
                                                                            <HiChevronUp className="w-4 h-4" /> :
                                                                        <></>
                                                                }
                                                            </div>
                                                        </Th>
                                                    ))
                                                }
                                            </tr>
                                        ))
                                    }
                                </Thread>
                                <Tbody {...getTableBodyProps()}>
                                    {rows.map((row, i) => {
                                        prepareRow(row);
                                        return (
                                            <Tr {...row.getRowProps()} onClick={() => { setEditUser(row.original); editUserModal.current.open() }}>
                                                {row.cells.map((cell) => {
                                                    return (
                                                        <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                                                    );
                                                })}
                                            </Tr>
                                        );
                                    })}
                                </Tbody>
                            </Table>
                        </TableBg>
                        <div className="mt-auto flex items-center justify-between">
                            <div className="flex items-center justify-between gap-2">
                                <CustomListBox options={pageSize} defaultIndex={0} directionPopup="top" onChangeCallBack={value => setPagination(prevState => ({ ...prevState, limit: value.value }))}></CustomListBox>
                                <span className="whitespace-nowrap text-sm">items per page of {totalItem} total items</span>
                            </div>
                            <div className="">
                                <PaginationBtn disabled={previous ? false : true} onClick={() => setPagination(prevState => ({ ...prevState, offset: prevState.offset - prevState.limit }))}><HiChevronLeft className="w-5 h-5" /></PaginationBtn>
                                <PaginationBtn disabled={nextPage ? false : true} onClick={() => setPagination(prevState => ({ ...prevState, offset: prevState.offset + prevState.limit }))}><HiChevronRight className="w-5 h-5" /></PaginationBtn>
                            </div>
                        </div>
                        <MediumModal ref={addUserModal} modalTitle="Add User">
                            {/* ////////////////////////////////////////////// ADD USER ////////////////////////////////////////////////////// */}
                            <form onSubmit={onSubmitAddUser} className="w-full flex flex-col gap-8">
                                {/* body */}
                                <div className="w-full flex flex-col gap-4">
                                    <div className="flex flex-col gap-1">
                                        <label className="text-white">Username<span className="text-red-500">*</span></label>
                                        <input type="text" name="username" required placeholder="Username" className="input input-txt"></input>
                                    </div>
                                    <div className="w-full grow flex flex-row gap-4">
                                        <div className="flex flex-col gap-1">
                                            <label className="text-white">First Name</label>
                                            <input type="text" name="first_name" placeholder="First name of user" className="input input-txt"></input>
                                        </div>
                                        <div className="flex flex-col gap-1 grow">
                                            <label className="text-white">Last Name</label>
                                            <input type="text" name="last_name" placeholder="Last name of user" className="input input-txt"></input>
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-1">
                                        <label className="text-white">Email</label>
                                        <input type="email" name="email" placeholder="Email address" className="input input-txt"></input>
                                    </div>
                                    <div className="flex flex-col gap-1">
                                        <label className="text-white">User Role</label>
                                        <div className="mt-2 flex flex-row justify-evenly items-evently">
                                            <div className="flex gap-2">
                                                <input type="radio" name="is_staff" required value="false" defaultChecked className="input-radio"></input>
                                                <label className="text-gray-400 text-sm min-w-[6rem]">User</label>
                                            </div>
                                            <div className="flex gap-2">
                                                <input type="radio" name="is_staff" required value="true" className="input-radio"></input>
                                                <label className="text-gray-400 text-sm min-w-[6rem]">Administrator</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" gap-1 table">
                                        <label className="text-white">Enabled User</label>
                                        <div className="mt-2 flex flex-row justify-evenly  ">
                                            <div className="flex gap-2">
                                                <input type="radio" name="is_active" required value="true" defaultChecked className="input-radio"></input>
                                                <label className="text-gray-400 text-sm min-w-[6rem]">Enabled</label>
                                            </div>
                                            <div className="flex gap-2">
                                                <input type="radio" name="is_active" required value="false" className="input-radio"></input>
                                                <label className="text-gray-400 text-sm min-w-[6rem]">Disabled</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-1">
                                        <label className="text-white">Password<span className="text-red-500">*</span></label>
                                        <input type="password" name="password" required placeholder="Password" className="input input-txt"></input>
                                    </div>
                                    <div className="flex flex-col gap-1">
                                        <label className="text-white">Confirm Password<span className="text-red-500">*</span></label>
                                        <input type="password" name="confirm_password" required placeholder="Confirm Password" className="input input-txt"></input>
                                    </div>

                                </div>
                                {/* footer */}
                                <div className="flex justify-end gap-4">
                                    <PrimaryBtn type="submit" className="text-sm">Add User</PrimaryBtn>
                                    <SecondaryBtn className="text-sm" onClick={() => addUserModal.current.close()}>Cancel</SecondaryBtn>
                                </div>
                            </form>
                        </MediumModal>
                        <MediumModal ref={editUserModal} modalTitle="Edit User Information">
                            <Tab.Group as="div" className="w-full">

                                <Tab.List as="div" className="flex justify-center items-center">
                                    <Tab className={({ selected }) => selected ? 'bg-theme-primary text-theme-bg-primary tab-btn' : 'bg-theme-bg-secondary text-white tab-btn'}>Edit User Profile</Tab>
                                    <Tab className={({ selected }) => selected ? 'bg-theme-primary text-theme-bg-primary tab-btn' : 'bg-theme-bg-secondary text-white tab-btn'}>Reset Password</Tab>
                                </Tab.List>
                                <Tab.Panels>
                                    {/* ////////////////////////////////////////////// EDIT USER PROFILE  ////////////////////////////////////////////////////// */}
                                    <Tab.Panel>
                                        {
                                            editUser ?
                                                <form onSubmit={onSubmitUpdateProfile} className="w-full flex flex-col gap-8">
                                                    {/* body */}
                                                    <div className="w-full flex flex-col gap-4">
                                                        <div className="flex flex-col gap-1">
                                                            <label className="text-white">Username<span className="text-red-500">*</span></label>
                                                            <input type="text" readOnly placeholder="Username" className="input input-txt" value={editUser.username}></input>
                                                        </div>
                                                        <div className="w-full grow flex flex-row gap-4">
                                                            <div className="flex flex-col gap-1">
                                                                <label className="text-white">First Name</label>
                                                                <input type="text" name="first_name" placeholder="First name of user" className="input input-txt" value={editUser.first_name} onChange={(e) => setEditUser(prevState => ({ ...prevState, first_name: e.target.value }))}></input>
                                                            </div>
                                                            <div className="flex flex-col gap-1 grow">
                                                                <label className="text-white">Last Name</label>
                                                                <input type="text" name="last_name" placeholder="Last name of user" className="input input-txt" value={editUser.last_name} onChange={(e) => setEditUser(prevState => ({ ...prevState, last_name: e.target.value }))}></input>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-col gap-1">
                                                            <label className="text-white">Email</label>
                                                            <input type="email" name="email" placeholder="Email address" className="input input-txt" value={editUser.email} onChange={(e) => setEditUser(prevState => ({ ...prevState, email: e.target.value }))}></input>
                                                        </div>
                                                        <div className="flex flex-col gap-1">
                                                            <label className="text-white">User Role</label>
                                                            <div className="mt-2 flex flex-row justify-evenly items-evently">
                                                                <div className="flex gap-2">
                                                                    <input type="radio" name="is_staff" required value="false" defaultChecked className="input-radio" checked={!editUser.is_staff} onChange={(e) => setEditUser(prevState => ({ ...prevState, is_staff: e.target.value === 'true' }))}></input>
                                                                    <label className="text-gray-400 text-sm min-w-[6rem]">User</label>
                                                                </div>
                                                                <div className="flex gap-2">
                                                                    <input type="radio" name="is_staff" required value="true" className="input-radio" checked={editUser.is_staff} onChange={(e) => setEditUser(prevState => ({ ...prevState, is_staff: e.target.value === 'true' }))}></input>
                                                                    <label className="text-gray-400 text-sm min-w-[6rem]">Administrator</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className=" gap-1 table">
                                                            <label className="text-white">Enabled User</label>
                                                            <div className="mt-2 flex flex-row justify-evenly  ">
                                                                <div className="flex gap-2">
                                                                    <input type="radio" name="is_active" required value="true" defaultChecked className="input-radio" checked={editUser.is_active} onChange={(e) => setEditUser(prevState => ({ ...prevState, is_active: e.target.value === 'true' }))}></input>
                                                                    <label className="text-gray-400 text-sm min-w-[6rem]">Enabled</label>
                                                                </div>
                                                                <div className="flex gap-2">
                                                                    <input type="radio" name="is_active" required value="false" className="input-radio" checked={!editUser.is_active} onChange={(e) => setEditUser(prevState => ({ ...prevState, is_active: e.target.value === 'true' }))}></input>
                                                                    <label className="text-gray-400 text-sm min-w-[6rem]">Disabled</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* footer */}
                                                    <div className="flex justify-end gap-4">
                                                        <PrimaryBtn type="submit" className="text-sm">Save</PrimaryBtn>
                                                        <SecondaryBtn className="text-sm" type="button" onClick={() => editUserModal.current.close()}>Cancel</SecondaryBtn>
                                                    </div>
                                                </form> :
                                                <></>
                                        }
                                    </Tab.Panel>
                                    <Tab.Panel>
                                        {/* ////////////////////////////////////////////// RESET PASSWORD FORM ////////////////////////////////////////////////////// */}
                                        <form onSubmit={onSubmitResetPasswd} className="w-full flex flex-col gap-8">
                                            {/* body */}
                                            <div className="w-full flex flex-col gap-4">
                                                <div className="flex flex-col gap-1">
                                                    <label className="text-white">Password<span className="text-red-500">*</span></label>
                                                    <input type="password" name="new_password" required placeholder="Password" className="input input-txt"></input>
                                                </div>
                                                <div className="flex flex-col gap-1">
                                                    <label className="text-white">Confirm Password<span className="text-red-500">*</span></label>
                                                    <input type="password" name="confirm_password" required placeholder="Confirm Password" className="input input-txt"></input>
                                                </div>

                                            </div>
                                            {/* footer */}
                                            <div className="flex justify-end gap-4">
                                                <PrimaryBtn type="submit" className="text-sm">Save</PrimaryBtn>
                                                <SecondaryBtn className="text-sm" type="button" onClick={() => editUserModal.current.close()}>Cancel</SecondaryBtn>
                                            </div>
                                        </form>
                                    </Tab.Panel>
                                </Tab.Panels>
                            </Tab.Group>

                        </MediumModal>
                        <SmallModal ref={infoModal} modalTitle={infoModalContent.title}>
                            <div className="grow flex flex-col items-center">
                                <span className="block text-center text-white">{infoModalContent.content}</span>
                                <PrimaryBtn className="mx-auto mt-auto min-w-[4rem] text-sm" onClick={() => { infoModal.current.close() }}>OK</PrimaryBtn>
                            </div>
                        </SmallModal>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default UserManagementPage