import { Listbox, Transition } from '@headlessui/react'
import React, { useState, Fragment, useEffect, useRef } from 'react'
import { HiOutlineChevronDown, HiCheck } from "react-icons/hi";

export const CustomListBox = ({ options, defaultIndex = 0, directionPopup = "bottom", widthFull = true, onChangeCallBack }) => {
    const mounted = useRef();
    const [selectedOption, setSelectedOption] = useState(options[defaultIndex]);
    useEffect(() => {
        if (!mounted.current) {
            // do componentDidMount logic
            mounted.current = true;
        } else {
            // do componentDidUpdate logic
            if (onChangeCallBack) {
                onChangeCallBack(selectedOption)
            }
        }
    }, [selectedOption])
    return (
        <div className={`relative ${widthFull ? 'w-full' : ''}`}>
            <Listbox value={selectedOption} onChange={setSelectedOption}>
                {({ open }) => (
                    <>
                        {/* <Listbox.Button className="w-full flex items-center justify-between py-1.5 px-2 rounded-lg bg-theme-input  border-2 border-theme-border focus:outline-none focus:ring-1 focus:ring-blue-400 focus:border-blue-400"> */}
                        <Listbox.Button className={`${widthFull ? 'w-full' : ''} min-w-[5rem] flex items-center justify-between input-txt gap-x-2`} value={selectedOption.value}>
                            <span className="block truncate">{selectedOption.title}</span>
                            <HiOutlineChevronDown className={`w-4 h-4 text-gray-500 animation ${open ? "rotate-180" : ""} `} />
                        </Listbox.Button>
                        <Transition
                            as={Fragment}
                            enter="transition ease-in-out duration-100"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Listbox.Options className={`absolute ${directionPopup === "bottom" ? "mt-2 top-full" : "mb-2 bottom-full"} inset-x-0  overflow-auto bg-theme-bg-secondary rounded-lg shadow-theme max-h-40 focus:outline-none z-40`}>
                                {options.map((option, index) => (

                                    <Listbox.Option
                                        key={index}
                                        value={option}
                                        disabled={option.unavailable}
                                        className={({ active }) => `cursor-pointer select-none relative py-2 pl-10 pr-4 ${option.unavailable ? "bg-theme-border" : ""} ${active ? "bg-theme-bg-700" : ""} `}
                                    >
                                        {({ selected }) => (
                                            <>
                                                {selected ? (
                                                    <span className="absolute text-sm inset-y-0 left-0 flex items-center pl-3 text-theme-primary">
                                                        <HiCheck className="w-5 h-5" />
                                                    </span>
                                                ) : null}
                                                <span className={` text-sm  ${option.unavailable ? "line-through text-theme-txt-200" : "text-theme-txt-100 whitespace-nowrap"}`}>{option.title}</span>
                                            </>
                                        )}

                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </>
                )}
            </Listbox>
        </div>
    )
}