import React, {useContext, useRef} from "react";
import { HiOutlineCalendar, HiPaperAirplane } from "react-icons/hi";
import { PrimaryBtn } from "../../styled_components/buttons";
import { useCommentFetch } from "../../hooks/api/CommentsFetch";
import { useAsyncFetch } from "../../hooks/AsyncFetch";
import { fetchCommentCreate } from "../../apis/comments";
import { AuthTokenContext } from "../../contexts/AuthTokenContext";
import { isoToDateNow } from "../../ultils/datetime_format";


const CommentsComponent = ({objectType, objectID}) => {
    const {authToken} = useContext(AuthTokenContext)
    const commentInput = useRef()
    const [
        isLoadingCreateComment,
        setIsLoadingCreateComment,
        isErrorCreateComment, 
        setIsErrorCreateComment
    ] = useAsyncFetch()
    const [        
        data,
        isLoading,
        isError,
        setRefreshCall,] =useCommentFetch(objectID, objectType)
    const onSubmitCreateComment = async (e) =>{
        e.preventDefault()
        let submitData = {}
        const data = new FormData(e.target);
        data.forEach((value, key) => submitData[key] = value);
        submitData.objectType=objectType
        submitData.objectID=objectID

        // call api to post data
        if(authToken){
            setIsLoadingCreateComment(true)
            try{
                const resp = await fetchCommentCreate(authToken.access,submitData)
                const respjson = await resp.json()
                if(resp.status===200){
                    setRefreshCall(Date())
                    // Clear comment input
                    commentInput.current.value=''
                }
                else{
                    setIsErrorCreateComment({
                        error: true,
                        statusCode: resp.status,
                        message: respjson.detail
                    })
                }
            }
            catch(err){
                setIsErrorCreateComment({
                    error: true,
                    statusCode: 0,
                    message: err.message
                })
            }
            setIsLoadingCreateComment(false)

        }
    }
    return (
        <div className="w-full flex flex-col gap-4 mt-2">
            {
                data?data.results.map((comment, index) => (
                    <div className="flex flex-col gap-4 bg-theme-bg-primary p-4 rounded-lg">
                        <div className="flex gap-2 items-center">
                            <div className="bg-theme-primary text-theme-bg-primary rounded-full w-14 h-14 flex items-center justify-center">
                                <span className="font-bold text-xl">{comment.owner.username.charAt(0).toUpperCase()}</span>
                            </div>
                            <div>
                                <span className="font-semibold text-base">{comment.owner.username}</span>
                                <p className="text-gray-400 font-light flex items-center gap-1 text-xs"><HiOutlineCalendar className="w-5 h-5" />{isoToDateNow(comment.createAt)}</p>
                            </div>
                        </div>
                        <p className="font-light break-all">{comment.comment}</p>
                    </div>
                ))
                :<></>
            }
            <div className="flex flex-col gap-4 bg-theme-bg-primary p-4 rounded-lg">
                <span className="font-semibold">Create a comment</span>
                <form name="comment_post" onSubmit={onSubmitCreateComment} className="flex flex-col items-end gap-2">
                    <input name="comment" ref={commentInput} placeholder="Write a comment" className="w-full input input-txt grow text-base" type="text" required></input>
                    <PrimaryBtn type="submit" className="flex items-center gap-2 text-sm">
                        <span>Post Comment</span>
                        <HiPaperAirplane className="w-5 h-5 rotate-90"/>
                    </PrimaryBtn>
                </form>

            </div>
        </div>
    )
}

export default CommentsComponent