import { useState, useEffect, useContext } from "react";
import { AuthTokenContext } from "../../contexts/AuthTokenContext";
import { fetchCommentList } from "../../apis/comments";
const defaultError = {
    error: false,
    statusCode: 0,
    message: ''
}

export const useCommentFetch = (objectID, objectType) => {
    const {authToken, setAuthToken} = useContext(AuthTokenContext)
    const [data, setData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(defaultError)
    const [refreshCall, setRefreshCall] = useState()
    useEffect( async ()=>{
        if(authToken['access']){ 
            // call api to fetch data
            try{
                setIsLoading(true)
                const resp = await fetchCommentList(authToken.access, objectType, objectID)
                const respjson = await resp.json()
                // if status is 200 set data, total items, next, and previous
                if(resp.status===200){
                    setIsError(defaultError)
                    setData(respjson)
                }
                else{
                    setIsError({
                        error: true,
                        statusCode: resp.status,
                        message: respjson.detail
                    })
                }
                setIsLoading(false)
            }
            catch(err){
                setIsLoading(false)
                setIsError({
                    error: true,
                    statusCode: 0,
                    message: err
                })
            }
        }
    },[refreshCall])
    return [
        data,
        isLoading,
        isError,
        setRefreshCall,
    ]
}