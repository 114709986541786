import React, { useState, useEffect, useContext, useRef } from "react";

// Components
import Navbar from "../../../components/navbar";
import Sidebar from "../../../components/sidebar";

// Styled components
import { PrimaryBtn, SecondaryBtn } from "../../../styled_components/buttons";
import {
    HiOutlinePencil,
} from "react-icons/hi";
import { SmallModal } from "../../../styled_components/modals";

// Ultils
import { isoToDateFormat } from "../../../ultils/datetime_format";

// Context
import { AuthTokenContext } from "../../../contexts/AuthTokenContext";
import { UserInfoContext } from "../../../contexts/UserInfoContext";

// Hooks
import { useAsyncFetch } from "../../../hooks/AsyncFetch";

// API
import { fetchUpdateProfile, fetchChangePassword } from "../../../apis/auth";

const ProfilePage = () => {
    const { authToken, setAuthToken } = useContext(AuthTokenContext)
    const { userInfo, setUserInfo } = useContext(UserInfoContext)
    const infoModal = useRef()
    const [infoModalContent, setInfoModalContent] = useState({
        title: "",
        content: "",
    })
    const [editProfile, setEditProfile] = useState(userInfo)
    const [enableEdit, setEnableEdit] = useState(false)

    useEffect(() => {
        if(!enableEdit){
            setEditProfile(userInfo)
        }
    }, [enableEdit])

    // 
    const [
        isLoadingUpdateProfile,
        setIsLoadingUpdateProfile,
        isErrorUpdateProfile,
        setIsErrorUpdateProfile
    ] = useAsyncFetch()

    const onUpdateProfile = async (e) => {
        e.preventDefault()
        let submitData = {}
        const data = new FormData(e.target);
        data.forEach((value, key) => submitData[key] = value);

        // Call API
        setIsLoadingUpdateProfile(true)
        setIsErrorUpdateProfile({
            error: false,
            statusCode: 0,
            message: ''
        })
        // try {
            const resp = await fetchUpdateProfile(authToken.access, submitData)
            const respJson = await resp.json()
            if (resp.status === 200) {
                setUserInfo(respJson)
                setInfoModalContent({
                    title: 'Information',
                    content: "User profile updated successfully"
                })
            }
            else {
                setIsErrorUpdateProfile({
                    error: true,
                    statusCode: resp.status,
                    message: respJson.detail
                })
                setInfoModalContent({
                    title: 'Error',
                    content: respJson.detail
                })
            }
        infoModal.current.open()
    }

    const [
        isLoadingChangePassword,
        setIsLoadingChangePassword,
        isErrorChangePassword,
        setIsErrorChangePassword,
    ] = useAsyncFetch()

    const onChangePassword = async (e) => {
        e.preventDefault()
        let submitData = {}
        const data = new FormData(e.target);
        data.forEach((value, key) => submitData[key] = value);

        // Call API
        setIsLoadingUpdateProfile(true)
        setIsErrorUpdateProfile({
            error: false,
            statusCode: 0,
            message: ''
        })
        // try {
            const resp = await fetchChangePassword(authToken.access, submitData)
            const respJson = await resp.json()
            if (resp.status === 200) {
                setUserInfo(respJson)
                setInfoModalContent({
                    title: 'Information',
                    content: "Your password was change successfully"
                })
            }
            else {
                setIsErrorUpdateProfile({
                    error: true,
                    statusCode: resp.status,
                    message: respJson.detail
                })
                setInfoModalContent({
                    title: 'Error',
                    content: typeof respJson.detail === 'object'?respJson.detail[Object.keys(respJson.detail)[0]][0]:respJson.detail
                })
            }
        infoModal.current.open()
    }

    return (
        <div className="max-w-screen min-h-screen bg-theme-bg-primary text-white flex">
            <Sidebar></Sidebar>
            {/* main */}
            <div className="grow flex flex-col align-top max-w-main gap-4">
                <Navbar>
                    <div className="flex items-end">
                        <a className="text-4xl text-theme-primary">User Profile</a>
                    </div>
                </Navbar>
                <div className="p-4 overflow-auto">
                    <form name="updateProfile" onSubmit={onUpdateProfile} className=" bg-theme-bg-secondary shadow-theme rounded-lg p-4 lg:mx-40 2xl:mx-56 flex flex-col gap-2">
                        <div className="flex flex-row justify-between">
                            <h2 className="text-theme-primary uppercase text-lg">Current User Profile</h2>
                            <PrimaryBtn className="text-sm flex flex-row gap-1 justify-center items-center" type="button" onClick={(e) => setEnableEdit(prevState => !prevState)}>
                                <span>Edit Profile</span>
                                <HiOutlinePencil className="w-4 h-4" />
                            </PrimaryBtn>
                        </div>
                        <div className="flex flex-col gap-1">
                            <span className="">Username:</span>
                            <input className="input input-txt" readOnly value={userInfo.username}></input>
                        </div>
                        <div className="flex flex-col gap-1">
                            <span className="">First Name:</span>
                            <input name="first_name" className="input input-txt"  readOnly={!enableEdit} onChange={(event) => setEditProfile((prevState) => ({ ...prevState, first_name: event.target.value }))} value={editProfile.first_name} placeholder="First Name"></input>
                        </div>
                        <div className="flex flex-col gap-1">
                            <span className="">Last Name:</span>
                            <input name="last_name" className="input input-txt" readOnly={!enableEdit} onChange={(event) => setEditProfile((prevState) => ({ ...prevState, last_name: event.target.value }))} value={editProfile.last_name} placeholder="Last Name"></input>
                        </div>
                        <div className="flex flex-col gap-1">
                            <span className="">Email:</span>
                            <input name="email" type="email" className="input input-txt" readOnly={!enableEdit} onChange={(event) => setEditProfile((prevState) => ({ ...prevState, email: event.target.value }))} value={editProfile.email} placeholder="Email"></input>
                        </div>
                        <div className="flex flex-col gap-1">
                            <span className="">Permissions:</span>
                            <input className="input input-txt" readOnly value={userInfo.is_staff ? "Admin" : "User"}></input>
                        </div>
                        <div className="flex flex-col gap-1">
                            <span className="">Date Joined:</span>
                            <input className="input input-txt" readOnly value={isoToDateFormat(userInfo.date_joined)}></input>
                        </div>
                        <div className="flex flex-col gap-1">
                            <span className="">Last Login:</span>
                            <input className="input input-txt" readOnly value={userInfo.last_login ? isoToDateFormat(userInfo.date_joined) : '-'}></input>
                        </div>
                        <div className={`mt-2 flex flex-row justify-end gap-4 ${!enableEdit ? "hidden" : ""}`}>
                            <PrimaryBtn className="text-sm" type="submit">Save</PrimaryBtn>
                            <SecondaryBtn className="text-sm" type="button" onClick={() => setEnableEdit(false)}>Cancel</SecondaryBtn>
                        </div>
                    </form>
                    <form onSubmit={onChangePassword} className=" mt-4 bg-theme-bg-secondary shadow-theme rounded-lg p-4 lg:mx-40 2xl:mx-56 flex flex-col gap-2">
                        <h2 className="text-theme-primary uppercase text-lg">Change Password</h2>
                        <div className="flex flex-col gap-1">
                            <span className="">Current Password:</span>
                            <input type="password" name="password" className="input input-txt" placeholder="Current Password"></input>
                        </div>
                        <div className="flex flex-col gap-1">
                            <span className="">New Password:</span>
                            <input type="password" name="new_password" className="input input-txt" placeholder="New Password"></input>
                        </div>
                        <div className="flex flex-col gap-1">
                            <span className="">Repeat New Password:</span>
                            <input type="password" name="confirm_password" className="input input-txt" placeholder="Repeat New Password"></input>
                        </div>
                        <div className="mt-2 flex flex-row justify-end">
                            <PrimaryBtn type="submit" className="text-sm">Change Password</PrimaryBtn>
                        </div>
                    </form>
                </div>
                <SmallModal ref={infoModal} modalTitle={infoModalContent.title}>
                    <div className="grow flex flex-col items-center">
                        <span className="block text-center text-white">{infoModalContent.content}</span>
                        <PrimaryBtn className="mx-auto mt-auto min-w-[4rem] text-sm" onClick={() => { infoModal.current.close() }}>OK</PrimaryBtn>
                    </div>
                </SmallModal>
            </div>
        </div>
    )
}
export default ProfilePage