import React, { useEffect, useMemo, useState, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTable, useSortBy } from "react-table";

// Components
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";

// Styled Components
import { BadgeRed, BadgeGreen, BadgeGray } from "../../styled_components/badges";
import { PrimaryBtn, PaginationBtn, SecondaryBtn } from "../../styled_components/buttons";
import { TableBg, Table, Thread, Th, Td, Tr, Tbody } from "../../styled_components/tables";
import { CustomListBox } from "../../styled_components/listbox";
import { MediumModal, SmallModal } from "../../styled_components/modals";
import {
    HiOutlinePlus,
    HiChevronDown,
    HiChevronUp,
    HiChevronLeft,
    HiChevronRight
} from "react-icons/hi";

// Custom hooks
import { useListFileSamplesFetch } from "../../hooks/api/FileSamplesFetch";
import { useAsyncFetch } from "../../hooks/AsyncFetch";

// Ultils
import { isoToDateNow } from "../../ultils/datetime_format";

// Context
import { AuthTokenContext } from "../../contexts/AuthTokenContext";

// APIs
import { fetchCreateFileSample } from "../../apis/file-samples";

const pageSize = [
    {
        value: 25,
        title: '25'
    },
    {
        value: 50,
        title: '50'
    },
    {
        value: 100,
        title: '100'
    },
    {
        value: 200,
        title: '200'
    },
]

const SamplesPage = () => {
    const navigator = useNavigate()
    // 
    // Upload sample
    // 

    // Authentication token in context
    const { authToken, setAuthToken } = useContext(AuthTokenContext)
    
    const [
        isLoadingCreateSample,
        setIsLoadingCreateSample,
        isErrorCreateSample,
        setIsErrorCreateSample
    ] = useAsyncFetch()

    const uploadModal = useRef()
    const infoModal = useRef()
    const [infoModalContent, setInfoModalContent] = useState({
        title: '',
        content:''
    })

    const onSubmitUploadSample = async (e) => {
        e.preventDefault()

        // extract checkStatic and checkCloud
        let submitData = {}
        const data = new FormData(e.target);
        data.forEach((value, key) => key !== 'sample' ? submitData[key] = value : '');
        data.delete('checkStatic')
        data.delete('checkCloud')

        //  convert checkStatic and checkCloud from str => bool
        if (submitData.checkStatic === "true") {
            submitData.checkStatic = true
        }
        else {
            submitData.checkStatic = false
        }
        if (submitData.checkCloud === "true") {
            submitData.checkCloud = true
        }
        else {
            submitData.checkCloud = false
        }
        //  Append json string of checkStatic and checkCloud to form data
        data.append("json", JSON.stringify(submitData))

        // Call api to create sample
        setIsLoadingCreateSample(true)
        setIsErrorCreateSample({
            error: false,
            statusCode: 0,
            message: ''
        })
        try {
            const resp = await fetchCreateFileSample(authToken.access, data)
            const respjson = await resp.json()
            //  SUCCESS
            if(resp.status===200){
                setInfoModalContent({
                    title:"Information",
                    content:"Sample created successfully"
                })
                uploadModal.current.close()
                //  Try to refresh table
                setRefreshCall(String(Date.now()))
            }
            // UNAUTHORIZED
            else if(resp.status ===401){

                localStorage.removeItem('authToken')
                setAuthToken(null)
                
            }
            // Other ERROR HANDLER
            else{
                setIsErrorCreateSample({
                    error: true,
                    statusCode: resp.status,
                    message: respjson.detail
                })
                setInfoModalContent({
                    title:"Error",
                    content:`Sample created unsuccessfully. ${respjson.detail}`
                })
            }
            setIsLoadingCreateSample(false)
        }
        catch (err) {
            setIsLoadingCreateSample(false)
            setIsErrorCreateSample({
                error: true,
                statusCode: 0,
                message: err.message
            })
            setInfoModalContent({
                title:"Error",
                content:`Sample created unsuccessfully.`
            })
        }
        infoModal.current.open()
    }
    // 
    // Data table
    // 

    // State of search input
    const [searchInput, setSearchInput] = useState();
    const [
        pagination,
        setPagination,
        sortField,
        setSortField,
        filter,
        setFilter,
        data,
        nextPage,
        previous,
        totalItem,
        isLoading,
        isError,
        setRefreshCall
    ] = useListFileSamplesFetch(0, 25, '-latest_check_time')

    // If fetch return 401 => session time out
    useEffect(()=>{
        if(isError.error && isError.statusCode===401){
            localStorage.removeItem('authToken')
            localStorage.removeItem('userInfo')
            setAuthToken(null)
        }
    },[isError])


    const columns = useMemo(() => [
        {
            Header: "filename",
            accessor: "filename"
        },
        // {
        //     Header: "owner",
        //     accessor: "owner"
        // },
        {
            Header: "upload time",
            accessor: "upload_time",
            Cell: ({ value }) => {
                return isoToDateNow(value)
            }
        },
        {
            Header: "Latest Analysis",
            accessor: "hashes.latest_check_time",
            Cell: ({ value }) => {
                return isoToDateNow(value)
            }
        },
        {
            Header: "md5",
            accessor: "hashes.md5"
        },
        {
            Header: "result",
            accessor: "hashes.is_malicious",
            Cell: ({ value }) => {
                if (value===true) {
                    return <BadgeRed>Malicious</BadgeRed>;
                }
                else if(value===false){
                    return <BadgeGreen>Clean</BadgeGreen>
                }
                return <BadgeGray>Unknown</BadgeGray>
            },
        }
    ],
        [])
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        // We need to get 'sortBy' from the table state, then use it as an argument to fetch new data
        state: { sortBy }
    } = useTable(
        {
            columns,
            data,
            // we need to tell react-table that we will handle sorting manually
            manualSortBy: true
        },
        useSortBy
    );

    // Handle sort by server site
    useEffect(() => {
        if (sortBy.length > 0) {
            setSortField(`${sortBy[0].desc ? '-' : ''}${sortBy[0].id}`)
        }
    }, [sortBy])

    // Handle search
    useEffect(() => {
        const timeOutId = setTimeout(() => setFilter(searchInput), 500);
        return () => clearTimeout(timeOutId);
    }, [searchInput])

    return (
        <div className="max-w-screen max-h-screen bg-theme-bg-primary text-white flex">
            <Sidebar></Sidebar>
            {/* main */}
            <div className="grow flex flex-col align-top max-w-main gap-4">
                <Navbar>
                    <div className="flex items-end">
                        <a className="text-4xl text-theme-primary">File Samples</a>
                    </div>
                </Navbar>
                <div className="px-4 pb-4 grow overflow-x-auto">
                    <div className="h-full flex flex-col">
                        <div className="flex items-center justify-between">
                            <h2 className="uppercase text-lg text-theme-primary">Analyzed File Samples</h2>
                            <div className="flex items-center gap-2">
                                <input className="input input-txt min-w-[18rem]" placeholder="Search" value={searchInput} onChange={event => setSearchInput(event.target.value)}></input>
                                <PrimaryBtn className="flex items-center py gap-1 text-sm" onClick={() => uploadModal.current.open()}>
                                    <HiOutlinePlus className="w-5 h-5" />
                                    <span>Add Sample</span>
                                </PrimaryBtn>
                            </div>
                        </div>
                        <TableBg>
                            <Table {...getTableProps()}>
                                <Thread>
                                    {
                                        headerGroups.map((headerGroup) => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {
                                                    headerGroup.headers.map((column) => (
                                                        <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                            <div className="flex items-center  gap-2 ">
                                                                <span>{column.render("Header")}</span>
                                                                {
                                                                    column.isSorted ?
                                                                        column.isSortedDesc ?
                                                                            <HiChevronDown className="w-4 h-4" /> :
                                                                            <HiChevronUp className="w-4 h-4" /> :
                                                                        <></>
                                                                }
                                                            </div>
                                                        </Th>
                                                    ))
                                                }
                                            </tr>
                                        ))
                                    }
                                </Thread>
                                <Tbody {...getTableBodyProps()}>
                                    {rows.map((row, i) => {
                                        prepareRow(row);
                                        return (
                                            <Tr {...row.getRowProps()} onClick={() => navigator(`/samples/${row.original.id}`)}>
                                                {row.cells.map((cell) => {
                                                    return (
                                                        <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                                                    );
                                                })}
                                            </Tr>
                                        );
                                    })}
                                </Tbody>
                            </Table>
                        </TableBg>
                        <div className="mt-auto flex items-center justify-between">
                            <div className="flex items-center justify-between gap-2">
                                <CustomListBox options={pageSize} defaultIndex={0} directionPopup="top" onChangeCallBack={value => setPagination(prevState => ({ ...prevState, limit: value.value }))}></CustomListBox>
                                <span className="whitespace-nowrap text-sm">items per page of {totalItem} total items</span>
                            </div>
                            <div className="">
                                <PaginationBtn disabled={previous ? false : true} onClick={() => setPagination(prevState => ({ ...prevState, offset: prevState.offset - prevState.limit }))}><HiChevronLeft className="w-5 h-5" /></PaginationBtn>
                                <PaginationBtn disabled={nextPage ? false : true} onClick={() => setPagination(prevState => ({ ...prevState, offset: prevState.offset + prevState.limit }))}><HiChevronRight className="w-5 h-5" /></PaginationBtn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <MediumModal ref={uploadModal} modalTitle="Add Sample">
                <form onSubmit={onSubmitUploadSample} className="w-full flex flex-col gap-8">
                    {/* body */}
                    <div className="w-full flex flex-col gap-5">
                        {/* file */}
                        <div className="flex flex-col">
                            <label className="text-white">Suspicions sample</label>
                            {/* <label className="text-gray-500 text-xs">Suspicions file need to Analyzed</label> */}
                            <input type="file" name="sample" required className="mx-2 mt-2 text-sm text-gray-500 border border-theme-border bg-theme-bg-900 rounded-lg file:text-theme-bg-primary file:bg-theme-primary file:hover:bg-theme-btn-primary-hover file:px-2 file:py-2 file:border-0 file:font-semibold file:mr-4 focus:ring-blue-400 focus:border-blue-400 focus:outline-none" />
                        </div>
                        <div className="flex flex-col gap-1">
                            <label className="text-white">Static analysis</label>
                            <label className="mx-2 text-gray-500 text-sm">Require upload suspicious to be static analyzed </label>
                            <div className="mt-2 flex flex-row justify-evenly items-evently">
                                <div className="flex gap-2">
                                    <input type="radio" name="checkStatic" required value="true" defaultChecked="checked" className="input-radio"></input>
                                    <label className="text-gray-400 text-sm min-w-[6rem]">Yes</label>
                                </div>
                                <div className="flex gap-2">
                                    <input type="radio" name="checkStatic" required value="false" className="input-radio"></input>
                                    <label className="text-gray-400 text-sm min-w-[6rem]">No</label>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-1">
                            <label className="text-white">Cloud Threat Intelligence</label>
                            <label className="mx-2 text-gray-500 text-sm">Require hash of upload suspicious to be searched on cloud system </label>
                            <div className="mt-2 flex flex-row justify-evenly items-evently">
                                <div className="flex gap-2">
                                    <input type="radio" name="checkCloud" required value="true" defaultChecked="checked" className="input-radio"></input>
                                    <label className="text-gray-400 text-sm min-w-[6rem]">Yes</label>
                                </div>
                                <div className="flex gap-2">
                                    <input type="radio" name="checkCloud" required value="false" className="input-radio"></input>
                                    <label className="text-gray-400 text-sm min-w-[6rem]">No</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* footer */}
                    <div className="flex justify-end gap-4">
                        <PrimaryBtn type="submit" className="text-sm">Add Sample</PrimaryBtn>
                        <SecondaryBtn className="text-sm" onClick={() => uploadModal.current.close()}>Cancel</SecondaryBtn>
                    </div>

                </form>
            </MediumModal>
            <SmallModal ref={infoModal} modalTitle={infoModalContent.title}>
                <div className="grow flex flex-col items-center">
                    <span className="block text-center text-white">{infoModalContent.content}</span>
                    <PrimaryBtn className="mx-auto mt-auto min-w-[4rem] text-sm" onClick={() => { infoModal.current.close() }}>OK</PrimaryBtn>
                </div>
            </SmallModal>
        </div>
    )
}
export default SamplesPage