import React from "react";
import * as Icons from "react-icons/hi";
import { ReactComponent as ExternalSettingsIcon } from '../../media/images/icons/external-settings.svg'
import { ReactComponent as MalwareIcon } from '../../media/images/icons/malware.svg'
import { ReactComponent as PhishingAlertIcon } from '../../media/images/icons/phishing-alert.svg'
import { ReactComponent as PhishingProfileIcon } from '../../media/images/icons/phishing-profile.svg'
import { ReactComponent as PhishingIcon } from '../../media/images/icons/phishing.svg'

const DynamicHiIcon = ({name, className}) =>{
    const styled = className?? ''
    const IconComponent = Icons[name]
    if(!IconComponent) {
        if(name=='ExternalSettingsIcon'){
            return <ExternalSettingsIcon className={styled}/>
        }
        if(name==='MalwareIcon'){
            return <MalwareIcon className={styled}/>
        }
        if(name==='PhishingAlertIcon'){
            return <PhishingAlertIcon className={styled}/>
        }
        if(name==='PhishingProfileIcon'){
            return <PhishingProfileIcon className={styled}/>
        }
        if(name==='PhishingIcon'){
            return <PhishingIcon className={styled}/>
        }
        return <Icons.HiAcademicCap className={styled}/>

    }
    return <IconComponent className={styled}/>
}
export default DynamicHiIcon