import { useState, useEffect } from "react";

export const useStoreState = (itemName, defaultValue) =>{
    const initialState = JSON.parse(localStorage.getItem(itemName))
    const [state, setState] = useState(initialState? initialState: defaultValue)
    
    useEffect(()=>{
        localStorage.setItem(itemName, JSON.stringify(state));
    }, [state])

    return [
        state,
        setState
    ]
}