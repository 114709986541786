import React, { useState, forwardRef, useImperativeHandle, Fragment } from "react";
import { Dialog, Transition } from '@headlessui/react'
import { HiX } from "react-icons/hi"

export const SmallModal = forwardRef(
    ({
        isOpenDefault = false,
        modalTitle,
        children
    }, ref) => {
        const [isModalOpen, setIsModalOpen] = useState(isOpenDefault)
        useImperativeHandle(ref,
            () => ({
                'open': () => setIsModalOpen(true),
                'close': () => setIsModalOpen(false)
            })
        )
        return (
            <Transition appear show={isModalOpen} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 flex flex-col justify-center items-center gap-5 w-full px-4" open={isModalOpen} onClose={() => setIsModalOpen(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-100"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 z-10 backdrop-blur-sm" />
                    </Transition.Child>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-100"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-100"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="min-w-[32rem] bg-theme-bg-primary z-10 rounded-lg shadow-theme" >
                            <Dialog.Title
                                as="span"
                                className="p-2 text-xl text-theme-primary flex flex-row items-center justify-between border-b border-gray-700"
                            >
                                {modalTitle}
                                <button className="hover:bg-theme-btn-primary-hover hover:text-theme-bg-primary p-2 rounded-full" onClick={() => setIsModalOpen(false)}>
                                    <HiX className="w-5 h-5" />
                                </button>
                            </Dialog.Title>
                            <div className="min-h-[8rem] p-3 flex flex-col items-center">
                                {children}
                            </div>
                        </div>
                    </Transition.Child>
                </Dialog>
            </Transition>
        )
    }
)

export const MediumModal = forwardRef(
    ({
        isOpenDefault = false,
        modalTitle,
        children
    }, ref) => {
        const [isModalOpen, setIsModalOpen] = useState(isOpenDefault)
        useImperativeHandle(ref,
            () => ({
                'open': () => setIsModalOpen(true),
                'close': () => setIsModalOpen(false)
            })
        )
        return (
            <Transition appear show={isModalOpen} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 flex flex-col justify-center items-center gap-5 w-full px-4" open={isModalOpen} onClose={() => setIsModalOpen(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-100"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 z-10  backdrop-blur-sm bg-gray-900/10" />
                    </Transition.Child>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-100"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-100"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="min-w-[40rem] bg-theme-bg-primary z-10 rounded-lg shadow-theme" >
                            <Dialog.Title
                                as="span"
                                className="p-2 text-xl text-theme-primary flex flex-row items-center justify-between border-b border-gray-700"
                            >
                                {modalTitle}
                                <button className="hover:bg-theme-btn-primary-hover hover:text-theme-bg-primary p-2 rounded-full" onClick={() => setIsModalOpen(false)}>
                                    <HiX className="w-5 h-5" />
                                </button>
                            </Dialog.Title>
                            <div className="min-h-[8rem] p-3 flex flex-col items-center">
                                {children}
                            </div>
                        </div>
                    </Transition.Child>
                </Dialog>
            </Transition>
        )
    }
)

export const SideModal = forwardRef(
    ({
        isOpenDefault = false,
        modalTitle,
        children
    }, ref) => {
        const [isModalOpen, setIsModalOpen] = useState(isOpenDefault)
        useImperativeHandle(ref,
            () => ({
                'open': () => setIsModalOpen(true),
                'close': () => setIsModalOpen(false)
            })
        )
        return (
            <Transition appear show={isModalOpen} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 w-full" open={isModalOpen} onClose={() => setIsModalOpen(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-100"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 z-10  backdrop-blur-sm" />
                    </Transition.Child>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-100"
                        enterFrom="opacity-0 translate-x-8"
                        enterTo="opacity-100 translate-x-0"
                        leave="ease-in duration-100"
                        leaveFrom="opacity-100 translate-x-0"
                        leaveTo="opacity-0 translate-x-8"
                    >
                        <div className="fixed inset-y-0 right-0 h-screen min-w-[40rem] bg-theme-bg-primary z-20 rounded-lg shadow-theme" >
                            <Dialog.Title
                                as="span"
                                className="p-4 text-xl text-theme-primary flex flex-row items-center justify-between border-b border-gray-700"
                            >
                                <button className="hover:bg-theme-btn-primary-hover hover:text-theme-bg-primary p- rounded-full" onClick={() => setIsModalOpen(false)}>
                                    <HiX className="w-5 h-5" />
                                </button>
                                {modalTitle}

                            </Dialog.Title>
                            <div className="h-modal-side flex flex-col p-3">
                                {children}
                            </div>
                        </div>
                    </Transition.Child>
                </Dialog>
            </Transition>
        )
    }
)