import { URL_BASE_API } from '..'

// List hashes
export const fetchHashesList = async (accessToken, queryParam) => {
    var url = `${URL_BASE_API}/api/samples/hashes/list/?`
    url = url + Object.keys(queryParam).reduce((result, key) => {
        return [...result, `${encodeURIComponent(key)}=${encodeURIComponent(queryParam[key])}`]
    }, []).join('&')
    const rawResponse = await fetch(url, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken,
        },
    });
    return rawResponse;
}

// Get hash detail
export const fetchHasheDetail = async (accessToken, hashID) => {
    var url = `${URL_BASE_API}/api/samples/hashes/${hashID}/`
    const rawResponse = await fetch(url, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken,
        },
    });
    return rawResponse;
}

// Request Cloud Check
export const fetchRequestReAnalysisHash = async (accessToken, hashID) => {
    const url = `${URL_BASE_API}/api/samples/hashes/cloud-check/${hashID}/`
    const payload = {
        "virustotal": true,
        "hybrid-analysis": true
    }
    const rawResponse = await fetch(url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken,
        },
        body: JSON.stringify(payload)
    });
    return rawResponse;
}

// Set hash malicious state
export const fetchMarkHashSampleIsMalicious = async (accessToken, hashID, isMalicious) => {
    const url = `${URL_BASE_API}/api/samples/hashes/set-malicious/${hashID}/`
    const payload = {
        "isMalicious": isMalicious
    }
    const rawResponse = await fetch(url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken,
        },
        body: JSON.stringify(payload)
    });
    return rawResponse;
}

// Share malicious hash to qradar
export const fetchShareSignaureToQradar = async (accessToken, hashID) => {
    const url = `${URL_BASE_API}/api/samples/hashes/send-qradar/${hashID}/`
    const rawResponse = await fetch(url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken,
        },
    });
    return rawResponse;
}

// Create a hash sample
export const fetchHashSampleCreate = async (accessToken, jsondata) => {
    const url = `${URL_BASE_API}/api/samples/hashes/create/`
    const rawResponse = await fetch(url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken,
        },
        body: JSON.stringify(jsondata)
    });
    return rawResponse;
}