import React from "react";
import { createContext, useState } from "react";

export  const UserInfoContext = createContext();

export const UserInfoContextProvider = (props) =>{
    const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('userInfo')));
    return(
        <UserInfoContext.Provider value={{userInfo, setUserInfo}}>
            {props.children}
        </UserInfoContext.Provider>
    )
}