import React from "react";
import { Disclosure } from "@headlessui/react";
import { HiOutlineChevronUp } from "react-icons/hi";
import { Transition } from "@headlessui/react";
const DisclosureComponent = ({ title, children }) => {
    return (
        <Disclosure as="div" className="mt-2">
            {({ open }) => (
                <>
                    <Disclosure.Button className="flex w-full justify-between rounded-lg shadow-theme bg-theme-bg-secondary px-4 py-2 text-left text-sm font-medium text-theme-primary hover:bg-theme-btn-primary-hover hover:text-theme-bg-primary focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                        <span>{title}</span>
                        <HiOutlineChevronUp
                            className={`${open ? 'rotate-180 transform' : ''
                                } h-5 w-5`}
                        />
                    </Disclosure.Button>
                    <Transition
                        enter="transition duration-100 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                    >
                        <Disclosure.Panel className="mt-2 text-white p-4 text-sm bg-theme-bg-secondary rounded-lg shadow-md">
                            {children}
                        </Disclosure.Panel>
                    </Transition>
                </>
            )}
        </Disclosure>
    )
}

export default DisclosureComponent