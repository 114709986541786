import React, { useEffect, useMemo, useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTable, useSortBy } from "react-table";

// Components
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";

// Style Components 
import { BadgeRed, BadgeGreen, BadgeGray, } from "../../styled_components/badges";
import { PrimaryBtn, PaginationBtn, SecondaryBtn } from "../../styled_components/buttons";
import { TableBg, Table, Thread, Th, Td, Tr, Tbody } from "../../styled_components/tables";
import { CustomListBox } from "../../styled_components/listbox";
import { MediumModal, SmallModal } from "../../styled_components/modals";
import {
    HiOutlinePlus,
    HiChevronDown,
    HiChevronUp,
    HiChevronLeft,
    HiChevronRight
} from "react-icons/hi";

// Context
import { AuthTokenContext } from "../../contexts/AuthTokenContext";

// Custom hooks
import { useListHashesFetch } from "../../hooks/api/HashesFetch";
import { useAsyncFetch } from "../../hooks/AsyncFetch";

// Ultils
import { isoToDateNow } from "../../ultils/datetime_format";

// API
import { fetchHashSampleCreate } from "../../apis/hash-sample";

const pageSize = [
    {
        value: 25,
        title: '25'
    },
    {
        value: 50,
        title: '50'
    },
    {
        value: 100,
        title: '100'
    },
    {
        value: 200,
        title: '200'
    },
]

const HashesPage = () => {
    // Modals
    const addHashModal = useRef()
    const infoModal = useRef()
    const [infoModalContent, setInfoModalContent] = useState({
        title: '',
        content:''
    })

    // Auth token context
    const { authToken, setAuthToken } = useContext(AuthTokenContext)
    const navigator = useNavigate()

    // Async state for submit add hashes
    const [
        isLoadingAddHash,
        setIsLoadingAddHash,
        isErrorAddHash, 
        setIsErrorAddHash
    ] = useAsyncFetch()

    // on submit add hash handler
    const onSubmitAddHash = async (e) => {
        e.preventDefault()

        // extract checkStatic and checkCloud
        let submitData = {}
        const data = new FormData(e.target);
        data.forEach((value, key) => value !== '' ? submitData[key] = value.toLowerCase() : '');
        
        setIsLoadingAddHash(true)
        setIsErrorAddHash({
            error: false,
            statusCode: 0,
            message: ''
        })
        try {
            const resp = await fetchHashSampleCreate(authToken.access, submitData)
            const respjson = await resp.json()
            //  SUCCESS
            if(resp.status===200){
                setInfoModalContent({
                    title:"Information",
                    content:"Hash sample created successfully"
                })
                addHashModal.current.close()
                //  Try to refresh table
                setRefreshCall(String(Date.now()))
            }
            // UNAUTHORIZED
            else if(resp.status ===401){
                localStorage.removeItem('authToken')
                localStorage.removeItem('userInfo')
                setAuthToken(null)
            }
            // Other ERROR HANDLER
            else{
                setIsErrorAddHash({
                    error: true,
                    statusCode: resp.status,
                    message: respjson.detail
                })
                setInfoModalContent({
                    title:"Error",
                    content:`Hash sample created unsuccessfully. ${respjson.detail}`
                })
            }
            setIsLoadingAddHash(false)
        }
        catch (err) {
            setIsErrorAddHash({
                error: true,
                statusCode: 0,
                message: err.message
            })
            setInfoModalContent({
                title:"Error",
                content:`Hash sample created unsuccessfully.`
            })
        }
        infoModal.current.open()
        
    }

    // Data table
    const [searchInput, setSearchInput] = useState();
    const [
        pagination,
        setPagination,
        sortField,
        setSortField,
        filter,
        setFilter,
        data,
        nextPage,
        previous,
        totalItem,
        isLoading,
        isError,
        setRefreshCall
    ] = useListHashesFetch(0, 25, '-latest_check_time')

    // Error if can not fetch hashes 
    useEffect(() => {
        if (isError.error && isError.statusCode === 401) {
            localStorage.removeItem('authToken')
            localStorage.removeItem('userInfo')
            setAuthToken(null)
        }
    }, [isError])

    // Table columns
    const columns = useMemo(() => [
        {
            Header: "md5",
            accessor: "md5"
        },
        {
            Header: "sha256",
            accessor: "sha256"
        },
        {
            Header: "first check",
            accessor: "first_check_time",
            Cell: ({ value }) => {
                return isoToDateNow(value)
            }
        },
        {
            Header: "latest check",
            accessor: "latest_check_time",
            Cell: ({ value }) => {
                return isoToDateNow(value)
            }
        },
        {
            Header: "result",
            accessor: "is_malicious",
            Cell: ({ value }) => {
                if (value === true) {
                    return <BadgeRed>Malicious</BadgeRed>;
                }
                else if (value === false) {
                    return <BadgeGreen>Clean</BadgeGreen>
                }
                return <BadgeGray>Unknown</BadgeGray>
            },
        }
    ],
        [])
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        // We need to get 'sortBy' from the table state, then use it as an argument to fetch new data
        state: { sortBy }
    } = useTable(
        {
            columns,
            data,
            // we need to tell react-table that we will handle sorting manually
            manualSortBy: true
        },
        useSortBy
    );

    // Handle sort by server site
    useEffect(() => {
        if (sortBy.length > 0) {
            setSortField(`${sortBy[0].desc ? '-' : ''}${sortBy[0].id}`)
        }
    }, [sortBy])

    // Handle search
    useEffect(() => {
        if(searchInput!=undefined){
            const timeOutId = setTimeout(() => setFilter(searchInput), 500);
            return () => clearTimeout(timeOutId);
        }
    }, [searchInput])

    return (
        <div className="max-w-screen max-h-screen bg-theme-bg-primary text-white flex">
            <Sidebar></Sidebar>
            {/* main */}
            <div className="grow flex flex-col align-top max-w-main gap-4">
                <Navbar>
                    <div className="flex items-end">
                        <a className="text-4xl text-theme-primary">Hash Samples</a>
                    </div>
                </Navbar>
                <div className="px-4 pb-4 grow overflow-x-auto">
                    <div className="h-full flex flex-col">
                        <div className="flex items-center justify-between">
                            <h2 className="uppercase text-lg text-theme-primary">Analyzed Hash Samples</h2>
                            <div className="flex items-center gap-2">
                                <input className="input input-txt min-w-[18rem]" placeholder="Search" value={searchInput} onChange={event => setSearchInput(event.target.value)}></input>
                                <PrimaryBtn onClick={() => { addHashModal.current.open() }} className="flex items-center py gap-1 text-sm">
                                    <HiOutlinePlus className="w-5 h-5" />
                                    <span>Add Hash</span>
                                </PrimaryBtn>
                            </div>
                        </div>
                        <TableBg>
                            <Table {...getTableProps()}>
                                <Thread>
                                    {
                                        headerGroups.map((headerGroup) => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {
                                                    headerGroup.headers.map((column) => (
                                                        <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                            <div className="flex items-center  gap-2 ">
                                                                <span>{column.render("Header")}</span>
                                                                {
                                                                    column.isSorted ?
                                                                        column.isSortedDesc ?
                                                                            <HiChevronDown className="w-4 h-4" /> :
                                                                            <HiChevronUp className="w-4 h-4" /> :
                                                                        <></>
                                                                }
                                                            </div>
                                                        </Th>
                                                    ))
                                                }
                                            </tr>
                                        ))
                                    }
                                </Thread>
                                <Tbody {...getTableBodyProps()}>
                                    {rows.map((row, i) => {
                                        prepareRow(row);
                                        return (
                                            <Tr {...row.getRowProps()} onClick={() => navigator(`/hashes/${row.original.id}`)}>
                                                {row.cells.map((cell) => {
                                                    return (
                                                        <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                                                    );
                                                })}
                                            </Tr>
                                        );
                                    })}
                                </Tbody>
                            </Table>
                        </TableBg>
                        <div className="mt-auto flex items-center justify-between">
                            <div className="flex items-center justify-between gap-2">
                                <CustomListBox options={pageSize} defaultIndex={0} directionPopup="top" onChangeCallBack={value => setPagination(prevState => ({ ...prevState, limit: value.value }))}></CustomListBox>
                                <span className="whitespace-nowrap text-sm">items per page of {totalItem} total items</span>
                            </div>
                            <div className="">
                                <PaginationBtn disabled={previous ? false : true} onClick={() => setPagination(prevState => ({ ...prevState, offset: prevState.offset - prevState.limit }))}><HiChevronLeft className="w-5 h-5" /></PaginationBtn>
                                <PaginationBtn disabled={nextPage ? false : true} onClick={() => setPagination(prevState => ({ ...prevState, offset: prevState.offset + prevState.limit }))}><HiChevronRight className="w-5 h-5" /></PaginationBtn>
                            </div>
                        </div>
                        <MediumModal ref={addHashModal} modalTitle="Add hash">
                            <form onSubmit={onSubmitAddHash} className="w-full flex flex-col gap-8">
                                {/* body */}
                                <div className="w-full flex flex-col gap-5">
                                    <div className="flex flex-col gap-1">
                                        <label className="text-white">MD5 Hash <span className="text-red-500">*</span></label>
                                        <div className="flex flex-col gap-1 mx-2">
                                            <label className="text-gray-500 text-sm">MD5 hash value of suspicions object</label>
                                            <input type="text" name="md5" required placeholder="MD5 Hash Value" minLength={32} maxLength={32} className="input input-txt"></input>
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-1">
                                        <label className="text-white">SHA256 hash</label>
                                        <div className="flex flex-col gap-1 mx-2">
                                            <label className="text-gray-500 text-sm">SHA256 hash value of suspicions object</label>
                                            <input type="text" name="sha256" placeholder="SHA256 Hash Value" minLength={64} maxLength={64}  className="input input-txt"></input>
                                        </div>
                                    </div>
                                </div>
                                {/* footer */}
                                <div className="flex justify-end gap-4">
                                    <PrimaryBtn type="submit" className="text-sm">Add Hash</PrimaryBtn>
                                    <SecondaryBtn className="text-sm" onClick={() => addHashModal.current.close()}>Cancel</SecondaryBtn>
                                </div>
                            </form>
                        </MediumModal>
                        <SmallModal ref={infoModal} modalTitle={infoModalContent.title}>
                            <div className="grow flex flex-col items-center">
                                <span className="block text-center text-white">{infoModalContent.content}</span>
                                <PrimaryBtn className="mx-auto mt-auto min-w-[4rem] text-sm" onClick={() => { infoModal.current.close() }}>OK</PrimaryBtn>
                            </div>
                        </SmallModal>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default HashesPage