import { useState, useEffect, useContext } from "react";
import { AuthTokenContext } from "../../contexts/AuthTokenContext";
import { fetchFileSampleDetail, fetchFileSamplesList } from "../../apis/file-samples";

const defaultError = {
    error: false,
    statusCode: 0,
    message: ''
}

export const useListFileSamplesFetch = (offset=0, limit=20, sortFieldInit, filterInit) => {
    const {authToken, setAuthToken} = useContext(AuthTokenContext)
    const [pagination, setPagination] = useState({offset:offset, limit:limit})
    const [sortField, setSortField] = useState(sortFieldInit)
    const [filter, setFilter] = useState(filterInit)
    const [data, setData] = useState([])
    const [nextPage, setNextPage] = useState()
    const [previous, setPreviousPage] = useState()
    const [totalItem, setTotalItem] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(defaultError)
    const [refreshCall, setRefreshCall] = useState()

    useEffect( async ()=>{
        if(authToken['access']){
            let queryParam = {}
            if(pagination){
                queryParam.offset = pagination.offset
                queryParam.limit = pagination.limit
            }
            if(filter){
                queryParam.search = filter
            }
            if(sortField){
                queryParam.ordering = sortField
            }
    
            // call api to fetch data

            try{
                setIsLoading(true)
                const resp = await fetchFileSamplesList(authToken.access, queryParam)
                
                const respjson = await resp.json()
                // if status is 200 set data, total items, next, and previous
                if(resp.status===200){
                    setIsError(defaultError)
                    setTotalItem(respjson.count)
                    setPreviousPage(respjson.previous)
                    setNextPage(respjson.next)
                    setData(respjson.results)
                }
                else{
                    setIsError({
                        error: true,
                        statusCode: resp.status,
                        message: respjson.detail
                    })
                }
                setIsLoading(false)
            }
            catch(err){
                setIsLoading(false)
                setIsError({
                    error: true,
                    statusCode: 0,
                    message: err
                })
            }
        }
    },[pagination, sortField, filter, refreshCall])
    return [
        pagination,
        setPagination,
        sortField,
        setSortField,
        filter,
        setFilter,
        data,
        nextPage,
        previous,
        totalItem,
        isLoading,
        isError,
        setRefreshCall,
    ]
}

export const useFileSamplesDetailFetch = (sampleID) => {
    const {authToken, setAuthToken} = useContext(AuthTokenContext)
    const [data, setData] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(defaultError)
    const [refreshCall, setRefreshCall] = useState()

    useEffect( async ()=>{
        if(authToken['access']){

            // call api to fetch data

            try{
                setIsLoading(true)
                setIsError(defaultError)
                const resp = await fetchFileSampleDetail(authToken.access, sampleID)
                
                const respjson = await resp.json()
                // if status is 200 set data, total items, next, and previous
                if(resp.status===200){
                    setData(respjson)
                }
                else{
                    setIsError({
                        error: true,
                        statusCode: resp.status,
                        message: respjson.detail
                    })
                }
            }
            catch(err){
                setIsLoading(false)
                setIsError({
                    error: true,
                    statusCode: 0,
                    message: err
                })
            }
            setIsLoading(false)
        }
    },[refreshCall])
    return [
        data,
        isLoading,
        isError,
        setRefreshCall,
    ]
}