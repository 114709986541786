import { URL_BASE_API } from ".."

// Get list of artifact
export const fetchArtifactList = async (accessToken, queryParam) => {
    var url = `${URL_BASE_API}/api/artifacts/list/?`
    url = url + Object.keys(queryParam).reduce((result, key) => {
        return [...result, `${encodeURIComponent(key)}=${encodeURIComponent(queryParam[key])}`]
    }, []).join('&')
    const rawResponse = await fetch(url, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken,
        },
    });
    return rawResponse;
}

//  Get artifact detail
export const fetchArtifactDetail = async (accessToken, artifactID) => {
    var url = `${URL_BASE_API}/api/artifacts/${artifactID}/`
    const rawResponse = await fetch(url, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken,
        },
    });
    return rawResponse;
}

// Create a artifact
export const fetchArtifactCreate = async (accessToken, jsondata) => {
    const url = `${URL_BASE_API}/api/artifacts/create/`
    const rawResponse = await fetch(url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken,
        },
        body: JSON.stringify(jsondata)
    });
    return rawResponse;
}

// Add existing artifact to hash sample
export const fetchAddArtifactToHash = async (accessToken, hashID, artifactID) => {
    const jsonData = { artifactID: artifactID }

    const url = `${URL_BASE_API}/api/samples/hashes/add-artifact/${hashID}/`
    const rawResponse = await fetch(url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken,
        },
        body: JSON.stringify(jsonData)
    });
    return rawResponse;
}