import React from "react";

import { BadgeGreen, BadgeYellow, BadgeRed } from "../../styled_components/badges";
import DisclosureComponent from "../../styled_components/disclosure";
import { isoToDateFormat } from "../../ultils/datetime_format";
import { useLookupTIFetch } from "../../hooks/api/ThreatIntelFetch";

const AlertDetail = ({ alert }) => {
    const [
        victimData,
        victimIsLoading,
        victimIsError,
        victimRefreshCall,] = useLookupTIFetch({ query: alert.sourceIP, type: 'IpAddr' })
    const [
        phishingData,
        phishingIsLoading,
        phishingIsError,
        phishingRefreshCall,] = useLookupTIFetch({ query: /(?:http|https):\/\/(.+?)(?:\/|$)/.exec(alert.alertUrl)[1], type: 'Domain' })
    return (
        <div className="grow overflow-hidden flex flex-col">
            <div className="text-white w-full">

                <tr className="w-full">
                    <td className="td py-1">Profile name</td>
                    <td className="td py-1 text-gray-400 w-full"><BadgeYellow>{alert.profile_name}</BadgeYellow></td>
                </tr>
                <tr>
                    <td className="td py-1">Phishing URL</td>
                    <td className="td py-1 text-theme-primary underline w-full"><a href={alert.alertUrl}>{alert.alertUrl}</a></td>
                </tr>
                <tr>
                    <td className="td py-1">Alert Time</td>
                    <td className="td py-1 text-gray-400 w-full">{isoToDateFormat(alert.createAt)}</td>
                </tr>
                <tr>
                    <td className="td py-1">Victim IP Address</td>
                    <td className="td py-1 text-gray-400 w-full"><BadgeGreen>{alert.sourceIP}</BadgeGreen></td>
                </tr>
                <tr>
                    <td className="td py-1">User Agent</td>
                    <td className="px-6 max-w-[25rem] py-1 text-gray-400 break-words w-full"><p>{alert.userAgent}</p></td>
                </tr>
            </div>
            <DisclosureComponent title="Phishing Enriched Information">
                {
                    phishingData.data ?
                        <table className="w-full">
                            <tr >
                                <td className="td py-1">IP Address</td>
                                <td className="td py-1 text-gray-400"><BadgeRed>{phishingData.data.ip}</BadgeRed></td>
                            </tr>
                            <tr >
                                <td className="td py-1">Zip Code</td>
                                <td className="td py-1 text-gray-400">{phishingData.data.zip}</td>
                            </tr>
                            <tr >
                                <td className="td py-1">Continent</td>
                                <td className="td py-1 text-gray-400">{phishingData.data.continent_name}</td>
                            </tr>
                            <tr >
                                <td className="td py-1">Country</td>
                                <td className="td py-1 text-gray-400">{phishingData.data.country_flag_emoji} {phishingData.data.country_name}</td>
                            </tr>
                            <tr >
                                <td className="td py-1">Region</td>
                                <td className="td py-1 text-gray-400">{phishingData.data.region_name}</td>
                            </tr>
                            <tr >
                                <td className="td py-1">City</td>
                                <td className="td py-1 text-gray-400">{phishingData.data.city}</td>
                            </tr>
                            <tr >
                                <td className="td py-1">Geographic Coordinates</td>
                                <td className="td py-1 text-gray-400">{phishingData.data.latitude}, {phishingData.data.longitude}</td>
                            </tr>
                        </table>
                        : <></>
                }

            </DisclosureComponent>
            <DisclosureComponent title="Victim Enriched Information">
                {
                    victimData.data ?
                        <table className="w-full">
                            <tr >
                                <td className="td py-1">IP Address</td>
                                <td className="td py-1 text-gray-400"><BadgeYellow>{victimData.data.ip}</BadgeYellow></td>
                            </tr>
                            <tr >
                                <td className="td py-1">Zip Code</td>
                                <td className="td py-1 text-gray-400">{victimData.data.zip}</td>
                            </tr>
                            <tr >
                                <td className="td py-1">Continent</td>
                                <td className="td py-1 text-gray-400">{victimData.data.continent_name}</td>
                            </tr>
                            <tr >
                                <td className="td py-1">Country</td>
                                <td className="td py-1 text-gray-400">{victimData.data.country_flag_emoji} {victimData.data.country_name}</td>
                            </tr>
                            <tr >
                                <td className="td py-1">Region</td>
                                <td className="td py-1 text-gray-400">{victimData.data.region_name}</td>
                            </tr>
                            <tr >
                                <td className="td py-1">City</td>
                                <td className="td py-1 text-gray-400">{victimData.data.city}</td>
                            </tr>
                            <tr >
                                <td className="td py-1">Geographic Coordinates</td>
                                <td className="td py-1 text-gray-400">{victimData.data.latitude}, {victimData.data.longitude}</td>
                            </tr>
                        </table> :
                        <></>
                }

            </DisclosureComponent>
        </div>
    )
}

export default AlertDetail