import React, {useContext, useRef}  from "react";
import Dropdown from "../styled_components/dropdown";
import { CircleBtn, SecondaryBtn, PrimaryBtn, } from "../styled_components/buttons";
import { HiOutlineUser, HiOutlineLogout, } from "react-icons/hi"
import { UserInfoContext } from "../contexts/UserInfoContext";
import { AuthTokenContext } from "../contexts/AuthTokenContext";
import { useNavigate } from 'react-router-dom';
import { SmallModal } from "../styled_components/modals";

const Navbar = ({children})=>{
    const logoutModal = useRef()
    const navigator = useNavigate()
    const {userInfo, setUserInfo} = useContext(UserInfoContext)
    const {authToken, setAuthToken} = useContext(AuthTokenContext)

    const logoutHandler = () => {
        localStorage.removeItem('authToken');
        localStorage.removeItem('userInfo');
        setUserInfo()
        setAuthToken()
        navigator('/login')
    }

    const  renderUsername = (userInfo) => {
        if(userInfo){
            if(userInfo.first_name && userInfo.last_name){
                return `${userInfo.first_name } ${userInfo.last_name}`
            }
            return userInfo.username
        }
        return ''
    }
    const userDropMenu =  [
        [
            {
                'icon':  <HiOutlineUser className="w-5 h-5 mr-3 text-theme-primary"/>,
                'text': "User Profile",
                'href': "/#/profile",
                'onClick':() => {navigator('/settings/profile')}
            },
            
        ],
        [
            {
                'icon':  <HiOutlineLogout className="w-5 h-5 mr-3 text-theme-primary"/>,
                'text': "Logout",
                'href': "/#/profile",
                'onClick':() => {logoutModal.current.open()}
            }
        ]
    ]
    return (
        <div className="w-full flex justify-between items-center bg-theme-bg-primary p-4">
                {/* <h1 className="text-4xl text-theme-primary" >{pageTitle}</h1> */}
                {children}
                <div className="flex items-center gap-x-4">
                    <span className="uppercase font-semibold">{renderUsername(userInfo)}</span>
                    <Dropdown items={userDropMenu} >
                        <CircleBtn>
                            <HiOutlineUser className="h-5 w-5 stroke-white"/>
                        </CircleBtn>
                    </Dropdown>
                </div>
                <SmallModal ref={logoutModal} modalTitle="Logout">
                <div className="grow flex flex-col items-center">
                    <span className="block text-center text-white">Are you sure to log out current session?</span>
                    <div className="mt-auto flex items-center gap-4 text-sm">
                        <PrimaryBtn onClick={logoutHandler}>Logout</PrimaryBtn>
                        <SecondaryBtn  onClick={() => { logoutModal.current.close() }}>Cancel</SecondaryBtn>
                    </div>
                </div>
            </SmallModal>
        </div>

    )
}
export default Navbar