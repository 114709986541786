import { URL_BASE_API } from "..";

// Login account
export const login = async (username, password) => {
    const rawResponse = await fetch(`${URL_BASE_API}/api/auth/token/`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username: username, password: password })
    });
    return rawResponse;
}

// Verify token
export const verifyToken = async (token) => {
    const rawResponse = await fetch(`${URL_BASE_API}/api/auth/verify/`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ token: token })
    });
    return rawResponse;
}

// Refresh Access Key
export const refreshAccessToken = async (refreshToken) => {
    const rawResponse = await fetch(`${URL_BASE_API}/api/auth/refresh/`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ refresh: refreshToken })
    });
    return rawResponse;
}
// Get user information
export const fetchUserInfo = async (accessToken) => {
    const rawResponse = await fetch(`${URL_BASE_API}/api/auth/profile/`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken,
        },
    });
    return rawResponse;
}

// Update user info
export const fetchUpdateProfile = async (accessToken, jsonData) => {
    const rawResponse = await fetch(`${URL_BASE_API}/api/auth/profile/update/`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken,
        },
        body: JSON.stringify(jsonData)
    });
    return rawResponse;
}

// Change password
export const fetchChangePassword = async (accessToken, jsonData) => {
    const rawResponse = await fetch(`${URL_BASE_API}/api/auth/change-password/`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken,
        },
        body: JSON.stringify(jsonData)
    });
    return rawResponse;
}