import { useState, useEffect, useContext, useMemo } from "react";
import moment from 'moment'
import { AuthTokenContext } from "../../contexts/AuthTokenContext";
import { fetchGeneralStatistics, fetchHashSampleTimeline, fetchFileSampleTimeline, fetchPhishingAlertTimeline } from "../../apis/statistics";
const defaultError = {
    error: false,
    statusCode: 0,
    message: ''
}

export const useGeneralStatisticFetch = () => {
    const {authToken, setAuthToken} = useContext(AuthTokenContext)
    const [data, setData] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(defaultError)
    const [refreshCall, setRefreshCall] = useState()
    useEffect( async ()=>{
        if(authToken['access']){ 
            // call api to fetch data
            try{
                setIsLoading(true)
                const resp = await fetchGeneralStatistics(authToken.access)
                const respjson = await resp.json()
                if(resp.status===200){
                    setIsError(defaultError)
                    setData(respjson)
                }
                else{
                    setIsError({
                        error: true,
                        statusCode: resp.status,
                        message: respjson.detail
                    })
                }
                setIsLoading(false)
            }
            catch(err){
                setIsLoading(false)
                setIsError({
                    error: true,
                    statusCode: 0,
                    message: err
                })
            }
        }
    },[refreshCall])
    return [
        data,
        isLoading,
        isError,
        setRefreshCall,
    ]
}

export const useHashSampleTimelineFetch = (days=30) => {
    const {authToken, setAuthToken} = useContext(AuthTokenContext)
    const [rawData, setRawData] = useState([])
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(defaultError)
    const [refreshCall, setRefreshCall] = useState()
    // Add emty days
    const insertEmptyItems = useMemo(()=>{
        const lastNumberOfDays = moment().utc().startOf('days').add(-1*days, 'd')
        const data = Array.from({length: days}, (_, index) =>{ 
            let itemDay = moment(lastNumberOfDays)
            itemDay.add(index+1,'d')
            const itemFiltered = rawData.filter(item => moment(item.create_timeline).isSame(itemDay))
            if (itemFiltered.length !==0){
                return {
                    "samples": itemFiltered[0].samples,
                    "create_timeline": itemDay
                }
            }
            else{
                return {
                    "samples": 0,
                    "create_timeline": itemDay
                }
            }
        })
        setData(data)
    }, [rawData])

    useEffect( async ()=>{
        if(authToken['access']){ 
            // call api to fetch data
            try{
                setIsLoading(true)
                const resp = await fetchHashSampleTimeline(authToken.access, days)
                const respjson = await resp.json()
                if(resp.status===200){
                    setIsError(defaultError)
                    setRawData(respjson)
                    insertEmptyItems()
                }
                else{
                    setIsError({
                        error: true,
                        statusCode: resp.status,
                        message: respjson.detail
                    })
                }
                setIsLoading(false)
            }
            catch(err){
                setIsLoading(false)
                setIsError({
                    error: true,
                    statusCode: 0,
                    message: err
                })
            }
        }
    },[refreshCall])
    return [
        rawData,
        data,
        isLoading,
        isError,
        setRefreshCall,
    ]
}

export const useFileSampleTimelineFetch = () => {
    const {authToken, setAuthToken} = useContext(AuthTokenContext)
    const [data, setData] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(defaultError)
    const [refreshCall, setRefreshCall] = useState()
    useEffect( async ()=>{
        if(authToken['access']){ 
            // call api to fetch data
            try{
                setIsLoading(true)
                const resp = await fetchFileSampleTimeline(authToken.access)
                const respjson = await resp.json()
                if(resp.status===200){
                    setIsError(defaultError)

                    setData(respjson)
                }
                else{
                    setIsError({
                        error: true,
                        statusCode: resp.status,
                        message: respjson.detail
                    })
                }
                setIsLoading(false)
            }
            catch(err){
                setIsLoading(false)
                setIsError({
                    error: true,
                    statusCode: 0,
                    message: err
                })
            }
        }
    },[refreshCall])
    return [
        data,
        isLoading,
        isError,
        setRefreshCall,
    ]
}

export const useStatisticTimelineFetch = (days=30) => {
    const {authToken, setAuthToken} = useContext(AuthTokenContext)
    const [timeline, setTimeline] = useState(days)
    const [rawData, setRawData] = useState({'hashes':[], 'phishingAlert':[]})
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(defaultError)
    const [refreshCall, setRefreshCall] = useState()
    // Add emty days
    const insertEmptyItems = useMemo(()=>{
        const startDay = moment().utc().startOf('days').subtract(timeline, 'd')
        const data = Array.from({length: timeline}, (_, index) =>{ 
            let itemDay = moment(startDay).add(index+1,'d')
            let item = {}
            const hashItemFiltered = rawData.hashes.filter(item => moment(item.create_timeline).isSame(itemDay))
            if (hashItemFiltered.length !==0){
                item = {
                    "samples": hashItemFiltered[0].objects,
                    "create_timeline": itemDay
                }
            }
            else{
                item =  {
                    "samples": 0,
                    "create_timeline": itemDay
                }
            }
            const alertItemFiltered = rawData.phishingAlert.filter(item => moment(item.create_timeline).isSame(itemDay))
            if (alertItemFiltered.length !==0){
                item.alerts = alertItemFiltered[0].objects
            }
            else{
                item.alerts = 0
            }
            return item
        })
        setData(data)
    }, [rawData, timeline])

    useEffect( async ()=>{
        console.log("useStatisticTimelineFetch called")
        if(authToken['access']){ 
            // call api to fetch data
            try{
                setIsLoading(true)
                const respHashes = await fetchHashSampleTimeline(authToken.access, timeline)
                const respAlerts = await fetchPhishingAlertTimeline(authToken.access, timeline)
                const jsonHashes = await respHashes.json()
                const jsonAlerts = await respAlerts.json()
                if(respHashes.status===200 && respAlerts.status===200){
                    setIsError(defaultError)
                    // insertEmptyItems()
                    setRawData({'hashes': jsonHashes, 'phishingAlert': jsonAlerts})
                    insertEmptyItems()
                    // const startDay = moment().utc().startOf('days').subtract(days, 'd')
                    // const data = Array.from({length: days}, (_, index) =>{
                    //     let itemDay = moment(lastNumberOfDays).add(index+1,'d')

                    // })
                }
                else{
                    if(respHashes.status!==200){
                        setIsError({
                            error: true,
                            statusCode: respHashes.status,
                            message: jsonHashes.detail
                        })
                    }
                    else{
                        setIsError({
                            error: true,
                            statusCode: respAlerts.status,
                            message: respAlerts.detail
                        })
                    }
                }
                setIsLoading(false)
            }
            catch(err){
                setIsLoading(false)
                setIsError({
                    error: true,
                    statusCode: 0,
                    message: err
                })
            }
        }
    },[refreshCall, timeline])
    return [
        rawData,
        data,
        isLoading,
        isError,
        setRefreshCall,
        setTimeline
    ]
}