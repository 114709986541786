import { URL_BASE_API } from '..'

// List phishing profile
export const fetchPhishingAlertList = async (accessToken, queryParam) => {
    var url = `${URL_BASE_API}/api/phishing/alert/list/?`
    url = url + Object.keys(queryParam).reduce((result, key) => {
        return [...result, `${encodeURIComponent(key)}=${encodeURIComponent(queryParam[key])}`]
    }, []).join('&')
    const rawResponse = await fetch(url, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken,
        },
    });
    return rawResponse;
}