import React, {useEffect} from "react";
import { Badge, BadgeGreen, BadgeRed, BadgeGray } from "../../styled_components/badges";
import ReactApexChart from "react-apexcharts";
import { TableBg, Table, Thread, Th, Tbody, Tr, Td } from "../../styled_components/tables";
import { Tab } from "@headlessui/react";
import ReactJson from 'react-json-view'
import { ReactComponent as NotFoundIcon } from '../../media/images/icons/not-found.svg'
import { useCloudResultDetailFetch } from "../../hooks/api/CloudResultsFetch";
import { isoToDateFormat } from "../../ultils/datetime_format";
const HybridAnalysisReport = ({resultID}) =>{
    const [
        dataResult,
        isLoading,
        isError,
        refreshCall,] = useCloudResultDetailFetch(resultID)

    const calcCorlor = (percent) => {
        // Green
        if (percent < 33) {
            return '#22c55e'
        }
        // Orage
        if (percent < 66) {
            return '#f97316'
        }
        //  Red
        return '#ef4444'
    }
    const chart = (score) => ({
        series: [score],
        options: {
            chart: {
                height: 350,
                type: 'radialBar',
            },
            plotOptions: {
                radialBar: {
                    hollow: {
                        size: '60%',
                    },
                    track: {
                        background: '#0a101d',
                        strokeWidth: '100%',
                        margin: 0, // margin is in pixels
                    },
                    dataLabels: {
                        show: true,
                        name: {
                            offsetY: 30,
                            show: true,
                            color: '#fff',
                            fontSize: '18px',
                            fontWeight: 100,
                        },
                        value: {
                            formatter: function (val) {
                                return parseInt(val);
                            },
                            offsetY: -15,
                            color: calcCorlor(),
                            fontSize: '36px',
                            show: true,
                        }
                    }
                }
            },

            stroke: {
                lineCap: 'round'
            },
            labels: ['/100'],
            colors: [calcCorlor(score),]
        },
    });
    const renderMitreAtk =  (mitreAtk ) =>{
        if(mitreAtk.length===0){
            return (
                <Tr>
                    <Td colSpan={4}>
                        <div className="w-full h-full flex items-center justify-center gap-4">
                            <NotFoundIcon className="text-gray-400 w-20 h-20"/>
                            <div>
                                <p className="text-gray-400 font-semibold text-2xl">No Data</p>
                                <p className="text-gray-400">This Mitre Attack of the sample is empty</p>
                            </div>
                        </div>
                    </Td>
                </Tr>
            )
        }
        return mitreAtk.map((atk, indx)=>(
                    <Tr key={indx}>
                        <Td><a href={atk.attck_id_wiki}>{atk.attck_id}</a></Td>
                        <Td>{atk.tactic}</Td>
                        <Td>{atk.technique}</Td>
                        <Td>{atk.malicious_identifiers_count} / {atk.suspicious_identifiers_count}</Td>
                    </Tr>
        ))
    }

    return(
        <div className="grow overflow-hidden flex flex-col">
            {
                dataResult.data?
                    Object.keys(dataResult.data).length===0?
                    <div className="w-full h-full flex items-center justify-center gap-4">
                        <NotFoundIcon className="text-gray-400 w-20 h-20"/>
                        <div>
                            <p className="text-gray-400 font-semibold text-2xl">Not Found</p>
                            <p className="text-gray-400">This sample does not exist on Hybrid Analysis</p>
                        </div>
                    </div>
                    :
                    <>
                        <div className="w-full flex">
                            <ReactApexChart 
                                options={chart(dataResult.data.av_detect).options} 
                                series={chart(dataResult.data.av_detect).series} 
                                type="radialBar" height={200} width={200} />
                            <div className="text-white grow">

                                <tr className>
                                    <td className="td py-1">Threat Score</td>
                                    <td className="td py-1 text-gray-400">{dataResult.data.threat_score? dataResult.data.threat_score:"-"}</td>
                                </tr>
                                <tr>
                                    <td className="td py-1">File type</td>
                                    <td className="td py-1 text-gray-400 flex gap-1 flex-wrap">
                                        {
                                            dataResult.data.type_short.map((type, index) => <BadgeGreen key={index}>{type}</BadgeGreen> )
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td className="td py-1">Create Time</td>
                                    <td className="td py-1 text-gray-400">{isoToDateFormat(dataResult.data.analysis_start_time)}</td>
                                </tr>
                                <tr>
                                    <td className="td py-1">Malware Family</td>
                                    <td className="td py-1 text-gray-400">{dataResult.data.vx_family}</td>
                                </tr>
                                <tr>
                                    <td className="td py-1">Verdict</td>
                                    <td className="td py-1 text-gray-400">{dataResult.data.verdict==="malicious"?<BadgeRed>{dataResult.data.verdict}</BadgeRed>:<BadgeGray>{dataResult.data.verdict}</BadgeGray>}</td>
                                </tr>
                                <tr>
                                    <td className="td py-1">Tags</td>
                                    <td className="td py-1 text-gray-400 flex gap-1 flex-wrap">
                                        {
                                            dataResult.data.tags.map((tag, index) => (<BadgeGray key={index}>{tag}</BadgeGray>))
                                        }
                                    </td>
                                </tr>
                            </div>
                        </div>
                        <Tab.Group as="div" className="mt-4 grow flex flex-col gap-2 overflow-hidden">
                            <Tab.List as="div" className="flex justify-center items-center">
                                <Tab className={({ selected }) => selected ? 'bg-theme-primary text-theme-bg-primary tab-btn' : 'bg-theme-bg-secondary text-white tab-btn'}>MITRE ATK</Tab>
                                <Tab className={({ selected }) => selected ? 'bg-theme-primary text-theme-bg-primary tab-btn' : 'bg-theme-bg-secondary text-white tab-btn'}>Raw</Tab>
                            </Tab.List>
                            <Tab.Panels className="flex flex-col overflow-hidden">
                                <Tab.Panel className="flex flex-col overflow-hidden">
                                    <TableBg className="grow overflow-auto">
                                        <Table>
                                            <Thread>
                                                <Tr>
                                                    <Th>AtkID</Th>
                                                    <Th>Tactic</Th>
                                                    <Th>Technique</Th>
                                                    <Th>Mal / Sups</Th>
                                                </Tr>
                                            </Thread>
                                            <Tbody className="overflow-y-auto max-w-main">
                                                    {renderMitreAtk(dataResult.data.mitre_attcks)} 
                                            </Tbody>
                                        </Table>
                                    </TableBg>
                                </Tab.Panel>
                                <Tab.Panel className="flex flex-col overflow-auto">
                                    <div className="overflow-auto bg-gray-900 rounded-lg p-2">
                                        <ReactJson style={{ backgroundColor: "transparent" }}
                                            iconStyle="square"
                                            theme="railscasts"
                                            displayDataTypes={false}
                                            src={dataResult.data}
                                            collapseStringsAfterLength={32}
                                            collapsed={1}
                                            displayObjectSize={false} />
                                    </div>
                                </Tab.Panel>
                            </Tab.Panels>
                        </Tab.Group>
                    </>
                    :<div className="w-full h-full flex items-center justify-center gap-4">
                    <NotFoundIcon className="text-gray-400 w-20 h-20"/>
                    <div>
                        <p className="text-gray-400 font-semibold text-2xl">Not Found</p>
                        <p className="text-gray-400">This sample does not exist on Hybrid Analysis</p>
                    </div>
                </div>
            }
        </div>
    )
}

export  default HybridAnalysisReport;