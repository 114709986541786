import React, { Fragment, useState } from "react";
import { Menu, Transition } from '@headlessui/react'
import {
    useFloating,
    offset,
    flip,
    shift,
} from '@floating-ui/react-dom';
import { Portal } from "../portal";


const Dropdown = ({ items, children, placement='bottom-start' }) => {
    const { x, y, reference, floating, strategy } = useFloating(
        {
            strategy: 'fixed',
            placement: placement,
            middleware: [offset(2), flip(), shift()],
        });

    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button ref={reference} as="div" className="w-full flex flex-col items-center justify-center">
                    {children}
                </Menu.Button>
            </div>
            <Portal>
                {/* <Transition
                    as={Fragment}
                    enter="transition ease-in-out duration-75"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                > */}
                    {/*  right-0 origin-top-right*/}
                    <Menu.Items
                        style={{
                            position: strategy,
                            top: y ?? 0,
                            left: x ?? 0,
                        }}
                        className="mt-2 origin-top-right bg-theme-bg-secondary divide-y divide-theme-bg-700 rounded-lg shadow-theme focus:outline-none z-50"
                        ref={floating}>
                        {
                            items.map((groupMenu, index) => (
                                <ul key={index} className="px-1 py-1 ">
                                    {
                                        groupMenu.map((item, index) => (
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <li key={index} onClick={item.onClick ? item.onClick : ''} className={`cursor-pointer flex items-center m-1 pl-2 pr-3 py-2 text-sm text-theme-txt-100 rounded-lg ${active ? 'bg-theme-bg-700' : ''}`} href={item.href}>
                                                        {item.icon}
                                                        {item.text}
                                                    </li>
                                                )}
                                            </Menu.Item>
                                        ))
                                    }
                                </ul>
                            ))
                        }
                    </Menu.Items>
                {/* </Transition> */}
            </Portal>
        </Menu>
    )
}

export default Dropdown
