import { Routes, Route, HashRouter, Navigate } from "react-router-dom";
import SamplePage from "./pages/samples";
import DashboardPage from "./pages/dashboard"
import LoginPage from "./pages/login/";
import TestPage from "./pages/test";
import HashesPage from "./pages/hashes";
import HashDetailPage from "./pages/hashes/detail";
import SampleDetailPage from "./pages/samples/detail";
import ProfilePage from "./pages/settings/profile";
import UserManagementPage from "./pages/admin/user-management";
import PhishingProfilesPage from "./pages/phishing/profiles";
import PhishingAlertsPage from "./pages/phishing/alerts";
import SystemSettings from "./pages/settings";
import ExternalSettings from "./pages/settings/external";
import { UserInfoContextProvider } from "./contexts/UserInfoContext";
import { AuthTokenContextProvider } from "./contexts/AuthTokenContext";
import ProtectedRoutes from "./ProtectedRoutes";


function App() {
  return (
    <HashRouter>
      <div className="App">
        <UserInfoContextProvider>
          <AuthTokenContextProvider>
            <Routes>
              <Route path="/login" exact element={<LoginPage />} />
              <Route element={<ProtectedRoutes/>}>
                <Route path="/" exact element={<Navigate to="/dashboard" />} />
                <Route path="/dashboard" exact element={<DashboardPage />} />
                <Route path="/test" exact element={<TestPage />} />
                <Route path="/hashes" exact element={<HashesPage />} />
                <Route path="/hashes/:id" exact  element={<HashDetailPage/>}/>
                <Route path="/samples" exact element={<SamplePage />} />
                <Route path="/samples/:id" exact  element={<SampleDetailPage/>}/>
                <Route path="/phishing/profiles" exact element={<PhishingProfilesPage/>}/>
                <Route path="/phishing/alerts" exact element={<PhishingAlertsPage/>}/>
                <Route path="/settings/profile" exact element={<ProfilePage/>}/>
                <Route path="/admin/user-management" exact element={<UserManagementPage/>}/>
                <Route path="/admin/settings/system" exact element={<SystemSettings/>}/>
                <Route path="/admin/settings/external" exact element={<ExternalSettings/>}/>
              </Route>
            </Routes>
          </AuthTokenContextProvider>
        </UserInfoContextProvider>
      </div>
    </HashRouter>
  )
}

export default App;