import React from "react"
import { Switch } from "@headlessui/react"

const ToggleSwitch = ({name, isDisable, isChecked, onChange}) => {
    return (
        <Switch
            name={name}
            disabled={isDisable}
            checked={isChecked}
            onChange={onChange}
            className={`${isChecked ? 'bg-green-500' : 'bg-gray-500'}
          relative inline-flex h-[24px] w-[54px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
        >
            <span
                aria-hidden="true"
                className={`${isChecked ? 'translate-x-[30px]' : 'translate-x-0'}
            pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
            />
        </Switch>
    )
}
export default ToggleSwitch
