import React, { useMemo, useRef, useState, useContext } from "react";
import { TableBg, Table, Thread, Tr, Th, Tbody, Td } from "../../styled_components/tables";
import { Badge, BadgeGreen, BadgeYellow, BadgeGray } from "../../styled_components/badges";
import { PrimaryBtn, SecondaryBtn } from "../../styled_components/buttons";
import { useTable, useSortBy } from "react-table";
import { useArtifactsFetch } from "../../hooks/api/ArtifactFetch";
import { useAsyncFetch } from "../../hooks/AsyncFetch";
import { isoToDateNow } from "../../ultils/datetime_format";
import { MediumModal, SmallModal } from "../../styled_components/modals";
import { CustomListBox } from "../../styled_components/listbox";
import { AuthTokenContext } from "../../contexts/AuthTokenContext";
import { fetchAddArtifactToHash, fetchArtifactCreate } from "../../apis/artifacts";
import {
    HiOutlinePlus,
    HiChevronDown,
    HiChevronUp,
    HiOutlinePaperClip,

} from "react-icons/hi";

const artifactTypes = [
    {
        value: 'IpAddressV4',
        title: 'IP Address V4'
    },
    {
        value: 'IpAddressV6',
        title: 'IP Address V6'
    },
    {
        value: 'Url',
        title: 'URL'
    },
    {
        value: 'Email',
        title: 'Email'
    },
    {
        value: 'Text',
        title: 'Text'
    },
]

const ArtifactsComponent = ({ hashID }) => {
    const {authToken, setAuthToken} = useContext(AuthTokenContext)
    const addArtifactModal = useRef()
    const infoModal = useRef()
    const [infoModalContent, setInfoModalContent] = useState({
        title: '',
        content:''
    })
    const [artifactTypeCreate, setArtifactTypeCreate] = useState('IpAddressV4');
    const [
        isLoadingCreateArtifact,
        setIsLoadingCreateArtifact,
        isErrorCreateArtifact, 
        setIsErrorCreateArtifact
    ] = useAsyncFetch()
    const [
        pagination,
        setPagination,
        sortField,
        setSortField,
        filter,
        setFilter,
        data,
        nextPage,
        previous,
        totalItem,
        isLoading,
        isError,
        setRefreshCall,
    ] = useArtifactsFetch(0, 500, hashID)
    const columns = useMemo(() => [
        {
            Header: "artifact",
            accessor: "artifact"
        },
        {
            Header: "artifact type",
            accessor: "artifactType",
            Cell: ({ value }) => {
                if (value === "Url") {
                    return <BadgeGreen>{value}</BadgeGreen>
                }
                if (value === "Email") {
                    return <BadgeYellow>{value}</BadgeYellow>
                }
                if (value === "Text") {
                    return <BadgeGray>{value}</BadgeGray>
                }
                return <Badge>{value}</Badge>
            }
        },
        {
            Header: "owner",
            accessor: "owner"
        },
        {
            Header: "create time",
            accessor: "createAt",
            Cell: ({ value }) => {
                return isoToDateNow(value)
            }
        },
    ],
        [])
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        // We need to get 'sortBy' from the table state, then use it as an argument to fetch new data
        state: { sortBy }
    } = useTable(
        {
            columns,
            data,
            // we need to tell react-table that we will handle sorting manually
            manualSortBy: true
        },
        useSortBy
    );
    
    const submitAddArtifact = async (e) =>{
        e.preventDefault()
        let submitData = {}
        const data = new FormData(e.target);
        submitData['artifactType'] = artifactTypeCreate
        data.forEach((value, key) => submitData[key] = value);
        if(authToken){
            // Call api to create artifact
            setIsLoadingCreateArtifact(true)
            setIsErrorCreateArtifact({
                error: false,
                statusCode: 0,
                message: ''
            })
            try{
                const respCreateArtifact = await fetchArtifactCreate(authToken.access, submitData)

                const respjson = await respCreateArtifact.json()
                if(respCreateArtifact.status===200){
                    const artifactID = respjson.id

                    // Call api to add artifact to hash sample
                    const respAddToHash = await fetchAddArtifactToHash(authToken.access, hashID, artifactID)
                    const respAddToHashJson = respAddToHash.json()
                    if(respAddToHash.status===200){
                        setInfoModalContent({
                            title:"Information",
                            content:"Artifact created successfully"
                        })
                        // Close create modal
                        addArtifactModal.current.close()
                        // call refresh fetch artifact list
                        setRefreshCall(Date())
                    }
                    else{
                        setIsErrorCreateArtifact({
                            error: true,
                            statusCode: respAddToHash.status,
                            message: respAddToHashJson.detail
                        })
                        setInfoModalContent({
                            title:"Error",
                            content: respAddToHashJson.detail
                        })
                    }
                }
                else{
                    setIsErrorCreateArtifact({
                        error: true,
                        statusCode: respCreateArtifact.status,
                        message: respjson.detail
                    })
                    setInfoModalContent({
                        title:"Error",
                        content: respjson.detail
                    })
                }

            }
            catch(err){
                setIsErrorCreateArtifact({
                    error: true,
                    statusCode: 0,
                    message: err.message
                })
                setInfoModalContent({
                    title:"Error",
                    content: err.message
                })
            }
            setIsLoadingCreateArtifact(false)
            infoModal.current.open()
        }
    }


    return (
        <>
            <div className="w-full flex items-end">
                
                <PrimaryBtn className="ml-auto text-sm flex items-center justify-center gap-1" onClick={()=>addArtifactModal.current.open()}>
                    <HiOutlinePlus className="w-4 h-4"/>
                    <span>Add Artifact</span>
                </PrimaryBtn>
            </div>
            <TableBg>
                <Table {...getTableProps()}>
                    <Thread>
                        {
                            headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {
                                        headerGroup.headers.map((column) => (
                                            <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                <div className="flex items-center  gap-2 ">
                                                    <span>{column.render("Header")}</span>
                                                    {
                                                        column.isSorted ?
                                                            column.isSortedDesc ?
                                                                <HiChevronDown className="w-4 h-4" /> :
                                                                <HiChevronUp className="w-4 h-4" /> :
                                                            <></>
                                                    }
                                                </div>
                                            </Th>
                                        ))
                                    }
                                </tr>
                            ))
                        }
                    </Thread>
                    <Tbody {...getTableBodyProps()}>
                        {
                            rows.length===0?
                            <Tr>
                                <Td colSpan={4}>
                                    <div className="flex items-center justify-center gap-4">
                                        <HiOutlinePaperClip className="w-12 h-12"/>
                                        <div>
                                            <p className="font-semibold text-2xl">No existed artifact</p>
                                            <p>You can add new artifact to this sample</p>
                                        </div>
                                    </div>
                                </Td>
                            </Tr>:<></>
                        }
                        {rows.map((row, i) => {
                            prepareRow(row);
                            return (
                                <Tr {...row.getRowProps()} onClick={() => console.log(row.original)}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                                        );
                                    })}
                                </Tr>
                            );
                        })}
                    </Tbody>
                </Table>
            </TableBg>
            <MediumModal modalTitle="Add Artifact" ref={addArtifactModal}>
            <form onSubmit={submitAddArtifact} className="w-full flex flex-col gap-8">
                    {/* body */}
                    <div className="w-full flex flex-col gap-5">
                        <div className="flex flex-col gap-1">
                            <label className="text-white">Artifact Type</label>
                            <label className="text-gray-500 text-sm">To cagegorize artifact (eg. IP Addresses, URLs, Email, Text)</label>
                            {/* <label className="text-gray-500 text-xs">Suspicions file need to Analyzed</label> */}
                            <CustomListBox options={artifactTypes} defaultIndex={0} directionPopup="bottom" onChangeCallBack={value => setArtifactTypeCreate(value.value)}/>
                        </div>
                        <div className="flex flex-col gap-1">
                            <label className="text-white">Artifact</label>
                            <label className="text-gray-500 text-sm">Artifact value need to be added</label>
                            {/* <label className="text-gray-500 text-xs">Suspicions file need to Analyzed</label> */}
                            <input name="artifact" required  className="input input-txt"/>
                        </div>
                        <div className="flex flex-col gap-1">
                            <label className="text-white">Description</label>
                            <label className="text-gray-500 text-sm">Description of artifact</label>
                            {/* <label className="text-gray-500 text-xs">Suspicions file need to Analyzed</label> */}
                            <textarea name="description" required  className="input input-txt"/>
                        </div>

                    </div>
                    {/* footer */}
                    <div className="flex justify-end gap-4">
                        <PrimaryBtn type="submit" className="text-sm">Add Artifact</PrimaryBtn>
                        <SecondaryBtn onClick={() => addArtifactModal.current.close()} className="text-sm">Cancel</SecondaryBtn>
                    </div>
                </form>
            </MediumModal>
            <SmallModal ref={infoModal} modalTitle={infoModalContent.title}>
                <div className="grow flex flex-col items-center">
                    <span className="block text-center text-white">{infoModalContent.content}</span>
                    <PrimaryBtn className="mx-auto mt-auto min-w-[4rem] text-sm" onClick={() => { infoModal.current.close() }}>OK</PrimaryBtn>
                </div>
            </SmallModal>
        </>
    )
}

export default ArtifactsComponent