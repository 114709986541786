import React, { useState, useEffect, useContext, useRef } from "react";

// Components
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";

// Styled components
import { PrimaryBtn, SecondaryBtn } from "../../styled_components/buttons";
import {
    HiOutlinePencil,
} from "react-icons/hi";
import { SmallModal } from "../../styled_components/modals";
import ToggleSwitch from "../../styled_components/toggle";

// Context
import { AuthTokenContext } from "../../contexts/AuthTokenContext";
import { UserInfoContext } from "../../contexts/UserInfoContext";

// Hooks
import { useAsyncFetch, defaultError } from "../../hooks/AsyncFetch";

// API
import { fetchSettings } from "../../apis/settings";

const SystemSettings = () => {
    const { authToken, setAuthToken } = useContext(AuthTokenContext)
    // Global settings
    const [settings, setSettings] = useState(null)

    // Edit proxy state 
    const [isEditProxy, setIsEditProxy] = useState(false)

    const [isSSL, setIsSSL] = useState(true)

    // Info modal
    const infoModal = useRef()
    const [infoModalContent, setInfoModalContent] = useState({
        title: "",
        content: "",
    })
    const [
        isLoadingSettings,
        setIsLoadingSettings,
        isErrorSettings,
        setIsErrorSettings
    ] = useAsyncFetch()

    useEffect(async () => {
        setIsLoadingSettings(true)
        setIsErrorSettings(defaultError)
        try {
            const resp = await fetchSettings(authToken.access, {})
            const respjson = await resp.json()
            if (resp.status === 200) {
                setSettings(respjson)
            }
            else {
                setIsErrorSettings({
                    error: true,
                    statusCode: resp.status,
                    message: respjson.detail
                })
            }
        }
        catch (err) {
            setIsErrorSettings({
                error: true,
                statusCode: 0,
                message: err
            })
        }
        setIsLoadingSettings(false)
    },
        []
    )

    const onUpdateProxySettings = () => {
        console.log('update proxy settings')
    }


    return (
        <div className="max-w-screen min-h-screen bg-theme-bg-primary text-white flex">
            <Sidebar></Sidebar>
            {/* main */}
            <div className="grow flex flex-col align-top max-w-main gap-4">
                <Navbar>
                    <div className="flex items-end">
                        <a className="text-4xl text-theme-primary">System Settings</a>
                    </div>
                </Navbar>
                <div className="p-4 overflow-auto">
                    <form name="updateProxy" onSubmit={onUpdateProxySettings} className=" bg-theme-bg-secondary shadow-theme rounded-lg p-4 lg:mx-40 2xl:mx-56 flex flex-col gap-2">
                        <div className="flex flex-row justify-between">
                            <h2 className="text-theme-primary uppercase text-lg">Proxy Configuration</h2>
                            <PrimaryBtn className="text-sm flex flex-row gap-1 justify-center items-center" type="button" onClick={(e) => setIsEditProxy(prevState => !prevState)}>
                                <span>Edit</span>
                                <HiOutlinePencil className="w-4 h-4" />
                            </PrimaryBtn>
                        </div>
                        <div className="grid grid-cols-2 gap-2 w-full">
                            <div >
                                <span className="block">Enable:</span>
                                <span className="text-gray-400 text-sm">Enable Proxy</span>
                            </div>
                            <div>
                                <ToggleSwitch onChange={(e)=>console.log(123)} isDisabled={!isEditProxy} name="PROXY_ENABLED" isChecked={settings? settings.PROXY_ENABLED.value: false} />
                            </div>
                            <div>
                                <span className="block">SSL:</span>
                                <span className="text-gray-400 text-sm">Proxy is using HTTP or HTTPS</span>
                            </div>
                            <div>
                                <ToggleSwitch onChange={(e)=>console.log(123)} isDisabled={!isEditProxy} name="PROXY_SSL" isChecked={settings? settings.PROXY_SSL.value: false} />
                            </div>
                            <div>
                                <span className="block">Hostname:</span>
                                <span className="block text-gray-400 text-sm">Hostname of proxy (e.g localhost)</span>
                                <input className="input input-txt w-full mt-1" readOnly={!isEditProxy} value={settings ? settings.PROXY_HOSTNAME.value : ''}></input>
                            </div>
                            <div>
                                <span className="block">Port:</span>
                                <span className="block text-gray-400 text-sm">Listening port of proxy (e.g 8080)</span>
                                <input className="input input-txt w-full mt-1" readOnly={!isEditProxy} value={settings ? settings.PROXY_PORT.value : ''}></input>
                            </div>
                        </div>
                       
                        <div className={`mt-2 flex flex-row justify-end gap-4 ${!isEditProxy ? "hidden" : ""}`}>
                            <PrimaryBtn className="text-sm" type="submit">Save</PrimaryBtn>
                            <SecondaryBtn className="text-sm" type="button" onClick={() => setIsEditProxy(false)}>Cancel</SecondaryBtn>
                        </div>
                    </form>

                    <form name="updateQradar" onSubmit={onUpdateProxySettings} className="mt-4 bg-theme-bg-secondary shadow-theme rounded-lg p-4 lg:mx-40 2xl:mx-56 flex flex-col gap-2">
                        <div className="flex flex-row justify-between">
                            <h2 className="text-theme-primary uppercase text-lg">QRadar Integration</h2>
                            <PrimaryBtn className="text-sm flex flex-row gap-1 justify-center items-center" type="button" onClick={(e) => setIsEditProxy(prevState => !prevState)}>
                                <span>Edit</span>
                                <HiOutlinePencil className="w-4 h-4" />
                            </PrimaryBtn>
                        </div>
                        <div className="grid grid-cols-2 gap-2 w-full">
                            <div >
                                <span className="block">Enable Qradar:</span>
                                <span className="text-gray-400 text-sm">Enable to send alerts and signatures to Qradar</span>
                            </div>
                            <div>
                                <ToggleSwitch onChange={(e)=>console.log(123)} isDisabled={!isEditProxy} name="QRADAR_ENABLED" isChecked={settings? settings.QRADAR_ENABLED.value: false} />
                            </div>
                            <div>
                                <span className="block">Qradar IP Address:</span>
                                <span className="block text-gray-400 text-sm">IP address of syslog server (e.g 127.0.0.1)</span>
                                <input className="input input-txt w-full mt-1" readOnly={!isEditProxy} value={settings ? settings.QRADAR_IP_ADDRESS.value : ''}></input>
                            </div>
                            <div>
                                <span className="block">Syslog Port:</span>
                                <span className="block text-gray-400 text-sm">Listening port of syslog server (e.g 514)</span>
                                <input className="input input-txt w-full mt-1" readOnly={!isEditProxy} value={settings ? settings.QRADAR_PORT.value : ''}></input>
                            </div>
                        </div>
                       
                        <div className={`mt-2 flex flex-row justify-end gap-4 ${!isEditProxy ? "hidden" : ""}`}>
                            <PrimaryBtn className="text-sm" type="submit">Save</PrimaryBtn>
                            <SecondaryBtn className="text-sm" type="button" onClick={() => setIsEditProxy(false)}>Cancel</SecondaryBtn>
                        </div>
                    </form>
                </div>
                <SmallModal ref={infoModal} modalTitle={infoModalContent.title}>
                    <div className="grow flex flex-col items-center">
                        <span className="block text-center text-white">{infoModalContent.content}</span>
                        <PrimaryBtn className="mx-auto mt-auto min-w-[4rem] text-sm" onClick={() => { infoModal.current.close() }}>OK</PrimaryBtn>
                    </div>
                </SmallModal>
            </div>
        </div>
    )
}
export default SystemSettings