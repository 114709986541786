import { URL_BASE_API } from '..'

// Get list of cloud analysis result
export const fetchCloudResultList = async (accessToken, queryParam) => {
    var url = `${URL_BASE_API}/api/cloud-analysis/list/?`
    url = url + Object.keys(queryParam).reduce((result, key) => {
        return [...result, `${encodeURIComponent(key)}=${encodeURIComponent(queryParam[key])}`]
    }, []).join('&')
    const rawResponse = await fetch(url, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken,
        },
    });
    return rawResponse;
}

// Get cloud result detail
export const fetchCloudResult = async (accessToken, resultID) => {
    var url = `${URL_BASE_API}/api/cloud-analysis/${resultID}/`
    const rawResponse = await fetch(url, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken,
        },
    });
    return rawResponse;
}