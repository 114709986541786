import { URL_BASE_API } from '..'

// List file samples
export const fetchFileSamplesList = async (accessToken, queryParam) => {
    var url = `${URL_BASE_API}/api/samples/files/list/?`
    url = url + Object.keys(queryParam).reduce((result, key) => {
        return [...result, `${encodeURIComponent(key)}=${encodeURIComponent(queryParam[key])}`]
    }, []).join('&')
    const rawResponse = await fetch(url, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken,
        },
    });
    return rawResponse;
}

// Get file sample detail
export const fetchFileSampleDetail = async (accessToken, sampleID) => {
    var url = `${URL_BASE_API}/api/samples/files/${sampleID}/`
    const rawResponse = await fetch(url, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken,
        },
    });
    return rawResponse;
}

// Create file sample
export const fetchCreateFileSample = async (accessToken, formData) => {
    const url = `${URL_BASE_API}/api/samples/files/create/`
    const rawResponse = await fetch(url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + accessToken,
        },
        body: formData
    });
    return rawResponse;
}

// Download file sample
export const fetchFileSampleDownload = async (accessToken, sampleID) => {
    var url = `${URL_BASE_API}/api/samples/files/download/${sampleID}/`
    const rawResponse = await fetch(url, {
        headers: {
            // 'Accept': 'application/json',
            // 'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken,
        },
    });
    return rawResponse;
}

// Request re-analysis file sample
export const fetchRequestReAnalysisStatic = async (accessToken, sampleID) => {
    const url = `${URL_BASE_API}/api/samples/files/static-check/${sampleID}/`
    const payload = {
        "string": true,
        "peinfo": true,
        "yara": true,
        "capa": true
    }
    const rawResponse = await fetch(url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken,
        },
        body: JSON.stringify(payload)
    });
    return rawResponse;
}