import moment from 'moment'

export const isoToDateNow = (isoStringTime) => {
    var time = moment(isoStringTime)
    return time.fromNow()
}

export const isoToDateFormat = (isoStringTime) =>{
    const time = moment(isoStringTime)
    return time.format('MM/DD/YYYY HH:mm:ss')
}

export const unixToDateFormat = (unixtime) =>{
    return moment.unix(parseInt(unixtime)).format('MM/DD/YYYY HH:mm:ss')
}