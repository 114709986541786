import React, { useState, useEffect, useContext, useMemo } from "react";
import ReactApexChart from "react-apexcharts";

// Components
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import RecentHashAnalysisTable from "../../components/dashboard/recent-hash-table";
import RecentFileAnalysisTable from "../../components/dashboard/recent-file-table";
import { CustomListBox } from "../../styled_components/listbox";

// Styled components
import { TableBg, } from "../../styled_components/tables";
import {
    HiFingerPrint,
} from "react-icons/hi";
import { ReactComponent as MalwareIcon } from '../../media/images/icons/malware.svg'
import { ReactComponent as PhishingAlertIcon } from '../../media/images/icons/phishing-alert.svg'
import { ReactComponent as PhishingIcon } from '../../media/images/icons/phishing.svg'

// Custom hooks
import { useGeneralStatisticFetch, useStatisticTimelineFetch } from "../../hooks/api/StatisticsFetch";

// Context
import { AuthTokenContext } from "../../contexts/AuthTokenContext";

const DashboardPage = () => {
    useEffect(()=>{console.log('dash load')},[])
    // const [timelineLength, setTimelineLength] = useState(14)
    const { authToken, setAuthToken } = useContext(AuthTokenContext)
    const [
        data,
        isLoading,
        isError,
        setRefreshCall,
    ] = useGeneralStatisticFetch()
    const [
        rawDataHashTimeline,
        dataHashTimeline,
        isLoadingHashTimeline,
        isErrorHashTimeline,
        setRefreshCallHashTimeline,
        setTimeline
    ] = useStatisticTimelineFetch(21)

    useEffect(() => {
        if (isErrorHashTimeline.error === true && isErrorHashTimeline.statusCode === 401) {
            localStorage.removeItem('authToken')
            localStorage.removeItem('userInfo')
            setAuthToken(null)
        }
        if (isError.error === true && isError.statusCode === 401) {
            localStorage.removeItem('authToken')
            localStorage.removeItem('userInfo')
            setAuthToken(null)
        }
    }, [isErrorHashTimeline, isError])
    const timelineOptions = useMemo(()=>[
        {
            value: 7,
            title: '7 Days'
        },
        {
            value: 14,
            title: '2 Weeks'
        },
        {
            value: 21,
            title: '3 Weeks'
        },
        {
            value: 30,
            title: '1 Month'
        },
    ],[] )
    const chartHashTimeline = useMemo(() => {
        const chartConfig = {
            options: {
                chart: {
                    id: "HashTimeline",
                    foreColor: "#fff",
                    toolbar: {
                        autoSelected: "pan",
                        show: false
                    },
                },
                xaxis: {
                    categories: dataHashTimeline.map(data => data.create_timeline.format('DD MMM '))
                },
                colors: ["#fb923c", "#4ade80"],
                stroke: {
                    width: 3
                },
                grid: {
                    borderColor: "#000",
                    clipMarkers: true,
                    yaxis: {
                        lines: {
                            show: false
                        }
                    }
                },
                dataLabels: {
                    enabled: false
                },
                fill: {
                    gradient: {
                        enabled: true,
                        opacityFrom: 0.65,
                        opacityTo: 0
                    }
                },
                markers: {
                    // size: 5,
                    colors: ["#13293D"],
                    strokeColor: ["#fb923c", "#4ade80"],
                    strokeWidth: 3
                },
                tooltip: {
                    theme: "dark"
                },
            },
            series: [
                {
                    name: "Hashes",
                    data: dataHashTimeline.map(data => data.samples)
                },
                {
                    name: "Phishing Alert",
                    data: dataHashTimeline.map(data => data.alerts)
                }
            ],
        }
        return <ReactApexChart
            options={chartConfig.options}
            series={chartConfig.series}
            height={300} type="area" />
    }, [dataHashTimeline])

    const chartPercentMaliciousState = useMemo(() => {
        const chartConfig = {
            options: {
                chart: {
                    height: 350,
                    type: 'radialBar',
                },
                plotOptions: {
                    radialBar: {
                        offsetY: 0,
                        startAngle: 0,
                        endAngle: 270,
                        hollow: {
                            margin: 5,
                            size: '40%',
                            background: 'transparent',
                            image: undefined,
                        },
                        dataLabels: {
                            style: {
                                colors: ['#dc2626', '#fb923c', '#4ade80'],
                            },
                            name: {
                                show: false,
                            },
                            value: {
                                show: true,
                                offsetY: 5,
                                fontSize: '40px',
                                color: '#fff',
                                formatter: (value) => `${parseInt(value)}%`
                            }
                        },
                        track: {
                            background: '#082032'
                        },
                    }
                },
                stroke: {
                    lineCap: "round",
                },
                colors: ['#dc2626', '#fb923c', '#4ade80',],

                labels: ['Malicious', 'Unknown', 'Clean',],
                legend: {
                    show: true,
                    floating: true,
                    fontSize: '14px',
                    position: 'left',
                    offsetX: 15,
                    offsetY: 5,
                    labels: {
                        useSeriesColors: true,
                    },
                    markers: {
                        size: 0
                    },
                    formatter: function (seriesName, opts) {
                        return seriesName
                    },
                    itemMargin: {
                        vertical: 3
                    }
                },
            },
            series: [
                Math.round(data.maliciousHash / (data.maliciousHash + data.unknownHash + data.cleanHash) * 100),
                Math.round(data.unknownHash / (data.maliciousHash + data.unknownHash + data.cleanHash) * 100),
                Math.round(data.cleanHash / (data.maliciousHash + data.unknownHash + data.cleanHash) * 100)]
        };
        return <ReactApexChart
            options={chartConfig.options}
            series={chartConfig.series}
            height={350} type="radialBar" />
    }, [data])
    return (
        <div className="max-w-screen min-h-screen bg-theme-bg-primary text-white flex">
            <Sidebar></Sidebar>
            {/* main */}
            <div className="grow flex flex-col align-top max-w-main gap-4">
                <Navbar>
                    <div className="flex items-end">
                        <a className="text-4xl text-theme-primary">Dashboard</a>
                    </div>
                </Navbar>
                <div className="p-4 grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-4 overflow-auto">
                    {
                        data ?
                            <>
                                {/*  Malicious Files*/}
                                <div className="bg-theme-bg-secondary shadow-theme rounded-lg p-3 flex items-center">
                                    <div className="bg-theme-bg-primary p-3 rounded-lg">
                                        <MalwareIcon className="w-12 h-12 text-red-500" />
                                    </div>
                                    <div className="grow flex flex-col items-start gap-1 pl-4">
                                        <span className="text-lg text-red-500">Malicious Files</span>
                                        <span className="text-3xl">{data.maliciousFile}</span>
                                    </div>
                                </div>

                                {/* Malicious Hases */}
                                <div className="bg-theme-bg-secondary shadow-theme rounded-lg p-3 flex items-center">
                                    <div className="bg-theme-bg-primary p-3 rounded-lg">
                                        <HiFingerPrint className="w-12 h-12 text-orange-400" />
                                    </div>
                                    <div className="grow flex flex-col items-start gap-1 pl-4">
                                        <span className="text-lg text-orange-400">Malicious Hashes</span>
                                        <span className="text-3xl">{data.maliciousHash}</span>
                                    </div>
                                </div>
                                <div className="bg-theme-bg-secondary shadow-theme rounded-lg p-3 flex items-center">
                                    <div className="bg-theme-bg-primary p-3 rounded-lg">
                                        <PhishingAlertIcon className="w-12 h-12 text-green-400" />
                                    </div>
                                    <div className="grow flex flex-col items-start gap-1 pl-4">
                                        <span className="text-lg text-green-400">Phishing Alerts</span>
                                        <span className="text-3xl">{data.phishingAlert}</span>
                                    </div>
                                </div>
                                <div className="bg-theme-bg-secondary shadow-theme rounded-lg p-3 flex items-center">
                                    <div className="bg-theme-bg-primary p-3 rounded-lg">
                                        <PhishingIcon className="w-12 h-12 text-yellow-400" />
                                    </div>
                                    <div className="grow flex flex-col items-start gap-1 pl-4">
                                        <span className="text-lg text-yellow-400">Anti Phishing Profile</span>
                                        <span className="text-3xl">{data.phishingProfile}</span>
                                    </div>
                                </div>
                            </> :
                            <></>
                    }
                    {
                        rawDataHashTimeline.length !== 0 ?
                            <>
                                <div className="col-span-1 2xl:col-span-3 bg-theme-bg-secondary shadow-theme rounded-lg p-4 flex flex-col gap-2">
                                    <div className="flex flex-row justify-between items-start">
                                        <span className="uppercase font-medium text-sm text-theme-primary grow-1">Timeline of statistics</span>
                                        <CustomListBox 
                                            options={timelineOptions} 
                                            defaultIndex={2} directionPopup="bottom" widthFull={false} onChangeCallBack={value => setTimeline(value.value)}></CustomListBox>
                                    </div>
                                    {
                                        chartHashTimeline
                                    }
                                </div>
                                <div className="col-span-1  bg-theme-bg-secondary shadow-theme rounded-lg p-4 flex flex-col gap-2">
                                    <span className="uppercase font-medium text-sm text-theme-primary">Percentage of malicious</span>
                                    {
                                        chartPercentMaliciousState
                                    }
                                </div>
                            </> :
                            <></>
                    }

                    <div className="col-span-1 lg:col-span-2 bg-theme-bg-secondary shadow-theme rounded-lg p-4 flex flex-col gap-2">
                        <span className="uppercase font-medium text-sm text-theme-primary">Recently analyzed hashes</span>
                        <TableBg className="overflow-hidden rounded-lg w-full">
                            <RecentHashAnalysisTable />
                        </TableBg>
                    </div>
                    <div className="col-span-1 lg:col-span-2 bg-theme-bg-secondary shadow-theme rounded-lg p-4 flex flex-col gap-2">
                        <span className="uppercase font-medium text-sm text-theme-primary">Recently analyzed Files </span>
                        <TableBg className="overflow-hidden rounded-lg w-full">
                            <RecentFileAnalysisTable />
                        </TableBg>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default DashboardPage