import {useState, useContext, useEffect} from 'react'
import { AuthTokenContext } from '../../contexts/AuthTokenContext'
import { verifyToken } from '../../apis/auth'

export const useCheckLoggedIn =  () => {
    const {authToken, setAuthToken} = useContext(AuthTokenContext)
    const [isLogged, setIsLoged] = useState(true)
    useEffect(async ()=>{
        if(authToken){
            try{
                const resp = await verifyToken(authToken.access)
                if(resp.status === 200){
                    // Try to refresh token
                    setIsLoged(true)
                }
                else{
                    setIsLoged(false)
                }
            }
            catch(err){
                setIsLoged(false)
            }
        }
        else{
            setIsLoged(false)
        }
    },[authToken])
    return [isLogged, setIsLoged]
}