import React, { useState, useEffect, useContext, useRef } from "react";
import { Tab } from '@headlessui/react'
import { useParams } from "react-router-dom";

// Components 
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import CommentsComponent from "../../components/comments";
import AnalysisCloud from "../../components/analysis";
import AnalysisStatic from "../../components/staticAnalysis";
import ArtifactsComponent from "../../components/artifacts";

// Styled components
import { BadgeRed, BadgeGreen, BadgeGray, } from "../../styled_components/badges";
import { PrimaryBtn, SecondaryBtn } from "../../styled_components/buttons";
import { TableBg, Table, Td, Tr, Tbody } from "../../styled_components/tables";
import { SmallModal, MediumModal } from "../../styled_components/modals";
import { CustomListBox } from "../../styled_components/listbox";
import Dropdown from "../../styled_components/dropdown";
import {
    HiChevronRight,
    HiOutlineChevronDown,
    HiOutlineDocumentSearch,
    HiOutlineShieldExclamation,
    HiOutlineShare,
    HiOutlineDownload,
} from "react-icons/hi";

// Api
import { fetchFileSampleDownload, fetchRequestReAnalysisStatic } from "../../apis/file-samples";
import { fetchRequestReAnalysisHash, fetchMarkHashSampleIsMalicious, fetchShareSignaureToQradar } from "../../apis/hash-sample";

// Context
import { AuthTokenContext } from "../../contexts/AuthTokenContext";

// Utils
import { isoToDateFormat } from "../../ultils/datetime_format";

// Custom hooks
import { useAsyncFetch } from "../../hooks/AsyncFetch";
import { useFileSamplesDetailFetch } from "../../hooks/api/FileSamplesFetch";


const isMaliciousOptions = [
    {
        value: 'true',
        title: 'Malicious'
    },
    {
        value: 'false',
        title: 'Clean'
    },
    {
        value: 'null',
        title: 'Unknown'
    },
]

const SampleDetailPage = () => {
    const params = useParams()
    const [        
        data,
        isLoading,
        isError,
        setRefreshCall,] = useFileSamplesDetailFetch(params.id)
    // const [
    //     isLoadingDetailSample,
    //     setIsLoading,
    //     isErrorDetailSample,
    //     setIsErrorDetailSample
    // ] = useAsyncFetch()

    useEffect(()=>{
        if(isError.error&&isError.statusCode===401){
            localStorage.removeItem('authToken')
            localStorage.removeItem('userInfo')
            setAuthToken(null)
        }
    }, [isError])
    // Modals
    const reAnalysisModal = useRef()
    const markMaliciousModal = useRef()
    const sendToQradarModal = useRef()
    const infoModal = useRef()
    // Modal information state
    const [infoModalContent, setInfoModalContent] = useState({
        title: '',
        content: ''
    })

    // Action menu items
    const actionItems = [
        [
            {
                'icon': <HiOutlineDocumentSearch className="w-5 h-5 mr-3 text-theme-primary" />,
                'text': "Re-analysis",
                'href': "#/re-analysis",
                'onClick': () => {
                    reAnalysisModal.current.open()
                }
            },
            {
                'icon': <HiOutlineShieldExclamation className="w-5 h-5 mr-3 text-theme-primary" />,
                'text': "Mark Malicious",
                'href': "#marl-malicious",
                'onClick': () => {
                    markMaliciousModal.current.open()
                }
            },
            {
                'icon': <HiOutlineShare className="w-5 h-5 mr-3 text-theme-primary" />,
                'text': "Send to Qradar",
                'href': "#profile",
                'onClick': () => {
                    sendToQradarModal.current.open()
                }
            },
            {
                'icon': <HiOutlineDownload className="w-5 h-5 mr-3 text-theme-primary" />,
                'text': "Download",
                'href': "#",
                'onClick': () => {
                    onDownloadSample()
                }
            }
        ],
    ]

    const { authToken, setAuthToken } = useContext(AuthTokenContext)
    const [isMaliciousSet, setIsMaliciousSet] = useState()
    const [
        isLoadingSetMalicious,
        setIsLoadingSetMalicious,
        isErrorSetMalicious,
        setIsErrorSetMalicious
    ] = useAsyncFetch()

    const onRequestReanalysis = async () => {
        reAnalysisModal.current.close()
        if (data.id) {
            try {
                const resp = await fetchRequestReAnalysisStatic(authToken.access, data.id)
                const repsjson = await resp.json()
                if (resp.status === 200 && data.hashes.id) {
                    // Call cloud analysis
                    const respCloudRequest = await fetchRequestReAnalysisHash(authToken.access, data.hashes.id)
                    const respCloudRequestJson = await respCloudRequest.json()
                    if(respCloudRequest.status===200){
                        setInfoModalContent({
                            title: 'Information',
                            content: "Request re-analysis is successfully processed"
                        })
                    }
                    else{
                        setInfoModalContent({
                            title: 'Error',
                            content: respCloudRequestJson.detail
                        })
                    }
                }
                else {
                    setInfoModalContent({
                        title: 'Error',
                        content: repsjson.detail
                    })
                }
            }
            catch (err) {
                setInfoModalContent({
                    title: 'Error',
                    content: err.message
                })
            }
            infoModal.current.open()
        }
    }
    const onSetMaliciousState = async () => {
        markMaliciousModal.current.close()
        if (data.hashes.id) {
            setIsLoadingSetMalicious(true)
            setIsErrorSetMalicious({
                error: false,
                statusCode: 0,
                message: ''
            })
            try {
                const resp = await fetchMarkHashSampleIsMalicious(authToken.access, data.hashes.id, isMaliciousSet === 'true' ? true : isMaliciousSet === 'false' ? false : null)
                const repsjson = await resp.json()
                if (resp.status === 200) {
                    // Refresh fetch
                    setRefreshCall(Date())
                }
                else {
                    setIsErrorSetMalicious({
                        error: true,
                        statusCode: resp.status,
                        message: repsjson.detail
                    })
                    setInfoModalContent({
                        title: 'Error',
                        content: repsjson.detail
                    })
                }
            }
            catch (err) {
                setIsErrorSetMalicious({
                    error: true,
                    statusCode: 0,
                    message: err.message
                })
                setInfoModalContent({
                    title: 'Error',
                    content: err.message
                })
            }
            setIsLoadingSetMalicious(false)
            if (isErrorSetMalicious.error) {
                infoModal.current.open()
            }
        }
    }
    const onShareQradar = async () => {
        sendToQradarModal.current.close()
        if (data.hashes.id) {
            try {
                const resp = await fetchShareSignaureToQradar(authToken.access, data.hashes.id)
                const repsjson = await resp.json()
                if (resp.status === 200) {
                    setInfoModalContent({
                        title: 'Information',
                        content: "Signature is sent to Qradar successfully"
                    })
                }
                else {
                    setInfoModalContent({
                        title: 'Error',
                        content: repsjson.detail
                    })
                }
            }
            catch (err) {
                setInfoModalContent({
                    title: 'Error',
                    content: err.message
                })
            }
            infoModal.current.open()
        }
    }
    const onDownloadSample = async () => {
        if (data.id) {
            try {
                const resp = await fetchFileSampleDownload(authToken.access, data.id)

                if (resp.status===200) {
                    const blob = await resp.blob()
                    const url = window.URL.createObjectURL(blob)
                    var link = document.createElement('a')
                    link.href = url
                    link.download= `${data.filename}.zip`
                    document.body.appendChild(link)
                    link.click()
                    link.remove()
                }
                else {
                    setInfoModalContent({
                        title: 'Error',
                        content: "There is a problem in download file sample"
                    })
                    infoModal.current.open()
                }
            }
            catch (err) {
                setInfoModalContent({
                    title: 'Error',
                    content: err.message
                })
                infoModal.current.open()
            }
        }
    }
    return (
        <div className="max-w-screen max-h-screen bg-theme-bg-primary text-white flex">
            <Sidebar></Sidebar>
            {/* main */}
            <div className="grow flex flex-col align-top max-w-main gap-4">
                <Navbar pageTitle={"Hashes Detail"} >
                    <div className="flex items-end">
                        <a className="text-4xl text-theme-primary" href="#/samples">File Samples</a>
                        <HiChevronRight className="w-8 h-8" />
                        <span>{data && data['id']?data.id:''}</span>
                    </div>
                </Navbar>
                <div className="px-4 pb-4 grow overflow-x-auto">
                    <div className="flex justify-between items-center">
                        <h2 className="text-theme-primary uppercase text-lg">detail information</h2>
                        <Dropdown items={actionItems}>
                            <PrimaryBtn className="text-sm flex">Actions
                                <HiOutlineChevronDown
                                    className="w-5 h-5 ml-2 -mr-1 text-theme-bg-900"
                                    aria-hidden="true"
                                />
                            </PrimaryBtn>
                        </Dropdown>
                    </div>
                    {
                        Object.keys(data) !== 0 && data.hashes && data.hashes.id ?
                            <>
                                <TableBg>
                                    <Table>
                                        <Tbody>
                                            <Tr>
                                                <Td className="text-white">
                                                    Filename
                                                </Td>
                                                <Td>
                                                    {data.filename}
                                                </Td>
                                                <Td className="text-white">
                                                    File Size
                                                </Td>
                                                <Td>
                                                    {data.filesize}
                                                </Td>
                                            </Tr>
                                            <Tr>
                                                <Td className="text-white">
                                                    Uploaded By
                                                </Td>
                                                <Td>
                                                    {data.owner}
                                                </Td>
                                                <Td className="text-white">
                                                    Result
                                                </Td>
                                                <Td>
                                                    {data.hashes.is_malicious === true ?
                                                        (<BadgeRed>Malicious</BadgeRed>) :
                                                        data.hashes.is_malicious === false ?
                                                            (<BadgeGreen>Clean</BadgeGreen>) :
                                                            (<BadgeGray>Unknown</BadgeGray>)}
                                                </Td>
                                            </Tr>
                                            <Tr>
                                                <Td className="text-white">
                                                    MIME
                                                </Td>
                                                <Td>
                                                    {data.mime_type}
                                                </Td>
                                                <Td className="text-white">
                                                    Magic
                                                </Td>
                                                <Td>
                                                    {data.magic}
                                                </Td>
                                            </Tr>
                                            <Tr>
                                                <Td className="text-white">Last Check</Td><Td>{isoToDateFormat(data.hashes.latest_check_time)}</Td>
                                                <Td className="text-white">First Check</Td><Td>{isoToDateFormat(data.hashes.first_check_time)}</Td>
                                            </Tr>
                                            <Tr>
                                                <Td className="text-white">
                                                    MD5:
                                                </Td>
                                                <Td>
                                                    {data.hashes.md5}
                                                </Td>
                                                <Td className="text-white">
                                                    CRC32:
                                                </Td>
                                                <Td>
                                                    {data.hashes.crc32}
                                                </Td>
                                            </Tr>
                                            <Tr>
                                                <Td className="text-white">
                                                    SSDEEP
                                                </Td>
                                                <td className="td" colSpan="3">
                                                    {data.hashes.ssdeep}
                                                </td>
                                            </Tr>
                                            <Tr>
                                                <Td className="text-white">
                                                    SHA1:
                                                </Td>
                                                <td className="td" colSpan="3">
                                                    {data.hashes.sha1}
                                                </td>
                                            </Tr>
                                            <Tr>
                                                <Td className="text-white">
                                                    SHA224
                                                </Td>
                                                <td className="td" colSpan="3">
                                                    {data.hashes.sha224}
                                                </td>
                                            </Tr>
                                            <Tr>
                                                <Td className="text-white">
                                                    SHA256
                                                </Td>
                                                <td className="td" colSpan="3">
                                                    {data.hashes.sha256}
                                                </td>
                                            </Tr>
                                            <Tr>
                                                <Td className="text-white">
                                                    SHA384
                                                </Td>
                                                <td className="td" colSpan="3">
                                                    {data.hashes.sha384}
                                                </td>
                                            </Tr>
                                            <Tr>
                                                <Td className="text-white">
                                                    SHA512
                                                </Td>
                                                <td className="td" colSpan="3">
                                                    {data.hashes.sha512}
                                                </td>
                                            </Tr>
                                        </Tbody>
                                    </Table>
                                </TableBg>
                                <Tab.Group as="div" className=" bg-theme-bg-secondary shadow-theme rounded-lg p-4 flex flex-col gap-2">
                                    <Tab.List as="div" className="flex justify-center items-center">
                                        <Tab className={({selected}) => selected? 'bg-theme-primary text-theme-bg-primary tab-btn':'bg-theme-bg-primary text-white tab-btn'}>Static Analysis</Tab>
                                        <Tab className={({ selected }) => selected ? 'bg-theme-primary text-theme-bg-primary tab-btn' : 'bg-theme-bg-primary text-white tab-btn'}>Cloud Analysis</Tab>
                                        <Tab className={({ selected }) => selected ? 'bg-theme-primary text-theme-bg-primary tab-btn' : 'bg-theme-bg-primary text-white tab-btn'}>Comments</Tab>
                                        <Tab className={({ selected }) => selected ? 'bg-theme-primary text-theme-bg-primary tab-btn' : 'bg-theme-bg-primary text-white tab-btn'}>Artifacts</Tab>
                                    </Tab.List>
                                    <Tab.Panels>
                                        <Tab.Panel><AnalysisStatic fileSampleID={data.id}/></Tab.Panel>
                                        <Tab.Panel><AnalysisCloud hashID={data.hashes.id} /></Tab.Panel>
                                        <Tab.Panel><CommentsComponent objectType="HashSample" objectID={data.hashes.id} /></Tab.Panel>
                                        <Tab.Panel><ArtifactsComponent hashID={data.hashes.id} /></Tab.Panel>
                                    </Tab.Panels>
                                </Tab.Group>
                                <SmallModal ref={infoModal} modalTitle={infoModalContent.title}>
                                    <div className="grow flex flex-col items-center">
                                        <span className="block text-center text-white">{infoModalContent.content}</span>
                                        <PrimaryBtn className="mx-auto mt-auto min-w-[4rem] text-sm" onClick={() => { infoModal.current.close() }}>OK</PrimaryBtn>
                                    </div>
                                </SmallModal>
                                <SmallModal ref={reAnalysisModal} modalTitle="Re-analysis">
                                    <div className="grow flex flex-col items-center justify-center">
                                        <span className="block text-center text-white">Do you want to re-analysis this hash sample?</span>
                                        <div className="mt-auto flex flex-row items-center gap-4">
                                            <PrimaryBtn className="min-w-[5rem] text-sm" onClick={onRequestReanalysis}>Confirm</PrimaryBtn>
                                            <SecondaryBtn className="min-w-[5rem] text-sm" onClick={() => { reAnalysisModal.current.close() }}>Cancel</SecondaryBtn>
                                        </div>
                                    </div>
                                </SmallModal>
                                <SmallModal ref={markMaliciousModal} modalTitle="Mark Sample Malicious">
                                    <div className="grow w-full flex flex-col items-center justify-center gap-4">
                                        <div className="flex flex-col w-full gap-2">
                                            <span className=" text-white">Mark sample malicious state</span>
                                            <CustomListBox options={isMaliciousOptions} defaultIndex={isMaliciousOptions.reduce((retval, type, idx) => type.value === String(data.hashes.is_malicious) ? idx : retval, 0)} directionPopup="bottom" onChangeCallBack={value => setIsMaliciousSet(value.value)} />
                                        </div>
                                        <div className="mt-auto flex flex-row items-center gap-4">
                                            <PrimaryBtn className="min-w-[5rem] text-sm" onClick={onSetMaliciousState}>Confirm</PrimaryBtn>
                                            <SecondaryBtn className="min-w-[5rem] text-sm" onClick={() => { markMaliciousModal.current.close() }}>Cancel</SecondaryBtn>
                                        </div>
                                    </div>
                                </SmallModal>
                                <SmallModal ref={sendToQradarModal} modalTitle="Send To Qradar">
                                    <div className="grow flex flex-col items-center justify-center">
                                        <span className="block text-center text-white">Do you want to send this signature of the sample to Qradar?</span>
                                        <div className="mt-auto flex flex-row items-center gap-4">
                                            <PrimaryBtn className="min-w-[5rem] text-sm" onClick={onShareQradar}>Confirm</PrimaryBtn>
                                            <SecondaryBtn className="min-w-[5rem] text-sm" onClick={() => { reAnalysisModal.current.close() }}>Cancel</SecondaryBtn>
                                        </div>
                                    </div>
                                </SmallModal>
                            </> :
                            <></>
                    }
                </div>
            </div>
        </div >
    )
}
export default SampleDetailPage