import React, {useEffect} from "react";

// Components
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";

const HashesPage = () => {
    useEffect(()=>{console.log('Page loaded')}, [])
    return (
        <div className="max-w-screen max-h-screen bg-theme-bg-primary text-white flex">
            <Sidebar></Sidebar>
            {/* main */}
            <div className="grow flex flex-col align-top max-w-main gap-4">
                <Navbar>
                    <div className="flex items-end">
                        <a className="text-4xl text-theme-primary">Hash Samples</a>
                    </div>
                </Navbar>
                <div className="px-4 pb-4 grow overflow-x-auto">
                    <h1>test page</h1>
                </div>
            </div>
        </div>
    )
}
export default HashesPage