import React, { useMemo } from "react";
import { Tab } from "@headlessui/react";
import { TableBg, Table, Thread, Th, Tbody, Tr, Td } from "../../styled_components/tables";
import ReactApexChart from "react-apexcharts";
import { Badge } from "../../styled_components/badges";
import { HiOutlineCheck, HiOutlineBan } from "react-icons/hi";
import DisclosureComponent from "../../styled_components/disclosure";
import ReactJson from 'react-json-view'
import { ReactComponent as NotFoundIcon } from '../../media/images/icons/not-found.svg'
import { useCloudResultDetailFetch } from "../../hooks/api/CloudResultsFetch";
import { unixToDateFormat } from "../../ultils/datetime_format";

const VirustotalReport = ({ resultID }) => {
    const [
        dataResult,
        isLoading,
        isError,
        refreshCall,] = useCloudResultDetailFetch(resultID)
    const calcCorlor = (percent) => {
        // Green
        if (percent < 33) {
            return '#22c55e'
        }
        // Orage
        if (percent < 66) {
            return '#f97316'
        }
        //  Red
        return '#ef4444'
    }
    const overviewChart = useMemo(() => {
        if (dataResult === undefined || dataResult === null || Object.keys(dataResult).length === 0) {
            return <></>
        }
        if (Object.keys(dataResult.data).length === 0) {
            return <></>
        }

        const numOfEngines = Object.values(dataResult.data.attributes.last_analysis_stats).reduce((total, value) => total + value, 0)
        const detectedEngine = dataResult.data.attributes.last_analysis_stats.malicious
        const percentage = detectedEngine / numOfEngines * 100
        const chartConfig = {
            series: [percentage],
            options: {
                chart: {
                    height: 350,
                    type: 'radialBar',
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            size: '60%',
                        },
                        track: {
                            background: '#0a101d',
                            strokeWidth: '100%',
                            margin: 0, // margin is in pixels
                        },
                        dataLabels: {
                            show: true,
                            name: {
                                offsetY: 30,
                                show: true,
                                color: '#fff',
                                fontSize: '18px',
                                fontWeight: 100,
                            },
                            value: {
                                formatter: function (val) {
                                    return parseInt(detectedEngine);
                                },
                                offsetY: -15,
                                color: calcCorlor(percentage),
                                fontSize: '36px',
                                show: true,
                            }
                        }
                    }
                },

                stroke: {
                    lineCap: 'round'
                },
                labels: [`/${numOfEngines}`],
                colors: [calcCorlor(percentage),]
            },
        };
        return <ReactApexChart
            options={chartConfig.options}
            series={chartConfig.series}
            type="radialBar" height={200} width={200} />
    }, [dataResult])


    return (
        <div className="grow overflow-hidden flex flex-col">
            {
                (dataResult === undefined || dataResult === null || Object.keys(dataResult).length === 0) ?
                    <div className="w-full h-full flex items-center justify-center gap-4">
                        <NotFoundIcon className="text-gray-400 w-20 h-20" />
                        <div>
                            <p className="text-gray-400 font-semibold text-2xl">Not Found</p>
                            <p className="text-gray-400">This sample does not exist on VirusTotal</p>
                        </div>
                    </div>
                    :
                    Object.keys(dataResult.data).length === 0 ?
                        <div className="w-full h-full flex items-center justify-center gap-4">
                            <NotFoundIcon className="text-gray-400 w-20 h-20" />
                            <div>
                                <p className="text-gray-400 font-semibold text-2xl">Not Found</p>
                                <p className="text-gray-400">This sample does not exist on VirusTotal</p>
                            </div>
                        </div>
                        : <>
                            <div className="w-full flex">
                                {overviewChart}
                                <div className="text-white grow">

                                    <tr className>
                                        <td className="td py-1">Filename</td>
                                        <td className="td py-1 text-gray-400">{dataResult.data.attributes.names.length === 0 ? "-" : dataResult.data.attributes.names[0]}</td>
                                    </tr>
                                    <tr>
                                        <td className="td py-1">File type</td>
                                        <td className="td py-1 text-gray-400">{dataResult.data.attributes.type_description}</td>
                                    </tr>
                                    <tr>
                                        <td className="td py-1">Create Time</td>
                                        <td className="td py-1 text-gray-400">{unixToDateFormat(dataResult.data.attributes.creation_date)}</td>
                                    </tr>
                                    <tr>
                                        <td className="td py-1">Last Analysis</td>
                                        <td className="td py-1 text-gray-400">{unixToDateFormat(dataResult.data.attributes.last_submission_date)}</td>
                                    </tr>
                                    <tr>
                                        <td className="td py-1">Tags</td>
                                        <td className="td py-1 text-gray-400 flex gap-1 flex-wrap">
                                            {
                                                dataResult.data.attributes.tags.map((tag, index) => (<Badge key={index}>{tag}</Badge>))
                                            }
                                        </td>
                                    </tr>
                                </div>
                            </div>
                            <Tab.Group as="div" className="mt-4 grow flex flex-col gap-2 overflow-hidden">
                                <Tab.List as="div" className="flex justify-center items-center">
                                    <Tab className={({ selected }) => selected ? 'bg-theme-primary text-theme-bg-primary tab-btn' : 'bg-theme-bg-secondary text-white tab-btn'}>Detection</Tab>
                                    <Tab className={({ selected }) => selected ? 'bg-theme-primary text-theme-bg-primary tab-btn' : 'bg-theme-bg-secondary text-white tab-btn'}>Details</Tab>
                                    <Tab className={({ selected }) => selected ? 'bg-theme-primary text-theme-bg-primary tab-btn' : 'bg-theme-bg-secondary text-white tab-btn'}>Raw</Tab>
                                </Tab.List>
                                <Tab.Panels className="flex flex-col overflow-hidden">
                                    <Tab.Panel className="flex flex-col overflow-hidden">
                                        <TableBg className="grow overflow-auto">
                                            <Table>
                                                <Thread>
                                                    <Tr>
                                                        <Th>Engine</Th>
                                                        <Th>Detection</Th>
                                                    </Tr>
                                                </Thread>
                                                <Tbody className="overflow-y-auto max-w-main">
                                                    {
                                                        Object.values(dataResult.data.attributes.last_analysis_results)
                                                            .sort((a, b) => (a.result ? a.result : "Undetected") > (b.result ? b.result : "Undetected") ? 1 : -1)
                                                            .map((engine, index) => (
                                                                <Tr key={index}>
                                                                    <Td>{engine.engine_name}</Td>
                                                                    <Td className="flex gap-1 items-center">
                                                                        {engine.result ?
                                                                            <>
                                                                                <HiOutlineBan className='w-5 h-5 text-red-500' />
                                                                                <span>{engine.result}</span>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <HiOutlineCheck className='w-5 h-5 text-green-500' />
                                                                                <span>Undetected</span>
                                                                            </>
                                                                        }
                                                                    </Td>
                                                                </Tr>
                                                            ))
                                                    }

                                                </Tbody>
                                            </Table>
                                        </TableBg>
                                    </Tab.Panel>
                                    <Tab.Panel className="flex flex-col overflow-hidden">
                                        <div className="grow overflow-auto">
                                            <DisclosureComponent title="Binary Signature">
                                                <table className="w-full">
                                                    {
                                                        dataResult.data.attributes.trid.map((type, indx) => (
                                                            <tr >
                                                                <td className="td py-1">{type.file_type}</td>
                                                                <td className="td py-1 text-gray-400">{type.probability} %</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </table>
                                            </DisclosureComponent>
                                            <DisclosureComponent title="Dynamic Linked Library">
                                                <table className="w-full">
                                                    {
                                                        dataResult.data.attributes.pe_info && dataResult.data.attributes.pe_info.import_list ?
                                                            dataResult.data.attributes.pe_info.import_list.map((type, indx) => (
                                                                <tr >
                                                                    <td className="td py-1">{type.library_name}</td>
                                                                </tr>
                                                            )) : <span className="w-full table text-gray-400 text-center">Dynamic link library data is not available for this sample</span>
                                                    }
                                                </table>
                                            </DisclosureComponent>
                                            <DisclosureComponent title="Compiler Product Version">
                                                <table className="w-full">
                                                    {
                                                        dataResult.data.attributes.pe_info && dataResult.data.attributes.pe_info.compiler_product_versions ?
                                                            dataResult.data.attributes.pe_info.compiler_product_versions.map((compiler, indx) => (
                                                                <tr >
                                                                    <td className="td py-1">{compiler}</td>
                                                                </tr>
                                                            )) : <span className="w-full table text-gray-400 text-center">Compiler product version data is not available for this sample</span>
                                                    }
                                                </table>
                                            </DisclosureComponent>
                                        </div>
                                    </Tab.Panel>
                                    <Tab.Panel className="flex flex-col overflow-auto">
                                        <div className="overflow-auto bg-gray-900 rounded-lg p-2">
                                            <ReactJson style={{ backgroundColor: "transparent" }}
                                                iconStyle="square"
                                                theme="railscasts"
                                                displayDataTypes={false}
                                                src={dataResult.data.attributes}
                                                collapseStringsAfterLength={32}
                                                collapsed={1}
                                                displayObjectSize={false} />
                                        </div>
                                    </Tab.Panel>
                                </Tab.Panels>
                            </Tab.Group>
                        </>
            }

        </div>
    )
}
export default VirustotalReport