import React, { useState, useEffect, useRef, useMemo } from "react";
import { TableBg, Table, Thread, Tr, Th, Tbody, Td } from "../styled_components/tables";
import { BadgeGreen, Badge, BadgeGray, BadgeYellow } from "../styled_components/badges";
import { SideModal } from "../styled_components/modals";
import VirustotalReport from "./threatintel/virustotal";
import HybridAnalysisReport from "./threatintel/hybrid_analysis";
import { useCloudResultsFetch } from "../hooks/api/CloudResultsFetch";
import StaticAnalysisReport from "./static-analysis";
import { useStaticResultsFetch } from "../hooks/api/StaticResultsFetch";
import { isoToDateFormat } from "../ultils/datetime_format";
import { useTable, useSortBy } from "react-table";
import { HiChevronUp, HiChevronDown, HiOutlineCubeTransparent } from "react-icons/hi";

const AnalysisStatic = ({ fileSampleID }) => {
  const [
    pagination,
    setPagination,
    sortField,
    setSortField,
    filter,
    setFilter,
    data,
    nextPage,
    previous,
    totalItem,
    isLoading,
    isError,
    refreshCall,
  ] = useStaticResultsFetch(0, 1000, fileSampleID)
  const detailModalRef = useRef(false)
  const clickHandler = (item) => {
    setSelectedRow(item)
    detailModalRef.current.open()
  }

  // TABLE
  const [selectedRow, setSelectedRow] = useState()
  const columns = useMemo(() => [
    {
      Header: "id",
      accessor: "id"
    },
    {
      Header: "request by",
      accessor: "request_by"
    },
    {
      Header: "request time",
      accessor: "request_time",
      Cell: ({ value }) => {
        return isoToDateFormat(value)
      }
    },
    {
      Header: "analysis type",
      accessor: "analysis_type",
      Cell: ({ value }) => {
        if (value === 'Yara') {
          return <BadgeYellow>{value}</BadgeYellow>
        }
        if (value === 'PeInfo') {
          return <BadgeGreen>{value}</BadgeGreen>
        }
        return <BadgeGray>{value}</BadgeGray>
      }
    },
  ],
    [])
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // We need to get 'sortBy' from the table state, then use it as an argument to fetch new data
    state: { sortBy }
  } = useTable(
    {
      columns,
      data,
      // we need to tell react-table that we will handle sorting manually
      manualSortBy: true
    },
    useSortBy
  );

  // Handle sort by server site
  useEffect(() => {
    if (sortBy.length > 0) {
      setSortField(`${sortBy[0].desc ? '-' : ''}${sortBy[0].id}`)
    }
  }, [sortBy])
  return (
    <>
      <TableBg>
        <Table {...getTableProps()}>
          <Thread>
            {
              headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {
                    headerGroup.headers.map((column) => (
                      <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                        <div className="flex items-center  gap-2 ">
                          <span>{column.render("Header")}</span>
                          {
                            column.isSorted ?
                              column.isSortedDesc ?
                                <HiChevronDown className="w-4 h-4" /> :
                                <HiChevronUp className="w-4 h-4" /> :
                              <></>
                          }
                        </div>
                      </Th>
                    ))
                  }
                </tr>
              ))
            }
          </Thread>
          <Tbody {...getTableBodyProps()}>
            {
              rows.length === 0 ?
                <Tr>
                  <Td colSpan={4}>
                    <div className="flex items-center justify-center gap-4">
                      <HiOutlineCubeTransparent className="w-12 h-12" />
                      <div>
                        <p className="font-semibold text-2xl">No existed cloud analysis results</p>
                        <p>You can request re-analysis to this sample</p>
                      </div>
                    </div>
                  </Td>
                </Tr> : <></>
            }
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} onClick={() => clickHandler(row.original)}>
                  {row.cells.map((cell) => {
                    return (
                      <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableBg>
      <SideModal ref={detailModalRef} modalTitle={selectedRow ? selectedRow.id : '-'}>
        {
          selectedRow ?
            <StaticAnalysisReport resultID={selectedRow.id}/>:<></>
        }
      </SideModal>
    </>
  )
}
export default AnalysisStatic