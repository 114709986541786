import React, { useContext, useRef, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

// Style Components
import logo from '../../media/images/logo-obj.svg'
import { PrimaryBtn } from "../../styled_components/buttons";
import { SmallModal } from "../../styled_components/modals";

// Contexts
import { AuthTokenContext } from "../../contexts/AuthTokenContext";
import { UserInfoContext } from "../../contexts/UserInfoContext";

// APIs
import { verifyToken, fetchUserInfo, login } from "../../apis/auth";

const initialError = {
    isError: false,
    message: '',
}

const LoginPage = () => {
    const navigator = useNavigate()
    const modalError = useRef()
    const { authToken, setAuthToken } = useContext(AuthTokenContext)
    const { userInfo, setUserInfo } = useContext(UserInfoContext)
    const [error, setError] = useState(initialError)
    const [isLoading, setIsLoading] = useState(false)


    useEffect(async () => {
        if (authToken) {
            try {
                const resp = await verifyToken(authToken.access)
                if (resp.status === 200) {
                    // if refresh token is valid => get current user info
                    const respUserInfo = await fetchUserInfo(authToken.access)
                    const respUserInfoRaw = await respUserInfo.json()
                    if (respUserInfo.status === 200) {
                        setUserInfo(respUserInfoRaw)
                        localStorage.setItem('userInfo', JSON.stringify(respUserInfoRaw))

                        // redirect to homepage
                        navigator('/')
                    }
                }
                // Clear auth token if verify false
                else{
                    localStorage.removeItem('authToken')
                    setAuthToken(null)
                }
            }
            catch (err) { }
        }
    }, [])

    // Update to info modal
    useEffect(() => {
        if (error.isError === true) {
            modalError.current.open()
        }
    }, [error])
    // Submit login form
    const onLoginSubmit = async (e) => {
        e.preventDefault()
        let submitData = {}
        const data = new FormData(e.target);
        data.forEach((value, key) => submitData[key] = value);
        //  check if username or password is null
        if (!submitData['username'] || !submitData['password']) {
            setError({
                isError: true,
                message: 'Please input your username and password.'
            })
            return
        }
        // Call api login
        try {
            setIsLoading(true)
            setError(initialError)
            const resp = await login(submitData['username'], submitData['password'])
            // handle success => set Auth token context and redirect to /
            const respjson = await resp.json()
            if (resp.status === 200) {
                setError(initialError)
                setIsLoading(false)
                setAuthToken(respjson)
                localStorage.setItem('authToken', JSON.stringify(respjson))

                const respUserInfo = await fetchUserInfo(respjson.access)
                const respUserInfoRaw = await respUserInfo.json()

                if (respUserInfo.status === 200) {
                    setUserInfo(respUserInfoRaw)
                    localStorage.setItem('userInfo', JSON.stringify(respUserInfoRaw))

                    // redirect to homepage
                    navigator('/')
                }
                return
            }
            // Handle error default
            setError({
                isError: true,
                message: respjson.detail
            })
            setIsLoading(false)
            return
        }
        catch (err) {
            setError({ isError: true, message: err.message })
        }
        setIsLoading({ isLoading: false, message: '' });
    }

    return (
        <div className="w-screen min-h-screen bg-theme-bg-primary text-white flex">
            <div className="flex flex-col items-start justify-center  p-24">
                <h1 className="text-theme-primary text-4xl font-semibold">EcartMal</h1>
                <span className="text-2xl font-light">Central mamagement malware samples</span>
            </div>
            <div className="shadow-theme flex flex-col gap-y-16 items-center justify-center ml-auto bg-theme-bg-secondary min-h-screen w-[24rem] p-8">
                <img src={logo} width="140px" alt="React Logo" />
                <div className="text-center">
                    <form onSubmit={onLoginSubmit}>
                        <span className="text-2xl font-light">Sign in to your account</span>
                        <input type="text" id="username" name="username" className="mt-8 input input-txt w-full" placeholder="Username"></input>
                        <input type="password" id="password" name="password" className="mt-2 input input-txt w-full" placeholder="Password"></input>
                        <PrimaryBtn type="submit" className="mt-8 w-full" value="Submit">Login</PrimaryBtn>
                    </form>
                </div>
            </div>
            <SmallModal ref={modalError} modalTitle="Error">
                <div className="grow flex flex-col items-center">
                    <span className="block text-center text-white">{error.message}</span>
                    <PrimaryBtn className="mx-auto mt-auto min-w-[4rem]" onClick={() => { modalError.current.close() }}>OK</PrimaryBtn>
                </div>
            </SmallModal>
        </div>
    )
}
export default LoginPage