import React, { useEffect, useMemo, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTable, useSortBy } from "react-table";
import { BadgeRed, BadgeGreen, BadgeGray } from "../../styled_components/badges";
import { Table, Thread, Th, Td, Tr, Tbody } from "../../styled_components/tables";
import { useListFileSamplesFetch } from "../../hooks/api/FileSamplesFetch";
import { isoToDateNow } from "../../ultils/datetime_format";
import { AuthTokenContext } from "../../contexts/AuthTokenContext";
import {
    HiChevronDown,
    HiChevronUp,
} from "react-icons/hi";


const RecentFileAnalysisTable = () => {
    const navigator = useNavigate()

    // Authentication token in context
    const { authToken, setAuthToken } = useContext(AuthTokenContext)

    // 
    // Data table
    // 

    const [
        pagination,
        setPagination,
        sortField,
        setSortField,
        filter,
        setFilter,
        data,
        nextPage,
        previous,
        totalItem,
        isLoading,
        isError,
        setRefreshCall
    ] = useListFileSamplesFetch(0, 5, '-latest_check_time')

    // If fetch return 401 => session time out
    useEffect(() => {
        if (isError.error && isError.statusCode === 401) {
            localStorage.removeItem('authToken')
            localStorage.removeItem('userInfo')
            setAuthToken(null)
        }
    }, [isError])


    const columns = useMemo(() => [
        {
            Header: "filename",
            accessor: "filename"
        },
        // {
        //     Header: "owner",
        //     accessor: "owner"
        // },
        {
            Header: "upload time",
            accessor: "upload_time",
            Cell: ({ value }) => {
                return isoToDateNow(value)
            }
        },
        {
            Header: "Latest Analysis",
            accessor: "hashes.latest_check_time",
            Cell: ({ value }) => {
                return isoToDateNow(value)
            }
        },
        // {
        //     Header: "md5",
        //     accessor: "hashes.md5"
        // },
        {
            Header: "result",
            accessor: "hashes.is_malicious",
            Cell: ({ value }) => {
                if (value === true) {
                    return <BadgeRed>Malicious</BadgeRed>;
                }
                else if (value === false) {
                    return <BadgeGreen>Clean</BadgeGreen>
                }
                return <BadgeGray>Unknown</BadgeGray>
            },
        }
    ],
        [])
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        // We need to get 'sortBy' from the table state, then use it as an argument to fetch new data
    } = useTable(
        {
            columns,
            data,
            // we need to tell react-table that we will handle sorting manually
            manualSortBy: false
        },
        useSortBy
    );

    return (
        <Table {...getTableProps()}>
            <Thread>
                {
                    headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {
                                headerGroup.headers.map((column) => (
                                    <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        <div className="flex items-center  gap-2 ">
                                            <span>{column.render("Header")}</span>
                                            {
                                                column.isSorted ?
                                                    column.isSortedDesc ?
                                                        <HiChevronDown className="w-4 h-4" /> :
                                                        <HiChevronUp className="w-4 h-4" /> :
                                                    <></>
                                            }
                                        </div>
                                    </Th>
                                ))
                            }
                        </tr>
                    ))
                }
            </Thread>
            <Tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                        <Tr {...row.getRowProps()} onClick={() => navigator(`/samples/${row.original.id}`)}>
                            {row.cells.map((cell) => {
                                return (
                                    <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                                );
                            })}
                        </Tr>
                    );
                })}
            </Tbody>
        </Table>
    )
}
export default RecentFileAnalysisTable