import { useState } from "react";

export const defaultError = {
    error: false,
    statusCode: 0,
    message: ''
}
export const useAsyncFetch = () =>{
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(defaultError)
    return [
        isLoading,
        setIsLoading,
        isError, 
        setIsError
    ]
}