import React, { useEffect, useMemo, useState, useRef, useContext } from "react";
import { useTable, useSortBy } from "react-table";

// Components
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import AlertDetail from "../../components/alert-detail";

// Styled Components
import { BadgeGreen } from "../../styled_components/badges";
import { PrimaryBtn, PaginationBtn } from "../../styled_components/buttons";
import { TableBg, Table, Thread, Th, Td, Tr, Tbody } from "../../styled_components/tables";
import { CustomListBox } from "../../styled_components/listbox";
import { SmallModal, SideModal } from "../../styled_components/modals";
import {
    HiChevronDown,
    HiChevronUp,
    HiChevronLeft,
    HiChevronRight
} from "react-icons/hi";

// Custom hooks
import { useListPhishingAlertFetch } from "../../hooks/api/PhishingFetch";

// Ultils
import { isoToDateNow } from "../../ultils/datetime_format";

// Context
import { AuthTokenContext } from "../../contexts/AuthTokenContext";

// APIs

const pageSize = [
    {
        value: 25,
        title: '25'
    },
    {
        value: 50,
        title: '50'
    },
    {
        value: 100,
        title: '100'
    },
    {
        value: 200,
        title: '200'
    },
]

const PhishingAlertsPage = () => {
    // 
    // Upload sample
    // 

    // Authentication token in context
    const { authToken, setAuthToken } = useContext(AuthTokenContext)
    const infoModal = useRef()
    const detailModalRef = useRef()

    const [infoModalContent, setInfoModalContent] = useState({
        title: '',
        content: ''
    })

    const [selectedAlert, setSelectedAlert] = useState()
    // 
    // Data table
    // 

    // State of search input
    const [searchInput, setSearchInput] = useState();
    const [
        pagination,
        setPagination,
        sortField,
        setSortField,
        filter,
        setFilter,
        data,
        nextPage,
        previous,
        totalItem,
        isLoading,
        isError,
        setRefreshCall
    ] = useListPhishingAlertFetch(0, 25, '-createAt')

    // If fetch return 401 => session time out
    useEffect(() => {
        if (isError.error && isError.statusCode === 401) {
            localStorage.removeItem('authToken')
            localStorage.removeItem('userInfo')
            setAuthToken(null)
        }
    }, [isError])


    const columns = useMemo(() => [
        {
            Header: "Phishing URL",
            accessor: "alertUrl",
            Cell: ({ value }) => (
                <a className="hover:text-theme-primary hover:underline" href={value}>{value}</a>
            )
        },
        {
            Header: "time",
            accessor: "createAt",
            Cell: ({ value }) => {
                return isoToDateNow(value)
            }
        },
        {
            Header: "Victim IP",
            accessor: "sourceIP",
            Cell: ({ value }) => (
                value ? <BadgeGreen>{value}</BadgeGreen> : ""
            )
        },
        {
            Header: "User Agent",
            accessor: "userAgent"
        },
        {
            Header: "Profile",
            accessor: "profile_name"
        },
    ],
        [])
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        // We need to get 'sortBy' from the table state, then use it as an argument to fetch new data
        state: { sortBy }
    } = useTable(
        {
            columns,
            data,
            // we need to tell react-table that we will handle sorting manually
            manualSortBy: true
        },
        useSortBy
    );

    // Handle sort by server site
    useEffect(() => {
        if (sortBy.length > 0) {
            setSortField(`${sortBy[0].desc ? '-' : ''}${sortBy[0].id}`)
        }
    }, [sortBy])

    // Handle search
    useEffect(() => {
        const timeOutId = setTimeout(() => setFilter(searchInput), 500);
        return () => clearTimeout(timeOutId);
    }, [searchInput])

    return (
        <div className="max-w-screen max-h-screen bg-theme-bg-primary text-white flex">
            <Sidebar></Sidebar>
            {/* main */}
            <div className="grow flex flex-col align-top max-w-main gap-4">
                <Navbar>
                    <div className="flex items-end">
                        <h1 className="text-4xl text-theme-primary">Phishing Alerts</h1>
                    </div>
                </Navbar>
                <div className="px-4 pb-4 grow overflow-x-auto">
                    <div className="h-full flex flex-col">
                        <div className="flex items-center justify-between">
                            <h2 className="uppercase text-lg text-theme-primary">Phishing Alerts Table</h2>
                            <div className="flex items-center gap-2">
                                <input className="input input-txt min-w-[18rem]" placeholder="Search" value={searchInput} onChange={event => setSearchInput(event.target.value)}></input>
                                {/* <PrimaryBtn className="flex items-center py gap-1 text-sm" onClick={() => uploadModal.current.open()}>
                                    <HiOutlineFilter className="w-5 h-5" />
                                    <span>Advanced Filter</span>
                                </PrimaryBtn> */}
                            </div>
                        </div>
                        <TableBg>
                            <Table {...getTableProps()}>
                                <Thread>
                                    {
                                        headerGroups.map((headerGroup) => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {
                                                    headerGroup.headers.map((column) => (
                                                        <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                            <div className="flex items-center  gap-2 ">
                                                                <span>{column.render("Header")}</span>
                                                                {
                                                                    column.isSorted ?
                                                                        column.isSortedDesc ?
                                                                            <HiChevronDown className="w-4 h-4" /> :
                                                                            <HiChevronUp className="w-4 h-4" /> :
                                                                        <></>
                                                                }
                                                            </div>
                                                        </Th>
                                                    ))
                                                }
                                            </tr>
                                        ))
                                    }
                                </Thread>
                                <Tbody {...getTableBodyProps()}>
                                    {rows.map((row, i) => {
                                        prepareRow(row);
                                        return (
                                            <Tr {...row.getRowProps()} onClick={() => {setSelectedAlert(row.original);detailModalRef.current.open()}}>
                                                {row.cells.map((cell) => {
                                                    return (
                                                        <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                                                    );
                                                })}
                                            </Tr>
                                        );
                                    })}
                                </Tbody>
                            </Table>
                        </TableBg>
                        <div className="mt-auto flex items-center justify-between">
                            <div className="flex items-center justify-between gap-2">
                                <CustomListBox options={pageSize} defaultIndex={0} directionPopup="top" onChangeCallBack={value => setPagination(prevState => ({ ...prevState, limit: value.value }))}></CustomListBox>
                                <span className="whitespace-nowrap text-sm">items per page of {totalItem} total items</span>
                            </div>
                            <div className="">
                                <PaginationBtn disabled={previous ? false : true} onClick={() => setPagination(prevState => ({ ...prevState, offset: prevState.offset - prevState.limit }))}><HiChevronLeft className="w-5 h-5" /></PaginationBtn>
                                <PaginationBtn disabled={nextPage ? false : true} onClick={() => setPagination(prevState => ({ ...prevState, offset: prevState.offset + prevState.limit }))}><HiChevronRight className="w-5 h-5" /></PaginationBtn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SmallModal ref={infoModal} modalTitle={infoModalContent.title}>
                <div className="grow flex flex-col items-center">
                    <span className="block text-center text-white">{infoModalContent.content}</span>
                    <PrimaryBtn className="mx-auto mt-auto min-w-[4rem] text-sm" onClick={() => { infoModal.current.close() }}>OK</PrimaryBtn>
                </div>
            </SmallModal>
            <SideModal ref={detailModalRef} modalTitle="Alert Detail">
                <AlertDetail alert={selectedAlert}/>
            </SideModal>
        </div>
    )
}
export default PhishingAlertsPage