import { URL_BASE_API } from "..";

// List file samples
export const fetchUserList = async (accessToken, queryParam) => {
    var url = `${URL_BASE_API}/api/users/list/?`
    url = url + Object.keys(queryParam).reduce((result, key) => {
        return [...result, `${encodeURIComponent(key)}=${encodeURIComponent(queryParam[key])}`]
    }, []).join('&')
    const rawResponse = await fetch(url, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken,
        },
    });
    return rawResponse;
}

// Get user detail
export const fetchUserDetail = async (accessToken, userID) => {
    var url = `${URL_BASE_API}/api/users/${userID}/`
    const rawResponse = await fetch(url, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken,
        },
    });
    return rawResponse;
}

// Create User Account
export const fetchCreateUser = async (accessToken, jsonData) => {
    var url = `${URL_BASE_API}/api/users/create/`
    const rawResponse = await fetch(url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken,
        },
        body: JSON.stringify(jsonData)
    });
    return rawResponse;
}

// Update user info
export const fetchUpdateUser = async (accessToken, userID, jsonData) => {
    var url = `${URL_BASE_API}/api/users/update/${userID}/`
    const rawResponse = await fetch(url, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken,
        },
        body: JSON.stringify(jsonData)
    });
    return rawResponse;
}

// Reset user password
export const fetchResetPasswordUser = async (accessToken, userID, jsonData) => {
    var url = `${URL_BASE_API}/api/users/set-password/${userID}/`
    const rawResponse = await fetch(url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken,
        },
        body: JSON.stringify(jsonData)
    });
    return rawResponse;
}

