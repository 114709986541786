import { URL_BASE_API } from '..'

// Get general statistic
export const fetchGeneralStatistics = async (accessToken) => {
    var url = `${URL_BASE_API}/api/statistic/`
    const rawResponse = await fetch(url, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken,
        },
    });
    return rawResponse;
}

//  Get timeline of hash sample analysis
export const fetchHashSampleTimeline = async (accessToken, days=30) => {
    var url = `${URL_BASE_API}/api/statistic/timeline/hashes/days/?days=${days}`
    const rawResponse = await fetch(url, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken,
        },
    });
    return rawResponse;
}

//  Get timeline of phishing alert
export const fetchPhishingAlertTimeline = async (accessToken, days=30) => {
    var url = `${URL_BASE_API}/api/statistic/timeline/phishing-alerts/days/?days=${days}`
    const rawResponse = await fetch(url, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken,
        },
    });
    return rawResponse;
}

//  Get timeline of file sample analysis
export const fetchFileSampleTimeline = async (accessToken, days=30) => {
    var url = `${URL_BASE_API}/api/statistic/timeline/files/days/?days=${days}`
    const rawResponse = await fetch(url, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken,
        },
    });
    return rawResponse;
}