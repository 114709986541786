import { URL_BASE_API } from "..";

// Get comment list
export const fetchCommentList = async (accessToken, objectType, objecID) => {
    var url = `${URL_BASE_API}/api/comments/list/?objectType=${objectType}&objectID=${objecID}&ordering=createAt&limit=1000`
    const rawResponse = await fetch(url, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken,
        },
    });
    return rawResponse;
}

//  Create comment
export const fetchCommentCreate = async (accessToken, jsondata) => {
    const url = `${URL_BASE_API}/api/comments/create/`
    const rawResponse = await fetch(url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken,
        },
        body: JSON.stringify(jsondata)
    });
    return rawResponse;
}