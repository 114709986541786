import React, { useEffect } from "react";
import ReactJson from 'react-json-view'
import { ReactComponent as NotFoundIcon } from '../../media/images/icons/not-found.svg'
import { useStaticResultDetailFetch } from "../../hooks/api/StaticResultsFetch";
import { data } from "autoprefixer";

const StaticAnalysisReport = ({ resultID }) => {
    const [
        dataResult,
        isLoading,
        isError,
        refreshCall,] = useStaticResultDetailFetch(resultID)

    return (
        <div className="grow overflow-hidden flex flex-col">
            {
                !dataResult.data ?
                    <div className="w-full h-full flex items-center justify-center gap-4">
                        <NotFoundIcon className="text-gray-400 w-20 h-20" />
                        <div>
                            <p className="text-gray-400 font-semibold text-2xl">Not Found</p>
                            <p className="text-gray-400">This analysis result is not found</p>
                        </div>
                    </div>
                    :
                    <div className="w-full flex flex-col overflow-hidden gap-2">
                        <h2 className="text-theme-primary uppercase text-lg">{dataResult.analysis_type} analysis result of {dataResult.filename}</h2>
                        <div className="overflow-y-auto bg-gray-900 rounded-lg p-2">
                            <ReactJson style={{ backgroundColor: "transparent" }}
                                iconStyle="square"
                                theme="railscasts"
                                displayDataTypes={false}
                                src={dataResult.data}
                                collapseStringsAfterLength={32}
                                collapsed={2}
                                displayObjectSize={false} />
                        </div>
                    </div>
            }
        </div>
    )
}

export default StaticAnalysisReport;