import React, { useState, useEffect, useContext, useRef } from "react";

// Components
import Navbar from "../../../components/navbar";
import Sidebar from "../../../components/sidebar";

// Styled components
import { PrimaryBtn, SecondaryBtn } from "../../../styled_components/buttons";
import {
    HiOutlinePencil,
} from "react-icons/hi";
import { SmallModal } from "../../../styled_components/modals";
import ToggleSwitch from "../../../styled_components/toggle";


// Context
import { AuthTokenContext } from "../../../contexts/AuthTokenContext";
import { UserInfoContext } from "../../../contexts/UserInfoContext";

// Hooks
import { useAsyncFetch, defaultError } from "../../../hooks/AsyncFetch";

// API
import { fetchSettings } from "../../../apis/settings";

const ExternalSettings = () => {
    const { authToken, setAuthToken } = useContext(AuthTokenContext)
    // Global settings
    const [settings, setSettings] = useState(null)

    // Edit proxy state 
    const [isEditProxy, setIsEditProxy] = useState(false)

    const [isSSL, setIsSSL] = useState(true)

    // Info modal
    const infoModal = useRef()
    const [infoModalContent, setInfoModalContent] = useState({
        title: "",
        content: "",
    })
    const [
        isLoadingSettings,
        setIsLoadingSettings,
        isErrorSettings,
        setIsErrorSettings
    ] = useAsyncFetch()

    useEffect(async () => {
        setIsLoadingSettings(true)
        setIsErrorSettings(defaultError)
        try {
            const resp = await fetchSettings(authToken.access, {})
            const respjson = await resp.json()
            if (resp.status === 200) {
                setSettings(respjson)
            }
            else {
                setIsErrorSettings({
                    error: true,
                    statusCode: resp.status,
                    message: respjson.detail
                })
            }
        }
        catch (err) {
            setIsErrorSettings({
                error: true,
                statusCode: 0,
                message: err
            })
        }
        setIsLoadingSettings(false)
    },
        []
    )

    const onUpdateProxySettings = () => {
    }


    return (
        <div className="max-w-screen min-h-screen bg-theme-bg-primary text-white flex">
            <Sidebar></Sidebar>
            {/* main */}
            <div className="grow flex flex-col align-top max-w-main gap-4">
                <Navbar>
                    <div className="flex items-end">
                        <a className="text-4xl text-theme-primary">External Settings</a>
                    </div>
                </Navbar>
                <div className="p-4 overflow-auto">
                    <form name="updateProxy" onSubmit={onUpdateProxySettings} className=" bg-theme-bg-secondary shadow-theme rounded-lg p-4 lg:mx-40 2xl:mx-56 flex flex-col gap-2">
                        <div className="flex flex-row justify-between">
                            <h2 className="text-theme-primary uppercase text-lg">VirusTotal Settings</h2>
                            <PrimaryBtn className="text-sm flex flex-row gap-1 justify-center items-center" type="button" onClick={(e) => setIsEditProxy(prevState => !prevState)}>
                                <span>Edit</span>
                                <HiOutlinePencil className="w-4 h-4" />
                            </PrimaryBtn>
                        </div>
                        <div className="grid grid-cols-2 gap-2 w-full">
                            <div >
                                <span className="block">Enable:</span>
                                <span className="text-gray-400 text-sm">Enable VirusTotal Analysis</span>
                            </div>
                            <div>
                                <ToggleSwitch onChange={(e)=>console.log(123)} isDisabled={!isEditProxy} name="VIRUSTOTAL_ENABLED" isChecked={settings? settings.VIRUSTOTAL_ENABLED.value: false} />
                            </div>
                            <div className="col-span-2">
                                <span className="block">API Key:</span>
                                <span className="block text-gray-400 text-sm">API Key of VirusTotal</span>
                                <input className="input input-txt w-full mt-1" readOnly={!isEditProxy} name="VIRUSTOTAL_APIKEY" value={settings ? settings.VIRUSTOTAL_APIKEY.value : ''}></input>
                            </div>
                        </div>
                       
                        <div className={`mt-2 flex flex-row justify-end gap-4 ${!isEditProxy ? "hidden" : ""}`}>
                            <PrimaryBtn className="text-sm" type="submit">Save</PrimaryBtn>
                            <SecondaryBtn className="text-sm" type="button" onClick={() => setIsEditProxy(false)}>Cancel</SecondaryBtn>
                        </div>
                    </form>

                    <form name="updateProxy" onSubmit={onUpdateProxySettings} className="mt-4 bg-theme-bg-secondary shadow-theme rounded-lg p-4 lg:mx-40 2xl:mx-56 flex flex-col gap-2">
                        <div className="flex flex-row justify-between">
                            <h2 className="text-theme-primary uppercase text-lg">Hybrid Analysis Settings</h2>
                            <PrimaryBtn className="text-sm flex flex-row gap-1 justify-center items-center" type="button" onClick={(e) => setIsEditProxy(prevState => !prevState)}>
                                <span>Edit</span>
                                <HiOutlinePencil className="w-4 h-4" />
                            </PrimaryBtn>
                        </div>
                        <div className="grid grid-cols-2 gap-2 w-full">
                            <div >
                                <span className="block">Enable:</span>
                                <span className="text-gray-400 text-sm">Enable Hybrid Analysis</span>
                            </div>
                            <div>
                                <ToggleSwitch onChange={(e)=>console.log(123)} isDisabled={!isEditProxy} name="HYBRID_ANALYSIS_ENABLED" isChecked={settings? settings.HYBRID_ANALYSIS_ENABLED.value: false} />
                            </div>
                            <div className="col-span-2">
                                <span className="block">API Key:</span>
                                <span className="block text-gray-400 text-sm">API Key of Hybrid Analysis</span>
                                <input className="input input-txt w-full mt-1" readOnly={!isEditProxy} name="HYBRID_ANALYSIS_APIKEY" value={settings ? settings.HYBRID_ANALYSIS_APIKEY.value : ''}></input>
                            </div>
                        </div>
                       
                        <div className={`mt-2 flex flex-row justify-end gap-4 ${!isEditProxy ? "hidden" : ""}`}>
                            <PrimaryBtn className="text-sm" type="submit">Save</PrimaryBtn>
                            <SecondaryBtn className="text-sm" type="button" onClick={() => setIsEditProxy(false)}>Cancel</SecondaryBtn>
                        </div>
                    </form>

                    <form name="updateProxy" onSubmit={onUpdateProxySettings} className="mt-4 bg-theme-bg-secondary shadow-theme rounded-lg p-4 lg:mx-40 2xl:mx-56 flex flex-col gap-2">
                        <div className="flex flex-row justify-between">
                            <h2 className="text-theme-primary uppercase text-lg">IPAPI Settings</h2>
                            <PrimaryBtn className="text-sm flex flex-row gap-1 justify-center items-center" type="button" onClick={(e) => setIsEditProxy(prevState => !prevState)}>
                                <span>Edit</span>
                                <HiOutlinePencil className="w-4 h-4" />
                            </PrimaryBtn>
                        </div>
                        <div className="grid grid-cols-2 gap-2 w-full">
                            <div >
                                <span className="block">Enable:</span>
                                <span className="text-gray-400 text-sm">Enable IPAPI</span>
                            </div>
                            <div>
                                <ToggleSwitch onChange={(e)=>console.log(123)} isDisabled={!isEditProxy} name="HYBRID_ANAIPAPI_ENABLEDLYSIS_ENABLED" isChecked={settings? settings.IPAPI_ENABLED.value: false} />
                            </div>
                            <div className="col-span-2">
                                <span className="block">API Key:</span>
                                <span className="block text-gray-400 text-sm">API Key of IPAPI</span>
                                <input className="input input-txt w-full mt-1" readOnly={!isEditProxy} name="IPAPI_APIKEY" value={settings ? settings.IPAPI_APIKEY.value : ''}></input>
                            </div>
                        </div>
                       
                        <div className={`mt-2 flex flex-row justify-end gap-4 ${!isEditProxy ? "hidden" : ""}`}>
                            <PrimaryBtn className="text-sm" type="submit">Save</PrimaryBtn>
                            <SecondaryBtn className="text-sm" type="button" onClick={() => setIsEditProxy(false)}>Cancel</SecondaryBtn>
                        </div>
                    </form>
                </div>
                <SmallModal ref={infoModal} modalTitle={infoModalContent.title}>
                    <div className="grow flex flex-col items-center">
                        <span className="block text-center text-white">{infoModalContent.content}</span>
                        <PrimaryBtn className="mx-auto mt-auto min-w-[4rem] text-sm" onClick={() => { infoModal.current.close() }}>OK</PrimaryBtn>
                    </div>
                </SmallModal>
            </div>
        </div>
    )
}
export default ExternalSettings