import React, { useEffect, useMemo, useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTable, useSortBy } from "react-table";

// Components
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import Dropdown from "../../styled_components/dropdown";
// Style Components 
import { BadgeRed, BadgeGreen, BadgeGray, } from "../../styled_components/badges";
import { PrimaryBtn, PaginationBtn, SecondaryBtn, DangerBtn } from "../../styled_components/buttons";
import { TableBg, Table, Thread, Th, Td, Tr, Tbody } from "../../styled_components/tables";
import { CustomListBox } from "../../styled_components/listbox";
import { MediumModal, SmallModal } from "../../styled_components/modals";
import {
    HiOutlinePlus,
    HiChevronDown,
    HiChevronUp,
    HiChevronLeft,
    HiChevronRight,
    HiDotsVertical,
    HiOutlineTrash,
    HiOutlinePencil,
    HiOutlineCode,
} from "react-icons/hi";

// Context
import { AuthTokenContext } from "../../contexts/AuthTokenContext";

// Custom hooks
import { useListPhishingProfileFetch } from "../../hooks/api/PhishingFetch";
import { useAsyncFetch } from "../../hooks/AsyncFetch";

// Ultils
import { isoToDateNow } from "../../ultils/datetime_format";

// API
import { fetchPhishingProfileCreate, fetchPhishingProfileDelete, fetchPhishingProfileUpdate, fetchGenerateAntiPhishingScript } from "../../apis/phishing/profiles";


const pageSize = [
    {
        value: 25,
        title: '25'
    },
    {
        value: 50,
        title: '50'
    },
    {
        value: 100,
        title: '100'
    },
    {
        value: 200,
        title: '200'
    },
]

// COMPONENT
const PhishingProfilesPage = () => {
    // Modals
    const addProfileModal = useRef()
    const updateProfileModal = useRef()
    const infoModal = useRef()
    const deleteModal = useRef()
    const genScriptModal = useRef()
    const [infoModalContent, setInfoModalContent] = useState({
        title: '',
        content: ''
    })

    // Auth token context
    const { authToken, setAuthToken } = useContext(AuthTokenContext)

    // State for selected item
    const [selectedItem, setSelectedItem] = useState({})

    // Editing profile
    const [editingItem, setEditingItem] = useState({})

    // --------------------------------- DELETE PROFILE START-------------------------------------//
    // -------------------------------------------------------------------------------------------//
    const [
        isLoadingDeleteProfile,
        setIsLoadingDeleteProfile,
        isErrorDeleteProfile,
        setIsErrorDeleteProfile
    ] = useAsyncFetch()

    // on submit delete profile
    const onDeleteProfile = async () => {
        setIsLoadingDeleteProfile(true)
        setIsErrorDeleteProfile({
            error: false,
            statusCode: 0,
            message: ''
        })
        deleteModal.current.close()
        try {
            const resp = await fetchPhishingProfileDelete(authToken.access, selectedItem.id)
            //  SUCCESS
            if (resp.status === 200 || resp.status === 204) {
                setInfoModalContent({
                    title: "Information",
                    content: "Phishing profile deleted successfully"
                })
                //  Try to refresh table
                setRefreshCall(String(Date.now()))
            }
            // UNAUTHORIZED
            else if (resp.status === 401) {
                localStorage.removeItem('authToken')
                localStorage.removeItem('userInfo')
                setAuthToken(null)
            }
            // Other ERROR HANDLER
            else {
                setIsErrorAddProfile({
                    error: true,
                    statusCode: resp.status,
                    message: 'Phishing profile deleted unsuccessfully'
                })
                setInfoModalContent({
                    title: "Error",
                    content: `Phishing profile deleted unsuccessfully`
                })
            }
            setIsLoadingAddProfile(false)
        }
        catch (err) {
            setIsErrorAddProfile({
                error: true,
                statusCode: 0,
                message: err.message
            })
            setInfoModalContent({
                title: "Error",
                content: `Phishing profile deleted unsuccessfully.`
            })
        }
        infoModal.current.open()
    }

    // --------------------------------- DELETE PROFILE END-------------------------------------//
    // -------------------------------------------------------------------------------------------//



    // -------------------------------------------------------------------------------------------//
    // --------------------------------- ADD PROFILE START---------------------------------------//
    // Async state for submit add profile
    const [
        isLoadingAddProfile,
        setIsLoadingAddProfile,
        isErrorAddProfile,
        setIsErrorAddProfile
    ] = useAsyncFetch()

    // on submit add profile handler
    const onSubmitAddProfile = async (e) => {
        e.preventDefault()
        let submitData = {}
        const data = new FormData(e.target);
        data.forEach((value, key) => value !== '' ? submitData[key] = value : '');

        setIsLoadingAddProfile(true)
        setIsErrorAddProfile({
            error: false,
            statusCode: 0,
            message: ''
        })
        try {
            const resp = await fetchPhishingProfileCreate(authToken.access, submitData)
            const respjson = await resp.json()
            //  SUCCESS
            if (resp.status === 200 || resp.status === 201) {
                setInfoModalContent({
                    title: "Information",
                    content: "Phishing profile created successfully"
                })
                addProfileModal.current.close()
                //  Try to refresh table
                setRefreshCall(String(Date.now()))
            }
            // UNAUTHORIZED
            else if (resp.status === 401) {
                localStorage.removeItem('authToken')
                localStorage.removeItem('userInfo')
                setAuthToken(null)
            }
            // Other ERROR HANDLER
            else {
                setIsErrorAddProfile({
                    error: true,
                    statusCode: resp.status,
                    message: respjson.detail
                })
                setInfoModalContent({
                    title: "Error",
                    content: `Phishing profile created unsuccessfully. ${respjson.detail}`
                })
            }
            setIsLoadingAddProfile(false)
        }
        catch (err) {
            setIsErrorAddProfile({
                error: true,
                statusCode: 0,
                message: err.message
            })
            setInfoModalContent({
                title: "Error",
                content: `Phishing profile created unsuccessfully.`
            })
        }
        infoModal.current.open()
    }
    // --------------------------------- ADD PROFILE END------------------------------------------//
    // -------------------------------------------------------------------------------------------//


    // -------------------------------------------------------------------------------------------//
    // --------------------------------- UPDATE PROFILE START---------------------------------------//
    // Async state for submit add profile
    const [
        isLoadingUpdateProfile,
        setIsLoadingUpdateProfile,
        isErrorUpdateProfile,
        setIsErrorUpdateProfile
    ] = useAsyncFetch()

    // on submit update profile handler
    const onSubmitUpdateProfile = async (e) => {
        e.preventDefault()

        // extract checkStatic and checkCloud
        let submitData = {}
        const data = new FormData(e.target);
        data.forEach((value, key) => value !== '' ? submitData[key] = value : '');

        setIsLoadingUpdateProfile(true)
        setIsErrorUpdateProfile({
            error: false,
            statusCode: 0,
            message: ''
        })
        try {
            const resp = await fetchPhishingProfileUpdate(authToken.access, editingItem.id, submitData)
            const respjson = await resp.json()
            //  SUCCESS
            if (resp.status === 200) {
                setInfoModalContent({
                    title: "Information",
                    content: "Phishing profile updated successfully"
                })
                updateProfileModal.current.close()
                //  Try to refresh table
                setRefreshCall(String(Date.now()))
            }
            // UNAUTHORIZED
            else if (resp.status === 401) {
                localStorage.removeItem('authToken')
                localStorage.removeItem('userInfo')
                setAuthToken(null)
            }
            // Other ERROR HANDLER
            else {
                setIsErrorUpdateProfile({
                    error: true,
                    statusCode: resp.status,
                    message: respjson.detail
                })
                setInfoModalContent({
                    title: "Error",
                    content: `Phishing profile updated unsuccessfully. ${respjson.detail}`
                })
            }
            setIsLoadingUpdateProfile(false)
        }
        catch (err) {
            setIsErrorUpdateProfile({
                error: true,
                statusCode: 0,
                message: err.message
            })
            setInfoModalContent({
                title: "Error",
                content: `Phishing profile updated unsuccessfully.`
            })
        }
        infoModal.current.open()
    }
    // --------------------------------- UPDATE PROFILE END-----------------------------------//
    // -------------------------------------------------------------------------------------------//


    // -------------------------------------------------------------------------------------------//
    // ---------------------------------GENERATE SCRIPT START--------------------------------//
    // Async state for submit add profile
    const [
        isLoadingGenScript,
        setIsLoadingGenScript,
        isErrorGenScript,
        setIsErrorGenScript
    ] = useAsyncFetch()

    // on submit update profile handler
    const onGenerateScript = async (e) => {
        e.preventDefault()

        setIsLoadingGenScript(true)
        setIsErrorGenScript({
            error: false,
            statusCode: 0,
            message: ''
        })
        try {
            const resp = await fetchGenerateAntiPhishingScript(authToken.access, selectedItem.id, selectedItem.scriptType)
            //  SUCCESS
            if (resp.status === 200) {
                const blob = await resp.blob()
                const url = window.URL.createObjectURL(blob)
                var link = document.createElement('a')
                link.href = url
                link.download= `${selectedItem.id}.js`
                document.body.appendChild(link)
                link.click()
                link.remove()
            }
            // UNAUTHORIZED
            else if (resp.status === 401) {
                localStorage.removeItem('authToken')
                localStorage.removeItem('userInfo')
                setAuthToken(null)
            }
            // Other ERROR HANDLER
            else {
                setIsErrorGenScript({
                    error: true,
                    statusCode: resp.status,
                    message: `Phishing script generated unsuccessfully.`
                })
                setInfoModalContent({
                    title: "Error",
                    content: `Phishing script generated unsuccessfully.`
                })
                infoModal.current.open()
            }
            setIsLoadingGenScript(false)
        }
        catch (err) {
            setIsErrorGenScript({
                error: true,
                statusCode: 0,
                message: err.message
            })
            setInfoModalContent({
                title: "Error",
                content: `Phishing script generated unsuccessfully.`
            })
            infoModal.current.open()
        }
    }
    // ---------------------------------GENERATE SCRIPT END-----------------------------------//
    // --------------------------------------------------------------------------------------------//


    // ------------------------------------------------------------------------------------------//
    // --------------------------------- TABLE PROFILE START----------------------------------//
    // Data table
    const [searchInput, setSearchInput] = useState();


    const [
        pagination,
        setPagination,
        sortField,
        setSortField,
        filter,
        setFilter,
        data,
        nextPage,
        previous,
        totalItem,
        isLoading,
        isError,
        setRefreshCall
    ] = useListPhishingProfileFetch(0, 25)

    // Error if can not fetch profile 
    useEffect(() => {
        if (isError.error && isError.statusCode === 401) {
            localStorage.removeItem('authToken')
            localStorage.removeItem('userInfo')
            setAuthToken(null)
        }
    }, [isError])

    // Table columns
    const columns = useMemo(() => [
        {
            Header: "Profile",
            accessor: "name"
        },
        {
            Header: "url",
            accessor: "url"
        },
        {
            Header: "create time",
            accessor: "createAt",
            Cell: ({ value }) => {
                return isoToDateNow(value)
            }
        },
        {
            Header: "Create By",
            accessor: "createBy"
        },
        {
            Header: "Enabled",
            accessor: "isEnabled",
            Cell: ({ value }) => {
                if (value === true) {
                    return <BadgeGreen>Enabled</BadgeGreen>
                }
                return <BadgeRed>Disabled</BadgeRed>;
            },
        },
        {
            Header: "actions",
            accessor: "Actions",
            Cell: ({ row }) => {
                const rowDropMenu = [
                    [
                        {
                            'icon': <HiOutlinePencil className="w-5 h-5 mr-3 text-theme-primary" />,
                            'text': "Edit Profile",
                            'href': "/#/profile",
                            'onClick':() => {setEditingItem(row.original);updateProfileModal.current.open()}
                        },
                        {
                            'icon': <HiOutlineTrash className="w-5 h-5 mr-3 text-red-500" />,
                            'text': "Delete Profile",
                            'href': "/#/profile",
                            'onClick':() => {deleteModal.current.open()}
                        }
                    ],
                    [
                        {
                            'icon': <HiOutlineCode className="w-5 h-5 mr-3 text-theme-primary" />,
                            'text': "Generate Script",
                            'href': "/#/profile",
                            'onClick':() => {genScriptModal.current.open()}
                        }
                    ]
                ]
                return (
                    <Dropdown items={rowDropMenu} placement="left-start">
                        <button className="hover:bg-gray-500 p-2 rounded-lg hover:text-white" onClick={(e) => setSelectedItem(row.original)}><HiDotsVertical className="w-5 h-5" /></button>
                    </Dropdown>
                )
            }
        }
    ],
        [])
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        // We need to get 'sortBy' from the table state, then use it as an argument to fetch new data
        state: { sortBy }
    } = useTable(
        {
            columns,
            data,
            // we need to tell react-table that we will handle sorting manually
            manualSortBy: true
        },
        useSortBy
    );

    // Handle sort by server site
    useEffect(() => {
        if (sortBy.length > 0) {
            setSortField(`${sortBy[0].desc ? '-' : ''}${sortBy[0].id}`)
        }
    }, [sortBy])

    // Handle search
    useEffect(() => {
        const timeOutId = setTimeout(() => setFilter(searchInput), 500);
        return () => clearTimeout(timeOutId);
    }, [searchInput])
    // --------------------------------- TABLE PROFILE END-------------------------------------//
    // -----------------------------------------------------------------------------------------//


    return (
        <div className="max-w-screen max-h-screen bg-theme-bg-primary text-white flex">
            <Sidebar></Sidebar>
            {/* main */}
            <div className="grow flex flex-col align-top max-w-main gap-4">
                <Navbar>
                    <div className="flex items-end">
                        <a className="text-4xl text-theme-primary">Phishing Profile</a>
                    </div>
                </Navbar>
                <div className="px-4 pb-4 grow overflow-x-auto">
                    <div className="h-full flex flex-col">
                        <div className="flex items-center justify-between">
                            <h2 className="uppercase text-lg text-theme-primary">Anti Phishing Profile</h2>
                            <div className="flex items-center gap-2">
                                <input className="input input-txt min-w-[18rem]" placeholder="Search" value={searchInput} onChange={event => setSearchInput(event.target.value)}></input>
                                <PrimaryBtn onClick={() => { addProfileModal.current.open() }} className="flex items-center py gap-1 text-sm">
                                    <HiOutlinePlus className="w-5 h-5" />
                                    <span>Add Profile</span>
                                </PrimaryBtn>
                            </div>
                        </div>
                        <TableBg>
                            <Table {...getTableProps()}>
                                <Thread>
                                    {
                                        headerGroups.map((headerGroup) => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {
                                                    headerGroup.headers.map((column) => (
                                                        <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                            <div className="flex items-center  gap-2 ">
                                                                <span>{column.render("Header")}</span>
                                                                {
                                                                    column.isSorted ?
                                                                        column.isSortedDesc ?
                                                                            <HiChevronDown className="w-4 h-4" /> :
                                                                            <HiChevronUp className="w-4 h-4" /> :
                                                                        <></>
                                                                }
                                                            </div>
                                                        </Th>
                                                    ))
                                                }
                                            </tr>
                                        ))
                                    }
                                </Thread>
                                <Tbody {...getTableBodyProps()}>
                                    {rows.map((row, i) => {
                                        prepareRow(row);
                                        return (
                                            <Tr {...row.getRowProps()}>
                                                {row.cells.map((cell) => {
                                                    return (
                                                        <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                                                    );
                                                })}
                                            </Tr>
                                        );
                                    })}
                                </Tbody>
                            </Table>
                        </TableBg>
                        <div className="mt-auto flex items-center justify-between">
                            <div className="flex items-center justify-between gap-2">
                                <CustomListBox options={pageSize} defaultIndex={0} directionPopup="top" onChangeCallBack={value => setPagination(prevState => ({ ...prevState, limit: value.value }))}></CustomListBox>
                                <span className="whitespace-nowrap text-sm">items per page of {totalItem} total items</span>
                            </div>
                            <div className="">
                                <PaginationBtn disabled={previous ? false : true} onClick={() => setPagination(prevState => ({ ...prevState, offset: prevState.offset - prevState.limit }))}><HiChevronLeft className="w-5 h-5" /></PaginationBtn>
                                <PaginationBtn disabled={nextPage ? false : true} onClick={() => setPagination(prevState => ({ ...prevState, offset: prevState.offset + prevState.limit }))}><HiChevronRight className="w-5 h-5" /></PaginationBtn>
                            </div>
                        </div>
                        {/* Add Profile Modal */}
                        <MediumModal ref={addProfileModal} modalTitle="Add Phishing Profile">
                            <form onSubmit={onSubmitAddProfile} className="w-full flex flex-col gap-8">
                                {/* body */}
                                <div className="w-full flex flex-col gap-5">
                                    <div className="flex flex-col gap-1">
                                        <label className="text-white">Profile name <span className="text-red-500">*</span></label>
                                        <div className="flex flex-col gap-1 mx-2">
                                            <label className="text-gray-500 text-sm">Name of anti phishing profile</label>
                                            <input type="text" name="name" required placeholder="Profile name" className="input input-txt"></input>
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-1">
                                        <label className="text-white">URL <span className="text-red-500">*</span></label>
                                        <div className="flex flex-col gap-1 mx-2">
                                            <label className="text-gray-500 text-sm">URL of website</label>
                                            <input type="url" name="url" required placeholder="URL" className="input input-txt"></input>
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-1">
                                        <label className="text-white">Enabled</label>
                                        <label className="mx-2 text-gray-500 text-sm">Specify if this profile accepts alert</label>
                                        <div className="mt-2 flex flex-row justify-evenly items-evently">
                                            <div className="flex gap-2">
                                                <input type="radio" name="isEnabled" required value="true" defaultChecked="checked" className="input-radio"></input>
                                                <label className="text-gray-400 text-sm min-w-[6rem]">Enabled</label>
                                            </div>
                                            <div className="flex gap-2">
                                                <input type="radio" name="isEnabled" required value="false" className="input-radio"></input>
                                                <label className="text-gray-400 text-sm min-w-[6rem]">Disabled</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-1">
                                        <label className="text-white">Description</label>
                                        <div className="flex flex-col gap-1 mx-2">
                                            {/* <label className="text-gray-500 text-sm">Description of profile</label> */}
                                            <textarea rows="4" name="description" placeholder="Description" className="input input-txt"></textarea>
                                        </div>
                                    </div>
                                </div>
                                {/* footer */}
                                <div className="flex justify-end gap-4">
                                    <PrimaryBtn type="submit" className="text-sm">Add Profile</PrimaryBtn>
                                    <SecondaryBtn className="text-sm"  type="button" onClick={() => addProfileModal.current.close()}>Cancel</SecondaryBtn>
                                </div>
                            </form>
                        </MediumModal>

                        {/* Update Profile Modal */}
                        <MediumModal ref={updateProfileModal} modalTitle="Update Phishing Profile">
                            <form onSubmit={onSubmitUpdateProfile} className="w-full flex flex-col gap-8">
                                {/* body */}
                                <div className="w-full flex flex-col gap-5">
                                    <div className="flex flex-col gap-1">
                                        <label className="text-white">Profile name <span className="text-red-500">*</span></label>
                                        <div className="flex flex-col gap-1 mx-2">
                                            <label className="text-gray-500 text-sm">Name of anti phishing profile</label>
                                            <input type="text" name="name" required placeholder="Profile name" className="input input-txt" value={editingItem.name} onChange={(e) => setEditingItem(prevState => ({ ...prevState, name: e.target.value }))}></input>
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-1">
                                        <label className="text-white">URL <span className="text-red-500">*</span></label>
                                        <div className="flex flex-col gap-1 mx-2">
                                            <label className="text-gray-500 text-sm">URL of website</label>
                                            <input type="url" name="url" required placeholder="URL" className="input input-txt" value={editingItem.url} onChange={(e) => setEditingItem(prevState => ({ ...prevState, url: e.target.value }))}></input>
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-1">
                                        <label className="text-white">Enabled</label>
                                        <label className="mx-2 text-gray-500 text-sm">Specify if this profile accepts alert</label>
                                        <div className="mt-2 flex flex-row justify-evenly items-evently">
                                            <div className="flex gap-2">
                                                <input type="radio" name="isEnabled" required value="true" className="input-radio" checked={editingItem.isEnabled} onChange={(e) => setEditingItem(prevState => ({ ...prevState, isEnabled: e.target.value === 'true' }))}></input>
                                                <label className="text-gray-400 text-sm min-w-[6rem]">Enabled</label>
                                            </div>
                                            <div className="flex gap-2">
                                                <input type="radio" name="isEnabled" required value="false" className="input-radio" checked={!editingItem.isEnabled} onChange={(e) => setEditingItem(prevState => ({ ...prevState, isEnabled: e.target.value === 'true' }))}></input>
                                                <label className="text-gray-400 text-sm min-w-[6rem]">Disabled</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-1">
                                        <label className="text-white">Description</label>
                                        <div className="flex flex-col gap-1 mx-2">
                                            {/* <label className="text-gray-500 text-sm">Description of profile</label> */}
                                            <textarea rows="4" name="description" placeholder="Description" className="input input-txt" value={editingItem.description} onChange={(e) => setEditingItem(prevState => ({ ...prevState, description: e.target.value }))}></textarea>
                                        </div>
                                    </div>
                                </div>
                                {/* footer */}
                                <div className="flex justify-end gap-4">
                                    <PrimaryBtn type="submit" className="text-sm">Update Profile</PrimaryBtn>
                                    <SecondaryBtn className="text-sm" type="button" onClick={() => updateProfileModal.current.close()}>Cancel</SecondaryBtn>
                                </div>
                            </form>
                        </MediumModal>


                        
                        {/* Info Modal */}
                        <SmallModal ref={infoModal} modalTitle={infoModalContent.title}>
                            <div className="grow flex flex-col items-center">
                                <span className="block text-center text-white">{infoModalContent.content}</span>
                                <PrimaryBtn className="mx-auto mt-auto min-w-[4rem] text-sm" onClick={() => { infoModal.current.close() }}>OK</PrimaryBtn>
                            </div>
                        </SmallModal>
                        
                        {/* Delete Modal */}
                        <SmallModal ref={deleteModal} modalTitle="Delete Profile">
                            <div className="grow flex flex-col items-center">
                                <span className="block text-center text-white">Are you sure to delete this profile?</span>
                                <div className="mt-auto flex flex-row items-center gap-4">
                                    <DangerBtn className="min-w-[5rem] text-sm" onClick={onDeleteProfile}>Delete</DangerBtn>
                                    <SecondaryBtn className="min-w-[5rem] text-sm" onClick={() => { deleteModal.current.close() }}>Cancel</SecondaryBtn>
                                </div>
                            </div>
                        </SmallModal>

                        {/* Generate Script Modal */}
                        <MediumModal ref={genScriptModal} modalTitle="Generate Script">
                            <form onSubmit={onGenerateScript} className="w-full flex flex-col gap-8">
                                {/* body */}
                                <div className="w-full flex flex-col gap-5">
                                    <div className="flex flex-col gap-1">
                                        <label className="text-white">Script type <span className="text-red-500">*</span></label>
                                        <div className="flex flex-col gap-1 mx-2">
                                            <label className="text-gray-500 text-sm">Choose script type to be generated</label>
                                            <CustomListBox 
                                                    options={[{
                                                        value: 1,
                                                        title: 'Ofuscated Javascript'
                                                    },
                                                    {
                                                        value: 0,
                                                        title: 'Raw javascript'
                                                    },]} 
                                                    defaultIndex={0} 
                                                    directionPopup="bottom"
                                                    onChangeCallBack={value => setSelectedItem(prevState => ({...prevState, scriptType: value.value}))}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* footer */}
                                <div className="flex justify-end gap-4">
                                    <PrimaryBtn type="submit" className="text-sm">Generate</PrimaryBtn>
                                    <SecondaryBtn className="text-sm" type="button" onClick={() => genScriptModal.current.close()}>Cancel</SecondaryBtn>
                                </div>
                            </form>
                        </MediumModal>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PhishingProfilesPage