import { URL_BASE_API } from '..'

// List phishing profile
export const fetchPhishingProfileList = async (accessToken, queryParam) => {
    var url = `${URL_BASE_API}/api/phishing/profile/list/?`
    url = url + Object.keys(queryParam).reduce((result, key) => {
        return [...result, `${encodeURIComponent(key)}=${encodeURIComponent(queryParam[key])}`]
    }, []).join('&')
    const rawResponse = await fetch(url, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken,
        },
    });
    return rawResponse;
}

// Create Phishing Profile
export const fetchPhishingProfileCreate = async (accessToken, jsondata) => {
    const url = `${URL_BASE_API}/api/phishing/profile/create/`
    const rawResponse = await fetch(url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken,
        },
        body: JSON.stringify(jsondata)
    });
    return rawResponse;
}

// Update Phishing Profile
export const fetchPhishingProfileUpdate = async (accessToken, profileID, jsondata) => {
    const url = `${URL_BASE_API}/api/phishing/profile/update/${profileID}/`
    const rawResponse = await fetch(url, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken,
        },
        body: JSON.stringify(jsondata)
    });
    return rawResponse;
}

// Delete Phishing Profile
export const fetchPhishingProfileDelete = async (accessToken, profileID) => {
    const url = `${URL_BASE_API}/api/phishing/profile/delete/${profileID}/`
    const rawResponse = await fetch(url, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken,
        },
    });
    return rawResponse;
}

// Generate Anti Phishing Script
export const fetchGenerateAntiPhishingScript = async (accessToken, profileID, isOfuscate) => {

    const url = `${URL_BASE_API}/api/phishing/profile/gen-js-script/${profileID}/?obfuscate=${isOfuscate?1:0}`
    const rawResponse = await fetch(url, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken,
        },
    });
    return rawResponse;
}

