import tw from 'twin.macro'

export const Badge = tw.button`
    px-2 py-0.5 rounded-xl bg-blue-400 text-gray-900 text-xs hover:bg-blue-500
`
export const BadgeRed = tw.button`
    px-2 py-0.5 rounded-xl bg-red-400 text-gray-900 text-xs hover:bg-red-500
`
export const BadgeYellow = tw.button`
    px-2 py-0.5 rounded-xl bg-yellow-400 text-gray-900 text-xs hover:bg-yellow-500
`
export const BadgeGreen = tw.button`
    px-2 py-0.5 rounded-xl bg-green-400 text-gray-900 text-xs hover:bg-green-500
`
export const BadgeGray = tw.button`
    px-2 py-0.5 rounded-xl bg-gray-400 text-gray-900 text-xs hover:bg-gray-500
`