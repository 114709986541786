import React from "react";
import { createContext, useState } from "react";

export  const AuthTokenContext = createContext();

export const AuthTokenContextProvider = (props) =>{
    const [authToken, setAuthToken] = useState(JSON.parse(localStorage.getItem('authToken')));
    return(
        <AuthTokenContext.Provider value={{authToken, setAuthToken}}>
            {props.children}
        </AuthTokenContext.Provider>
    )
}